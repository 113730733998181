import React,{useContext} from 'react'
import { Button,  Modal } from 'antd'
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import useAxiosInstance from '../../../lib/useAxiosInstance';
import ReportContext from '../../../context/reportContext';

const DeactivateReport = ({ isStatusModal, editData }) => {
    const reportContext = useContext(ReportContext);

    const axiosInstance = useAxiosInstance()
    const queryClient = useQueryClient();
    const toggleReportStatusMutation = useMutation(async () => {
        // Update the reportStatus of the selected report
        const updatedReport = { ...editData };
        updatedReport.reportStatus =
            updatedReport.reportStatus === 'deactivated' ? 'activated' : 'deactivated';

        const response = await axiosInstance.put(`/api/reports/${updatedReport._id}`, updatedReport);

        return response.data;
    },

        {
            onSuccess: (data) => {
                handleCancel();
                queryClient.invalidateQueries('report-list');
                queryClient.invalidateQueries('allSearchReports');
                toast.success(`Report ${data.data.reportStatus} successfully`);
            },
            onError: (error) => {
                toast.error('An error occurred while updating report status');
            }
        }
    );

    const handleToggleStatus = () => {
        toggleReportStatusMutation.mutate(); // Call the mutation function
    };

    const handleCancel = () => {
        reportContext.updateStatusModal(false);
    };

    return (
        <>

            <Modal open={isStatusModal}
                onSumbit={handleToggleStatus}
                onCancel={handleCancel}
                footer={null}
            >
                <div>
                    <div className='modal-title'>
                        <h3>{editData.reportStatus.reportStatus === 'deactivated' ? 'Activate' : 'Deactivate'} Report</h3>
                    </div>
                    <div className='delete-modal-body'>
                        <h6>Are you sure you want to {editData.reportStatus === 'deactivated' ? 'activate' : 'deactivate'} this report?</h6>
                    </div>

                    <div className='modal-footer p-4'>
                        <div className='flex justify-end'>
                           <Button className='delete-btn' onClick={handleToggleStatus}>
                                {editData.reportStatus === 'deactivated' ? 'Activate' : 'Deactivate'} Report
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default DeactivateReport