import React, { useState } from "react";
import { Select, Pagination } from "antd";
import "../../../styles/Gms.css";
import SearchFilter from "../../SearchFilter";
import { toast } from "react-toastify";
import CategoryDataTable from "./CategoryDataTable";
import { useQuery, useQueryClient } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";

const CategoryList = () => {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line
  const [sort, setSort] = useState("DES");
  const [searchKeyword, setSearchKeyword] = useState("");

  const fetchCategories = async () => {
    let response;
    if (searchKeyword) {
      response = await axiosInstance.get(
        `api/category/search/main?keyword=${searchKeyword}&size=${limit}&page=${currentPage}`
      );
      setTotal(response?.data?.totalCategories);

      return response;
    } else {
      response = await axiosInstance.get(
        `api/category/main?size=${limit}&page=${currentPage}&sort=${sort}`
      );

      setTotal(response?.data?.totalCategories);
      return response;
    }
  };
  const categoryData = useQuery(
    ["categories", currentPage, limit, sort],
    () => fetchCategories(),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  const categorySearchData = useQuery(
    ["allSearchCategory", searchKeyword, currentPage, limit, sort],
    () => fetchCategories(),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );
  const isLoading = categoryData?.isLoading || categorySearchData?.isLoading;


  const categoryListData = categoryData?.data?.data;
  const searchCategoryData = categorySearchData?.data?.data?.data;
  // GET ALL SEARCH CATEGORY DATA
  const SearchCategory = (value) => {
    setCurrentPage(1);
    setSearchKeyword(value);
  };

  // FOR PAGINATION
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const pagination = {
    total: total * limit,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange
  };

  // DELETE CATEGORY
  const handleDelete = async (record) => {
    try {
      const response = await axiosInstance.delete(`api/category/${record._id}`);
      const message = response?.data?.message;
      toast.success(`${message}`);
      queryClient.invalidateQueries("categories");
      queryClient.invalidateQueries("allSearchCategory");
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong.");
      }
    }
  };

  return (
    <div className='gms-page-section'>
      <div className='gms-table-wrapper gms-category table-wrap'>
        <SearchFilter handleSearch={SearchCategory} />

        <CategoryDataTable
          categoryData={categoryListData}
          categorySearchData={searchCategoryData}
          pagination={pagination}
          handleDelete={handleDelete}
          isLoading={isLoading}
        />

        <div className='report-pagination '>
          <div>
            <Select
              value={limit.toString()}
              style={{ width: 56 }}
              onChange={(value) => {
                setLimit(parseInt(value));
                setCurrentPage(1);
              }}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "50", label: "50" },
                { value: "100", label: "100" }
              ]}
            />

            <span className='ml-3'> Entries Per Page</span>
          </div>

          <div className='report-selection '>
            <Pagination
              className='pagination'
              current={currentPage}
              pageSize={limit}
              total={total}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setLimit(pageSize);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryList;
