import React, { useContext, useState } from "react";
import { Table, Select, Pagination, Space, Skeleton, Empty } from "antd";
import { useParams } from "react-router-dom";
import rightArrow from "../../../assets/images/client-icon/chevron-right.png";
import { NavLink } from "react-router-dom";
import SearchFilter from "../../SearchFilter";
import { AppContext } from "../../../context/AppContextProvider";
// import BillingContext from "../../../context/billingContext";
import InvoiceForm from "./InvoiceForm";
import DownloadIcon from "../../../assets/images/download.png";
import EditInvoiceForm from "./EditInvoiceForm";
import RecordPayment from "./RecordPayment";
import EditRecordPayment from "./EditRecordPayment";
import { MdUpload } from "react-icons/md";
import DeleteModal from "../../DeleteModal";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { useQuery, useQueryClient } from "react-query";
import { toast, ToastContainer } from "react-toastify";

const { Column } = Table;

const MoreInfo = () => {
  const queryClient = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const clientData = JSON.parse(localStorage.getItem("clientInfo"));

  const { clientId } = useParams();

  const { isDropdownOpen, handleClick, handleMouseEnter, handleMouseLeave } =
    useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  // const [sort, setSort] = useState("DES");

  const [uploadInvoice, setUploadInvoice] = useState(false);
  const [editInvoice, setEditInvoice] = useState(false);
  const [recordPayment, setRecordPayment] = useState(false);
  const [editRecordPayment, setEditRecordPayment] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleModal = () => {
    setUploadInvoice(true);
  };

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setEditInvoice(true);
  };

  const handleRecordPayment = (record) => {
    setSelectedRecord(record);
    setRecordPayment(true);
  };

  // const handleEditRecordPayment = (record) => {
  //   setSelectedRecord(record);
  //   setEditRecordPayment(true);
  // };

  const showModalDelete = (record) => {
    setSelectedRecord(record);
    setDeleteModal(!isDeleteModal);
  };
  const fetchClientInvoices = async (currentPage, limit, clientId, keyword) => {
    const response = await axiosInstance.get(
      `api/invoice/admin/getInvoicesByClient/${clientId}?page=${currentPage}&limit=${limit}&keyword=${keyword}&sort=DES`
    );
    setTotal(response?.data?.message?.invoiceCount);
    return response;
  };

  const invoiceData = useQuery(
    ["invoice-list", currentPage, limit, clientId, keyword],
    () => fetchClientInvoices(currentPage, limit, clientId, keyword),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );
  const invoices = invoiceData?.data?.data?.message?.data || [];
  const isLoading = invoiceData?.isLoading;

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };
  const pagination = {
    total: total * limit,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange
  };
  const handleDelete = async (record) => {
    try {
      if (!record || !record._id) {
        throw new Error("Invalid record");
      }

      const response = await axiosInstance.delete(`api/invoice/${record._id}`);
      const successMessage = response?.data?.message;
      toast.success(successMessage);
      queryClient.invalidateQueries("invoice-list");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong.");
      }
    }
  };

  const data = invoices.map((invoice) => {
    return {
      invoice_date: new Date(invoice?.invoiceDate).toLocaleDateString(),
      mod_subscribed: invoice?.planType.map((type) => type?.name).join(", "),
      duration: invoice?.planDuration
        .replace("_", " ")
        .replace(/(^|\s)\S/g, (l) => l.toUpperCase()),
      status: invoice?.invoiceStatus, // Adjust status as needed
      invoice_number: invoice?.invoiceNumber, // Replace with actual transaction data if available
      amt: "₹" + invoice?.invoiceAmount,
      date: new Date(invoice?.invoiceDate).toLocaleDateString(),
      _id: invoice?._id,
      Outstanding_amt: "₹" + invoice?.totalOutstandingAmount || "-",
      ...invoice
    };
  });

  const handleSearch = (search) => {
    setCurrentPage(1);
    setKeyword(search);
  };

  return (
    <div className='gms-page-section'>
      <ToastContainer />
      <div className='gms-wrapper'>
        <div className='client-breadcrumbs'>
          <p className='flex'>
            <span className='flex'>
              <NavLink to='/billing' className='nav-link flex'>
                Billing <img src={rightArrow} alt='arrow' />
              </NavLink>
            </span>
            <span>
              {clientData?.adminFirstName} {clientData?.adminLastName}
            </span>
          </p>
        </div>

        <div className='flex items-center justify-between upload-invoice mt-4 mb-4'>
          <h1>
            {clientData?.adminFirstName} {clientData?.adminLastName}
          </h1>
          <button onClick={() => handleModal()}>
            <MdUpload />
            <span>Upload Invoice</span>
          </button>
        </div>

        <div>
          <div className='gms-page-section'>
            <div className='gms-table-wrapper'>
              <div className='client-details upload-invoice-client-details'>
                <div className='tab-heading'>
                  <div className='flex client-info-heading1'>
                    <h4>Current Subscription </h4>
                  </div>
                </div>
                <div className='client-details-section1 grid grid-cols-3'>
                  <div className='basicInfoDiv'>
                    <div className='firstname-field'>
                      <p>
                        <span>Plan</span>
                        <span className='w-96'>
                          {clientData?.planType
                            .map((plan) => plan.name)
                            .join(", ")}
                        </span>
                      </p>
                      <p>
                        <span>Start Date</span>
                        <span>
                          {new Date(
                            clientData?.validityStart
                          ).toLocaleDateString()}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='basicInfoDiv'>
                    <div className='firstname-field'>
                      <p>
                        <span>Duration</span>
                        <span>
                          {clientData?.planDuration
                            .replace("_", " ")
                            .replace(/(^|\s)\S/g, (l) => l.toUpperCase())}
                        </span>
                      </p>
                      <p>
                        <span>End Date</span>
                        <span>
                          {new Date(
                            clientData?.validityEnd
                          ).toLocaleDateString()}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='basicInfoDiv'>
                    <div className='firstname-field'>
                      <p>
                        <span>Amount</span>
                        <span>₹{clientData?.planAmount}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='tab-heading tab-heading1'>
                <h4 className='ml-4'>Transactions</h4>
              </div>
              <div className="table-wrapper-content">
                <SearchFilter handleSearch={handleSearch} />

                <div className='billing-table'>
                  <Table
                    rowKey={(record) => record.key}
                    className='gms-table-rows'
                    pagination={pagination}
                    dataSource={data}
                    locale={{
                      emptyText: isLoading ? (
                        <div
                          style={{
                            marginLeft: "20px",
                            width: "95%"
                          }}
                        >
                          {" "}
                          {/* Container with 90% width */}
                          <Skeleton
                            title={false}
                            active
                            paragraph={{
                              rows: 6,
                              width: [
                                "100%",
                                "100%",
                                "100%",
                                "100%",
                                "100%",
                                "100%"
                              ]
                            }}
                          />
                        </div>
                      ) : (
                        <Empty />
                      )
                    }}
                  >
                    <Column title='Invoice Date' dataIndex='invoice_date' />

                    <Column
                      key='Mod_Subscribed'
                      title='Mod. Subscribed'
                      dataIndex='mod_subscribed'
                    />

                    <Column
                      key='duration'
                      title='Duration'
                      dataIndex='duration'
                    />

                    <Column
                      key='status'
                      title='Status'
                      dataIndex='status'
                      render={(text) => {
                        if (text === "partiallyPaid") {
                          return "Partially Paid";
                        } else if (
                          text === "fullyPaid" ||
                          text === "fully_paid"
                        ) {
                          return "Fully Paid";
                        }
                        return text;
                      }}
                    />

                    <Column
                      key='invoice_number'
                      title='Invoice Number'
                      dataIndex='invoice_number'
                    />
                    <Column key='amt' title='Amount' dataIndex='amt' />
                    <Column
                      key='Outstanding_amt'
                      title='Outstanding Amount'
                      dataIndex='Outstanding_amt'
                      render={(text, record) => (
                        <span style={{ color: "red" }}>{text}</span>
                      )}
                    />

                    <Column title='Payment DATE' dataIndex='date' />
                    <Column
                      render={(text, record, index) => {
                        return (
                          <a
                            href={record?.invoiceFile?.publicUrl}
                            download
                            target='_blank'
                            rel='noreferrer'
                          >
                            <img src={DownloadIcon} alt='download' />
                          </a>
                        );
                      }}
                    />

                    <Column
                      title='ACTION'
                      key='action'
                      render={(text, record, index) => {
                        if (
                          record.status === "fully_paid" ||
                          record.status === "fullyPaid"
                        ) {
                          return null;
                        }
                        return (
                          <Space
                            className='select-option'
                            size='small'
                            style={{}}
                          >
                            <div
                              className='dropdown'
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={handleMouseLeave}
                            >
                              <span onClick={() => handleClick(index)}>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='34'
                                  height='30'
                                  viewBox='0 0 34 30'
                                  fill='none'
                                >
                                  <circle
                                    cx='17.3889'
                                    cy='9.38845'
                                    r='1.38889'
                                    transform='rotate(-90 17.3889 9.38845)'
                                    fill='#004877'
                                  />
                                  <circle
                                    cx='17.3889'
                                    cy='15.4998'
                                    r='1.38889'
                                    transform='rotate(-90 17.3889 15.4998)'
                                    fill='#004877'
                                  />
                                  <circle
                                    cx='17.3889'
                                    cy='21.6111'
                                    r='1.38889'
                                    transform='rotate(-90 17.3889 21.6111)'
                                    fill='#004877'
                                  />
                                </svg>
                              </span>

                              {isDropdownOpen(index) && (
                                <div className='dropdown-content'>
                                  {record.status === "unpaid" && (
                                    <>
                                      <div
                                        onClick={() =>
                                          handleRecordPayment(record)
                                        }
                                      >
                                        Record Payment
                                      </div>
                                      <div onClick={() => handleEdit(record)}>
                                        Edit Invoice
                                      </div>
                                      <div
                                        onClick={() => showModalDelete(record)}
                                      >
                                        Delete Invoice
                                      </div>
                                    </>
                                  )}

                                  {record.status === "partiallyPaid" && (
                                    <div
                                      onClick={() => handleRecordPayment(record)}
                                    >
                                      Record Payment
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </Space>
                        );
                      }}
                    />
                  </Table>
                  <InvoiceForm
                    uploadInvoice={uploadInvoice}
                    setUploadInvoice={setUploadInvoice}
                    clientId={clientData?._id}
                  />
                  <EditInvoiceForm
                    editInvoice={editInvoice}
                    setEditInvoice={setEditInvoice}
                    selectedRecord={selectedRecord}
                  />
                  <RecordPayment
                    recordPayment={recordPayment}
                    setRecordPayment={setRecordPayment}
                    selectedRecord={selectedRecord}
                  />
                  <EditRecordPayment
                    editRecordPayment={editRecordPayment}
                    setEditRecordPayment={setEditRecordPayment}
                    selectedRecord={selectedRecord}
                  />
                  {isDeleteModal && (
                    <DeleteModal
                      textheading='Delete Invoice'
                      deleteTitle='invoice'
                      deleteBtn='Delete Invoice'
                      handleDelete={() => handleDelete(selectedRecord)}
                      isDeleteModal={isDeleteModal}
                      showModalDelete={() => setDeleteModal(false)}
                      action='delete'
                    />
                  )}
                </div>
                <div className='report-pagination'>
                  <div>
                    <span>Showing</span>
                    <Select
                      // value={limit.toString()}
                      defaultValue={10}
                      style={{ width: 56 }}
                      // onChange={(value) => setLimit(parseInt(value))}
                      options={[
                        // { value: "5", label: "5" },
                        { value: "10", label: "10" },
                        { value: "20", label: "20" },
                        { value: "50", label: "50" },
                        { value: "100", label: "100" }
                      ]}
                    />
                    <span className='px-4'> of 50</span>
                  </div>
                  <div className='report-selection'>
                    <Pagination
                      current={currentPage}
                      pageSize={limit}
                      total={total}
                      onChange={(page, pageSize) => {
                        setCurrentPage(page);
                        setLimit(pageSize);
                      }}
                      className='pagination'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreInfo;
