import React, { useContext, useState } from "react";
import { Button, Pagination, Select, Space, Table, Skeleton, Empty } from "antd";
import Column from "antd/es/table/Column";
import SearchFilter from "../../SearchFilter";
import callBackImg from "../../../assets/svg/callBack.svg";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { AppContext } from "../../../context/AppContextProvider";
import SurveyContext from "../../../context/surveyContext";
import RequestUploadForm from "./RequestUploadForm";
import DeleteModal from "../../DeleteModal";

const SurveyRequestedList = () => {
  const userString = localStorage.getItem("admin-user");
  const user = JSON.parse(userString);
  const { handleClick, handleMouseEnter, handleMouseLeave, isDropdownOpen } = useContext(AppContext);
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const surveyContext = useContext(SurveyContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line
  const [sort, setSort] = useState("DES");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedRequestData, setSelectedRequestData] = useState(null);
  const [selectedSurveyId, setSelectedSurveyId] = useState(null);
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);

  // GET ALL SURVEY REQUESTED  DATA
  const fetchSurveyRequested = async () => {
    const response = await axiosInstance.get(
      `api/survey/client/getAllRequestedSurveyFromClient?page=${currentPage}&limit=${limit}&sort=${sort}`
    );
    setTotal(response.data.totalpages);
    return response;
  };
  const surveyRequestedData = useQuery(
    ["survey-requested-list", currentPage, limit, sort],
    () => fetchSurveyRequested(currentPage, limit, sort),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  // GET ALL SEARCH REQUESTED REPORT DATA
  const fetchSearchRequestedReports = async () => {
    let response;
    if (searchKeyword) {
      response = await axiosInstance.get(
        `api/survey/searchRequestedSurvey/requested?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}`
      );
      setTotal(response?.data?.totalpages);
    } else {
      response = await axiosInstance.get(
        `api/survey/client/getAllRequestedSurveyFromClient?page=${currentPage}&limit=${limit}&sort=${sort}`
      );
      setTotal(response?.data?.totalpages);
    }
    return response;
  };
  const requestedSearchData = useQuery(
    ["search-requested-survey", searchKeyword, currentPage, limit],
    () => fetchSearchRequestedReports(),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  const SearchUser = (value) => {
    setCurrentPage(1);
    setSearchKeyword(value);
  };

  // FOR PAGINATION
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };
  const pagination = {
    total: total * limit,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange
  };

  //For closing a survey request
  const closeSurveyRequestMutation = useMutation(async (surveyId) => {
    await axiosInstance.delete(`api/survey/closeSurveyRequest/${surveyId}`);
  });


  const handleSurveyClose = async (surveyId) => {
    try {
      await closeSurveyRequestMutation.mutateAsync(surveyId);
      queryClient.invalidateQueries("survey-requested-list");
      queryClient.invalidateQueries("search-requested-survey");
      toast.success('Survey request closed successfully!');
    } catch (error) {
      console.error('Error closing report request:', error);
    }
  };


  const openUploadModal = (record) => {
    surveyContext.updateOpenRequestModal(true);
    setSelectedRequestData(record);
  };
  const handleEdit = (record, type) => {
    setSelectedRequestData(record);
    surveyContext.updateEditRequestData(record);
    surveyContext.updateRequestAddMode(false);
    surveyContext.updateOpenRequestModal(true);
  };

  // Decline Survey
  const handleDeclineModal = (record) => {
    setSelectedSurveyId(record._id);
    setIsDeclineModalVisible(true);
  };

  const handleDecline = async () => {
    try {
      const response = await axiosInstance.put(`api/survey/declineSurveyRequest/${selectedSurveyId}`, {
        declinedById: user._id ,
        declinedDate: new Date(),
        declinedByName:`${user?.firstName} ${user?.lastName}`
      });
      const message = response?.data?.message;
      toast.success(`${message}`);
      queryClient.invalidateQueries("survey-requested-list");
      queryClient.invalidateQueries("search-requested-survey");
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong.");
      }
    }
  };

  const isLoading = surveyRequestedData.isLoading || requestedSearchData.isLoading
  return (
    <div className='report-page-section report-requested'>
      <div className='report-table-wrapper'>
        <div className='reports-table'>
          <SearchFilter handleSearch={SearchUser} />

          <Table
            rowKey={(record) => record._id}
            dataSource={
              requestedSearchData?.data?.data?.data ||
              surveyRequestedData?.data?.data?.data
            }
            className='report-tableRows'
            pagination={pagination}
            locale={{
              emptyText: isLoading ? (
                <div
                  style={{
                    marginLeft: "20px",
                    width: "95%"
                  }}
                >
                  {" "}
                  {/* Container with 90% width */}
                  <Skeleton
                    title={false}
                    active
                    paragraph={{
                      rows: 6,
                      width: ["100%", "100%", "100%", "100%", "100%", "100%"]
                    }}
                  />
                </div>
              ) : (
                <Empty />
              )
            }}
          >
            <Column
              title=''
              dataIndex='callBack'
              className='w-16'
              render={(title, record) => (
                <>
                  <div className='flex pl-5'>
                    {record.quickCall === "true" && (
                      <img src={callBackImg} alt='pin icon' className='mr-4' />
                    )}
                  </div>
                </>
              )}
            />
            <Column
              title='CLIENT NAME'
              dataIndex='clientName'
            />
            <Column
              title='SURVEY NAME'
              dataIndex='surveyName'
            />
            <Column
              title='DESCRIPTION'
              dataIndex='description'
              className='report-name-tooltip'
            />
            <Column
              title='DATE REQUESTED'
              dataIndex='createdAt'
              sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
              defaultSortOrder='descend'
              render={(text, record) => (
                <span>
                  {new Date(record.createdAt).toLocaleDateString("en-GB")}
                </span>
              )}
            />
            <Column title="ACTION" key="actions" className='w-44'
              render={(text, record, index) => (
                <Space className="select-option" size="small" style={{}}>
                  <div
                    className="dropdown"
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <span onClick={() => handleClick(index)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 34 30" fill="none">
                        <circle cx="17.3889" cy="9.38845" r="1.38889" transform="rotate(-90 17.3889 9.38845)" fill="#004877" />
                        <circle cx="17.3889" cy="15.4998" r="1.38889" transform="rotate(-90 17.3889 15.4998)" fill="#004877" />
                        <circle cx="17.3889" cy="21.6111" r="1.38889" transform="rotate(-90 17.3889 21.6111)" fill="#004877" />
                      </svg>
                    </span>
                    {isDropdownOpen(index) && (
                      <div className="dropdown-content">
                        {record.resourceLocation ? (
                          <div key={`editRequestSurvey-${index}`} onClick={() => handleEdit(record)}>
                            Edit Survey
                          </div>
                        ) : (
                          <div key={`statusRequestSurvey-${index}`} onClick={() => openUploadModal(record)}>
                            Upload Survey
                          </div>
                        )}
                        <div onClick={() => handleDeclineModal(record)}>
                          Decline Survey
                        </div>
                      </div>
                    )}
                  </div>
                </Space>
              )}
            />
            <Column
              key='action'
              className='w-36'
              render={(text, record) => (
                <Space>
                  <Button type="button" onClick={() => handleSurveyClose(record._id)} disabled={!record.resourceLocation}
                  >
                    Close Request
                  </Button>
                </Space>
              )}
            />
          </Table>

          <RequestUploadForm isOpen={surveyContext.isOpenRequestModal} isRequestAddMode={surveyContext.isRequestAddMode}
            selectedRequestData={selectedRequestData} />

          <DeleteModal
            textheading="Decline Survey"
            deleteTitle={'Survey'}
            deleteBtn="Decline"
            isDeleteModal={isDeclineModalVisible}
            handleDelete={handleDecline}
            showModalDelete={setIsDeclineModalVisible}
            action='decline'
          />

          <div className='report-pagination'>
            <div>
              <Select
                value={limit.toString()}
                style={{ width: 56 }}
                onChange={(value) => {
                  setLimit(parseInt(value));
                  setCurrentPage(1);
                }}
                options={[
                  // { value: "5", label: "5" },
                  { value: "10", label: "10" },
                  { value: "20", label: "20" },
                  { value: "50", label: "50" },
                  { value: "100", label: "100" }
                ]}
              />
              <span className='px-4'>Entries Per Pages</span>
            </div>
            <div className='report-selection'>
              <Pagination
                current={currentPage}
                pageSize={limit}
                total={total * limit}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                  setLimit(pageSize);
                }}
                className='pagination'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyRequestedList;
