import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {MdAdd} from 'react-icons/md';
import GmsClientList from "./GmsClient/GmsClientList";
import CategoryList from "./Category/CategoryList";
import CategoryForm from "./Category/CategoryForm";
import SubCategoryList from "./SubCategory/SubCategoryList";
import SubCategoryForm from "./SubCategory/SubCategoryForm";
import PageHeading from "../PageHeading";
import GmsContext from "../../context/gmsContext";

const GmsTabs = () => {

    const navigate = useNavigate();
    const gmsContext = useContext(GmsContext);
    
    const [activeTab, setActiveTab] = useState("gms-client");

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        navigate(`../${tab}`);
    };

    const handleModal = () => {
       gmsContext.updateOpenModal(true);
    };

    return (
        <>
            {activeTab === "gms-client" && <h1 className="heading-text">Grievance Management System (GMS)</h1>}

            {activeTab === "category" &&
            <PageHeading
                pageTitle='Grievance Management System (GMS)'
                icon={<MdAdd/>}
                pageModalTitle='Add New Category'
                pageModalOpen={handleModal}
            />
            }

            {activeTab === "sub-category" &&
            <PageHeading
                pageTitle='Grievance Management System (GMS)'
                icon={<MdAdd/>}
                pageModalTitle='Add New Sub Category'
                pageModalOpen={handleModal}
            />
            }

            <div className="tabs1">
                <div className="gms-table-tabs">
                    <button className={`tab-button tab-btn1 ${activeTab === "gms-client" ? "active" : ""}`}
                     onClick={() => handleTabChange("gms-client")} >
                     GMS Client
                    </button>

                    <button className={`tab-button tab-btn1 ${activeTab === "category" ? "active" : ""}`}
                     onClick={() => handleTabChange("category")}
                    >
                    Categories
                    </button>

                    <button className={`tab-button tab-btn1 ${activeTab === "sub-category" ? "active" : ""}`}
                     onClick={() => handleTabChange("sub-category")}
                    >
                    Sub Categories
                    </button>
                </div>
            </div>

            <div className="tab-content">
                {activeTab === "gms-client" && <GmsClientList/>}
                {activeTab === "category" && <CategoryList/>}
                {activeTab === "sub-category" && <SubCategoryList/>}
            </div>

            {activeTab === "category" && <CategoryForm isOpen={gmsContext.isOpenModal} isAddMode={gmsContext.isAddMode}/>}
            {activeTab === "sub-category" && <SubCategoryForm isOpen={gmsContext.isOpenModal} isAddMode={gmsContext.isAddMode}/>}
        </>
    );
};

export default GmsTabs;
