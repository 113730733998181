import React, { useState, useMemo, useEffect, useCallback } from "react";
import rightArrow from "../../../assets/images/client-icon/chevron-right.png";
import { NavLink } from "react-router-dom";
import { Select } from "antd";
import { MdAdd } from "react-icons/md";
import { InfoCircleOutlined } from "@ant-design/icons";
import VillageData from "./VillageInfo";
import ConstituencyData from "./ConstituencyInfo";
import ElectionDataTabs from "./ElectionData";
import ParliamentaryInformation from "./ParliamentaryInformation";
import AddElectionYear from "../AddElectionYear";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { useQuery } from "react-query";
import { getIsDataAvailable } from "../utils/getIsDataAvailable";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import exportIcon from "../../../assets/svg/exportFile.svg";

import Report from "./Report";

const ParliamentaryViewDetails = () => {
  const { selectedYear } = useParams();

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isAddYearModalOpen, setIsAddYearModalOpen] = useState(false);
  const [year, setYear] = useState(selectedYear || 2023);
  const [isYearAdded, setIsYearAdded] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState({});
  const [isExport, setIsExport] = useState(false);
  const [electionYears, setElectionYears] = useState([]);
  const [yearSelected, setYearSelected] = useState("");

  const polygonDataString = localStorage.getItem("polygonData");
  const polygonData = polygonDataString ? JSON.parse(polygonDataString) : {};

  const axiosInstance = useAxiosInstance();

  const isDataAvailable = getIsDataAvailable();

  const yearToUse =
    year === "undefined" ? electionYears?.data?.electionYears[0] : year;

  const handleRefreshClick = async () => {
    if (polygonData[0]?.constituencyId && yearToUse) {
      try {
        const response = await axiosInstance.get(
          `api/parliment/getParlimentsummaryById/${polygonData[0]?.constituencyId}?year=${yearToUse}`
        );

        const summaryData = response?.data?.data;
        const uploadSummaryDataString = JSON.stringify(summaryData);
        localStorage.setItem("summaryUploadData", uploadSummaryDataString);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      console.log("year is not selected");
    }
  };

  // useEffect(() => {
  //   const fetchElectionYears = async () => {
  //     try {
  //       const response = await axiosInstance.get(
  //         `api/constituency/getElectionYears/${polygonData[0]?.constituencyId}`
  //       );
  //       setElectionYears(response.data); // Assuming the response is an array of election years
  //     } catch (error) {
  //       console.error(error);
  //       // Handle error
  //     }
  //   };

  //   fetchElectionYears();
  // }, [isYearAdded]);

  useEffect(() => {
    handleRefreshClick();
  }, [yearToUse, refresh]);

  const fetchConstituencyTableById = async (constituencyId, yearToUse) => {
    try {
      const response = await axiosInstance.get(
        `api/parliment/getParlimentTableById/${constituencyId}?years=${yearToUse}`
      );
      // toast.success("Data Fetched Successfully");
      return response;
    } catch (error) {
      console.error("Error fetching constituency table:", error);
      throw error; // Rethrow the error to be caught by the useQuery hook
    }
  };

  const constituencyTableData = useQuery(
    [
      "client-constituency-table",
      polygonData[0]?.constituencyId,
      yearToUse,
      refresh
    ],
    () => fetchConstituencyTableById(polygonData[0]?.constituencyId, yearToUse),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled:
        polygonData[0]?.constituencyId !== undefined && yearToUse !== undefined,
      onError: (error) => {
        console.error("Error during useQuery:", error);
      }
    }
  );

  const constituencyDataList = useMemo(() => {
    return constituencyTableData?.data?.data?.data || {};
  }, [constituencyTableData?.data?.data?.data]);

  const constituencyLoading = constituencyTableData?.isLoading || true;

  const villagePoint = useMemo(() => {
    return (constituencyDataList?.villageData || []).map((village) => {
      if (village !== null && village?.location != null) {
        const parts = village?.location.split(", ");

        // Extract latitude and longitude values
        const lat = parseFloat(parts[0].split(":")[1].trim());
        const long = parseFloat(parts[1].split(":")[1].trim());

        return {
          lat: +lat,
          lng: +long,
          title: village?.villageName,
          village_id: village?._id,
          winnerParty: village?.winnerParty,
          runnerUpParty: village?.runnerUpParty,
          firstRunnerUpParty: village?.firstRunnerUpParty
        };
      } else if (village?.location === null) {
        return {
          lat: 28.7041,
          lng: 77.1025,
          title: village?.villageName,
          village_id: village?._id,
          winnerParty: village?.winnerParty,
          runnerUpParty: village?.runnerUpParty,
          firstRunnerUpParty: village?.firstRunnerUpParty
        };
      } else {
        return null;
      }
    });
  }, [constituencyDataList?.villageData]);

  const center = villagePoint.filter((position) => position !== null);

  const fetchVillageTableById = async (id, yearToUse) => {
    return axiosInstance.get(
      `api/parliment/getVillageTableById/${id}?years=${yearToUse}`
    );
  };

  const queryData = useQuery(
    ["village-data", selectedMarker?.village_id, yearToUse, refresh],
    () => fetchVillageTableById(selectedMarker?.village_id, yearToUse),
    {
      enabled: selectedMarker?.village_id !== undefined && year !== undefined,
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  const villageFetchedData = queryData?.data?.data?.data || {};
  const villageLoading = queryData?.isLoading || true;

  const villagesList = constituencyTableData?.data?.data?.data?.villageData;

  const handleVillageSelectChange = useCallback(
    (id) => {
      const newMarker = center.find((item) => item?.village_id === id);
      if (newMarker) {
        setSelectedMarker(newMarker);
      }
    },
    [center]
  );

  const viewInfo = useCallback(() => {
    setIsInfoModalOpen(true);
  }, []);

  const handleAddYear = useCallback(() => {
    setIsAddYearModalOpen(true);
  }, []);

  useEffect(() => {
    if (center.length > 0) {
      setSelectedMarker(center[0]);
    }
  }, [JSON.stringify(center)]);

  return (
    <>
      <div>
        <div className='view-constituency'>
          <div className='client-breadcrumbs'>
            <p className='flex'>
              <span className='flex'>
                <NavLink
                  to='/constituency-management'
                  className='nav-link flex'
                >
                  Constituency Management Dashboard{" "}
                  <img src={rightArrow} alt='arrow' />
                </NavLink>
              </span>
              <span>View Constituency </span>
            </p>
          </div>
          <div className='flex items-center justify-between mt-5'>
            <div className='constituency-name'>
              <p>
                {polygonData[0]?.name}{" "}
                <span className='mr-2'>
                  (Parliamentary), {polygonData[0]?.state}
                </span>
                <InfoCircleOutlined onClick={viewInfo} />
              </p>
            </div>
            <div className='pageTitle flex items-center mt-5'>
              <button
                onClick={() => {
                  setIsExport(true);
                }}
                className='mr-4'
              >
                <img src={exportIcon} alt='Export Icon' width={13} />
                Export Data
              </button>

              <Select
                id='villageSelect'
                value={selectedMarker?.village_id}
                onChange={handleVillageSelectChange}
                className='mr-4'
                placeholder='Select Village'
                showSearch // Enable showSearch
                optionFilterProp='children' // Specify the property to filter on
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={!isDataAvailable}
              >
                {(villagesList || [])
                  .sort((a, b) => a.villageName.localeCompare(b.villageName)) // Sort the array alphabetically
                  .map((village) => (
                    <Select.Option key={village?._id} value={village?._id}>
                      {village?.villageName}
                    </Select.Option>
                  ))}
              </Select>

              <Select
                className='mr-4'
                placeholder='Select Year'
                onChange={(value) => setYear(value)}
                value={yearToUse}
              >
                {/* {electionYears?.data?.electionYears.map((year) => (
                  <Select.Option key={year} value={year}>
                    {year}
                  </Select.Option>
                ))} */}
                <>
                  {" "}
                  <Select.Option value='2014'>2014</Select.Option>
                  <Select.Option value='2019'>2019</Select.Option>
                  <Select.Option value='2023'>2023</Select.Option>
                </>
              </Select>

              {/* <button className='rounded-lg' onClick={handleAddYear}>
                {" "}
                <MdAdd /> Add Year{" "}
              </button> */}
            </div>
          </div>
          <div className='flex mb-3 constituency-count'>
            <p className='mr-5'>
              Villages:<span> {constituencyDataList?.totalVillageCount}</span>
            </p>
            <p>
              Polling Stations:
              <span> {constituencyDataList?.totalPSCount}</span>
            </p>
          </div>

          <VillageData
            polygonData={polygonData}
            constituencyDataList={constituencyDataList}
            center={center}
            setSelectedMarker={setSelectedMarker}
            selectedMarker={selectedMarker}
            villageFetchedData={villageFetchedData}
            isDataAvailable={isDataAvailable}
            isLoading={villageLoading}
          />
          <ConstituencyData
            constituencyDataList={constituencyDataList}
            isDataAvailable={isDataAvailable}
            isLoading={constituencyLoading}
          />
        </div>
        <ElectionDataTabs
          polygonData={polygonData}
          year={yearToUse}
          handleRefreshClick={handleRefreshClick}
          setRefresh={setRefresh}
        />
      </div>

      {isExport && (
        <Report
          polygonData={polygonData}
          constituencyDataList={constituencyDataList}
          center={center}
          setSelectedMarker={setSelectedMarker}
          selectedMarker={selectedMarker}
          villageFetchedData={villageFetchedData}
          isDataAvailable={isDataAvailable}
          isExport={isExport}
          setIsExport={setIsExport}
        />
      )}
      <ParliamentaryInformation
        isViewDetails={isInfoModalOpen}
        setIsInfoModalOpen={setIsInfoModalOpen}
        polygonData={polygonData}
      />
      <AddElectionYear
        setIsYearAdded={setIsYearAdded}
        constituencyId={polygonData?.constituencyId}
        isAddYearModalOpen={isAddYearModalOpen}
        setIsAddYearModalOpen={setIsAddYearModalOpen}
      />
    </>
  );
};

export default ParliamentaryViewDetails;
