import React from "react";
import {ToastContainer} from "react-toastify";
import GmsTabs from "./GmsTabs";

const Result = () => {
    return (
        <div className="gms-page-section">
            <ToastContainer/>

            <div className="gms-wrapper">
                <GmsTabs/>
            </div>
        </div>
    );
};

export default Result;
