// import React from 'react'
// import BillingPlans from '../../components/Billing/BillingPlans'
// import "../../styles/billing.css"

// const Billing = () => {
 
//   return (
//     <>     
//       <BillingPlans />
//     </>
  
      
//   )
// }

// export default Billing;


import React from 'react';
import '../../styles/Report.css'
import Results from '../../components/Billing/Results';
import "../../styles/billing.css"

// import BillingContext from '../../context/billingContext';
import { BillingContextProvider } from '../../context/billingContext';

const Billing = () => {

  return (
    <>
      <BillingContextProvider>
        <Results />
      </BillingContextProvider>
    </>
  )
}

export default Billing
