import React, { useContext, useState } from "react";
import { Modal, Input,  Row, Col, Select, Upload, Spin,  Progress, Button } from "antd";
import { FileFilled, DownloadOutlined } from "@ant-design/icons";
import {  useMutation, useQueryClient } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { toast } from "react-toastify";
import { AiOutlineClose } from "react-icons/ai";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../../../components/Forms/ErrorMessage";
import SurveyContext from "../../../context/surveyContext";
const { TextArea } = Input;

const RequestUploadForm = ({ isOpen, isRequestAddMode, selectedRequestData }) => {
    const surveyContext = useContext(SurveyContext);
    const axiosInstance = useAxiosInstance();
    const queryClient = useQueryClient();
    const [fileError, setFileError] = useState({ isError: false, message: "" });
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showCompletedProgress, setShowCompletedProgress] = useState(false);


    const FormSchema = Yup.object().shape({
        resourceLocation: Yup.array().min(1, "File is required")
    });

    const formik = useFormik({
        initialValues: {
            resourceLocation: isRequestAddMode ? [] : surveyContext?.editRequestData?.resourceLocation ? [selectedRequestData.resourceLocation] : [],
            reportName: selectedRequestData ? selectedRequestData.surveyName : "",
            description: selectedRequestData ? selectedRequestData.description : ""
        },
        enableReinitialize: true,
        validationSchema: FormSchema,
        onSubmit: (values, onSubmitProps) => {
            handleSubmit(values, onSubmitProps);
        }
    });



    const handleSubmit = async (values, onSubmitProps) => {
        if (isRequestAddMode) {
            try {
                const formData = new FormData();
                formData.append('reportLink', values.resourceLocation[0]);
                await handleCreateReport.mutateAsync(formData);
                onSubmitProps.resetForm();
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        } else {
            try {
                const formData = new FormData();
                formData.append('reportLink', values.resourceLocation[0]);
                await handleCreateReport.mutateAsync(formData);
                onSubmitProps.resetForm();
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        }
    };

    const handleCreateReport = useMutation(
        (formData, onProgress) =>
            axiosInstance
                .put(`api/survey/uploadRequestedSurveyFileById/${selectedRequestData._id}`, formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        },
                        onUploadProgress: onProgress
                    })
                .then((response) => response.data),
        {
            onSuccess: (data) => {
                handleSuccess(data);
            },
            onError: (error) => {
                handleError(error);
            }
        }
    );

    const handleResetFile = () => {
        formik.setFieldValue("resourceLocation", []);
        setUploadProgress(0);
    };
    const handleSuccess = (data) => {
        queryClient.invalidateQueries("survey-requested-list");
      queryClient.invalidateQueries("search-requested-survey");
        closeModal();
        toast.success(`${data?.message}`);
        setShowCompletedProgress(false);
    };

    const handleError = (error) => {
        toast.error("Something went wrong.");
        closeModal();
    };
    const handleFileChange = async (info) => {
        const file = info.file;
        const supportedFormats = [".jpg", ".jpeg", ".png", ".pdf", ".doc", ".xls"];

        const isSupportedFormat = supportedFormats.some((format) =>
            file.name.toLowerCase().endsWith(format)
        );

        if (!isSupportedFormat) {
            setFileError({
                isError: true,
                message:
                    "Unsupported file format. Please upload a file with a valid format"
            });
        } else {
            formik.setFieldValue("resourceLocation", [file]);
            setUploadProgress(0);
        }
    };

    const closeModal = () => {
        surveyContext.updateOpenRequestModal(false);
        surveyContext.updateRequestAddMode(true);
        formik.resetForm();
        setShowCompletedProgress(false);
    };


    const handleDownload = (fileUrl) => {
        const link = document.createElement("a");
        link.href = fileUrl[0]?.publicUrl;
        link.download = fileUrl[0]?.name || "Uploaded Report";
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    };
    return (
        <>
            {isOpen && (
                <Modal
                    className='share-report-modal edit-reports'
                    open={isOpen}
                    onCancel={closeModal}
                    width={1040}
                    maskClosable={false}
                    footer={[
                        <button
                            key='upload-survey'
                            form='survey-form'
                            type='submit'
                            className='report-upload-modal-button'
                        >
                            {isRequestAddMode ? "Upload Survey" : "Save Changes"}
                        </button>
                    ]}
                >
                    <form id='survey-form' className="request-report-form modal-body-container" onSubmit={formik.handleSubmit}>
                        <div className='modal-title'>
                            <h3>{isRequestAddMode ? "Upload Survey" : "Edit Survey"}</h3>
                        </div>

                        <div className='modal-wrapper-body'>
                            <div className='modal-wrapper-content'>
                                {isRequestAddMode ? (
                                    <>
                                        <h4>Upload File</h4>
                                        <p className='pb-4'>
                                            Add your relevant survey documents here. (Supported
                                            formats are JPEG, PNG, PDF, DOC, XLS)
                                        </p>
                                    </>
                                ) : (
                                    <h4>View Documents</h4>
                                )}
                                <div className='file-upload'>
                                    <div className='mt-3 report-upload-file-info'>
                                        {formik?.values?.resourceLocation?.length === 0 ? (
                                            <Upload.Dragger
                                                key='select-file'
                                                className='custom-file-upload'
                                                name='resourceLocation'
                                                accept='.jpg,.jpeg,.png,.pdf,.doc,.xls'
                                                beforeUpload={() => false}
                                                onChange={handleFileChange}
                                            >
                                                {loading ? (
                                                    <Spin />
                                                ) : (
                                                    <>
                                                        {uploadProgress > 0 && (
                                                            <div className='p-4'>
                                                                <Progress
                                                                    percent={uploadProgress}
                                                                    status='active'
                                                                />
                                                                <span className='text-center font-bold'>
                                                                    Uploading File
                                                                </span>
                                                                {formik.values.resourceLocation.length > 0 && (
                                                                    <div className='fileUpload'>
                                                                        <FileFilled />
                                                                        <p>
                                                                            {formik?.values?.resourceLocation[0]?.name}
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}

                                                        {uploadProgress === 0 && (
                                                            <>
                                                                <span className='flex justify-center file-icon'>
                                                                    <svg
                                                                        width='22'
                                                                        height='16'
                                                                        viewBox='0 0 22 16'
                                                                        fill='none'
                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                    >
                                                                        <path
                                                                            d='M10 16H5.5C3.98 16 2.68333 15.4767 1.61 14.43C0.536667 13.3767 0 12.0933 0 10.58C0 9.28 0.39 8.12 1.17 7.1C1.95667 6.08 2.98333 5.43 4.25 5.15C4.67 3.61667 5.50333 2.37667 6.75 1.43C8.00333 0.476667 9.42 0 11 0C12.9533 0 14.6067 0.68 15.96 2.04C17.32 3.39333 18 5.04667 18 7C19.1533 7.13333 20.1067 7.63333 20.86 8.5C21.62 9.35333 22 10.3533 22 11.5C22 12.7533 21.5633 13.8167 20.69 14.69C19.8167 15.5633 18.7533 16 17.5 16H12V8.85L13.6 10.4L15 9L11 5L7 9L8.4 10.4L10 8.85V16Z'
                                                                            fill='#336D92'
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <p className='ant-upload-text'>
                                                                    Click to upload or drag and drop your files.
                                                                    <br /> (Maximum file size is 50MB)
                                                                </p>
                                                            </>
                                                        )}

                                                        {showCompletedProgress ? (
                                                            <div className=''>
                                                                <span className='text-right mr-8 font-bold'>
                                                                    100%
                                                                </span>
                                                                <Progress percent={100} status='completed' />
                                                            </div>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Upload.Dragger>
                                        ) : (
                                            <div className='fileUpload'>
                                                <div className='text-right'>
                                                    <button
                                                        onClick={handleResetFile}
                                                        className='reset-button font-bold'
                                                    >
                                                        <AiOutlineClose />
                                                    </button>
                                                </div>
                                                <div className='flex file-icon justify-between'>
                                                    <div className="flex items-center">
                                                        <FileFilled />
                                                        <p className="pl-2">{formik?.values.resourceLocation[0]?.name}</p>
                                                    </div>
                                                    <Button
                                                        type='link'
                                                        icon={<DownloadOutlined />}
                                                        onClick={() =>
                                                            handleDownload(formik.values.resourceLocation)
                                                        }
                                                    // style={{ zIndex: 1 }}
                                                    >
                                                        Download
                                                    </Button>
                                                </div>
                                            </div>
                                        )}

                                        {fileError.isError ? (
                                            <ErrorMessage
                                                hasError={Boolean(fileError.isError)}
                                                message={fileError.message}
                                            />
                                        ) : (
                                            <ErrorMessage
                                                hasError={Boolean(
                                                    formik.errors.resourceLocation &&
                                                    formik.touched.resourceLocation
                                                )}
                                                message={formik.errors.resourceLocation}
                                            />
                                        )}
                                    </div>
                                </div>

                                <h4 className='py-4'>Survey Details</h4>

                                <Row className='add-modal-row'>
                                    <Col span={20}>
                                        <label>Survey Name</label>
                                        <Input type='text' className="capitalize"
                                            placeholder='Type Survey Name'
                                            name='surveyName'
                                            onChange={formik.handleChange}
                                            value={selectedRequestData?.surveyName}
                                            showCount disabled
                                            maxLength={200}
                                        />
                                    </Col>
                                </Row>

                                <Row className='add-modal-row my-7'>
                                    <Col span={20}>
                                        <label>Survey Description</label>
                                        <TextArea
                                            className='pt-3 capitalize'
                                            rows={2} disabled
                                            placeholder='Write Survey Description'
                                            name='surveyDescription'
                                            value={selectedRequestData?.description}
                                        />
                                    </Col>
                                </Row>

                                <Row className='add-modal-row pb-8'>
                                    <Col span={24} className='edit-subscribers'>
                                        <label>Subscribers</label>
                                        <Select
                                            className='custom-dropdown capitalize'
                                            mode='multiple'
                                            // style={{ width: "100%" }}
                                            placeholder='Select subscribers'
                                            name='subscribers'
                                            showSearch disabled
                                            value={selectedRequestData?.clientName ? [selectedRequestData?.clientName] : []}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </form>
                </Modal>
            )}
        </>
    )
}

export default RequestUploadForm