import React, { useContext } from "react";
import { Space, Table, Skeleton, Empty } from "antd";
import Column from "antd/es/table/Column";
import TicketDetails from "./TicketDetails";
import { AppContext } from "../../context/AppContextProvider";
import CustSupportContext from "../../context/custSupportContext";

const CustSupportDataTable = ({
  ticketSearchData,
  pagination,
  ticketSummaryData,
  isLoading
}) => {
  const { handleMouseEnter, handleMouseLeave, isDropdownOpen } =
    useContext(AppContext);

  const custSupportContext = useContext(CustSupportContext);

  const handleEdit = (record) => {
    custSupportContext.updateEditData(record);
    // custSupportContext.updateAddMode(false);
    custSupportContext.updateOpenModal(true);
  };

  const handleTicketDetails = (record) => {
    custSupportContext.updateTicketDetails(record);
  };

  return (
    <>
      <Table
        rowKey={(record) => record._id}
        pagination={pagination}
        dataSource={
          ticketSearchData
            ? ticketSearchData.data?.data?.results
            : ticketSummaryData
        }
        locale={{
          emptyText: isLoading ? (
            <div
              style={{
                marginLeft: "20px",
                width: "95%"
              }}
            >
              {" "}
              {/* Container with 90% width */}
              <Skeleton
                title={false}
                active
                paragraph={{
                  rows: 6,
                  width: ["100%", "100%", "100%", "100%", "100%", "100%"]
                }}
              />
            </div>
          ) : (
            <Empty />
          )
        }}
      >
        <Column title='Ticket ID' dataIndex='_id' />
        <Column title='Issue Name' dataIndex='issueName' />
        <Column title='Category' dataIndex='category' />
        <Column title='Status' dataIndex='ticketStatus' />
        <Column
          title='Assigned to'
          dataIndex='assignedToUserName'
          render={(assignedToUserName) => {
            return assignedToUserName ? assignedToUserName : "--";
          }}
        />
        <Column
          title='ACTION'
          key='action'
          render={(text, record, index) => (
            <Space className='select-option' size='small' style={{}}>
              <div
                className='dropdown'
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='34'
                  height='30'
                  viewBox='0 0 34 30'
                  fill='none'
                >
                  <circle
                    cx='17.3889'
                    cy='9.38845'
                    r='1.38889'
                    transform='rotate(-90 17.3889 9.38845)'
                    fill='#004877'
                  />
                  <circle
                    cx='17.3889'
                    cy='15.4998'
                    r='1.38889'
                    transform='rotate(-90 17.3889 15.4998)'
                    fill='#004877'
                  />
                  <circle
                    cx='17.3889'
                    cy='21.6111'
                    r='1.38889'
                    transform='rotate(-90 17.3889 21.6111)'
                    fill='#004877'
                  />
                </svg>
                {isDropdownOpen(index) && (
                  <div className='dropdown-content'>
                    <div onClick={() => handleTicketDetails(record)}>
                      Ticket Details
                    </div>

                    <div onClick={() => handleEdit(record)}>Edit</div>
                  </div>
                )}
              </div>
            </Space>
          )}
        />
      </Table>
      <TicketDetails isOpenModal={custSupportContext.ticketDetails} />
    </>
  );
};

export default CustSupportDataTable;
