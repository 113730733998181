import React, { useContext, useState } from "react";
import { Modal } from "antd";
import "../../../styles/Client.css";
import { useMutation, useQueryClient } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import ClientContext from "../../../context/clientContext";
import ClientInfoForm from "./ClientInfoForm";
import MainOfficeForm from "./MainOfficeForm";
import SelectUserForm from "./SelectUserForm";

const ClientForm = ({ isOpen, isAddMode }) => {
  const clientContext = useContext(ClientContext);
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = useState(1);
  const [addedUsers, setAddedUsers] = useState([]);
  const [constituencyStates, setConstituencyStates] = useState(""); // Provide the initial state value
  const [constituencyDistricts, setConstituencyDistricts] = useState("");



  const FormSchema1 = Yup.object().shape({
    prefix:Yup.string().required("Name prefix is required"),
    adminFirstName: Yup.string().trim().required("First Name is required").min(3, "First Name is minimum 3 character").max(50, "First Name is maximum 50 character"),
    adminLastName: Yup.string().trim().required("Last Name is required").min(3, "Last Name is minimum 3 character").max(50, "Last Name is maximum 50 character"),
    adminEmail: Yup.string().trim().required("Email is required").email("Please enter a valid email address").matches("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$", "Valid email is required").min(10, "Email is minimum 10 character").max(100, "Email is maximum 100 character"),
    adminContact: Yup.string().required("Phone is required").min(10, "Phone must be 10 digit").max(10, "Phone must be 10 digit"),
    planType: Yup.array().min(1, "Plan Type is required"),
    planDuration: Yup.string().required("Plan Duration is required"),
    planAmount: Yup.number().required("Plan Amount is required").min(0, 'Plan Amount must be a positive value'),
    validityStart: Yup.string().trim().required('Validity Start is required'),
    password: Yup.string().trim().required("Password is required").min(8, "password is minimum 8 character"),
    confirmpass: Yup.string().trim().required("Passwords do not match").oneOf([Yup.ref("password"), null], "Password must match"),
    constituencyState: Yup.string().required("State is required"),
    constituencyDistrict: Yup.string().required("District is required"),
    constituencyName: Yup.string().required("Home Constituency is required"),
  });

  const formik1 = useFormik({
    initialValues: {
      prefix: isAddMode ? "" : clientContext.editData.prefix,
      adminFirstName: isAddMode ? "" : clientContext.editData.adminFirstName,
      adminLastName: isAddMode ? "" : clientContext.editData.adminLastName,
      adminEmail: isAddMode ? "" : clientContext.editData.adminEmail,
      adminContact: isAddMode ? null : Number(clientContext.editData.adminContact),
      planType: isAddMode ? [] : clientContext.editData.planType,
      planDuration: isAddMode ? "" : clientContext.editData.planDuration,
      validityStart: isAddMode ? "" : clientContext.editData.validityStart
        ? new Date(clientContext.editData.validityStart).toISOString().split('T')[0]
        : "",
      planAmount: isAddMode ? "" : clientContext.editData.planAmount,
      password: isAddMode ? "" : clientContext.editData.clientPassword || "",
      confirmpass: isAddMode ? "" : clientContext.editData.clientPassword || "",
      constituency: isAddMode ? [] : clientContext.editData.constituency,
      constituencyName: isAddMode ? null : clientContext.editData.constituency[0]?.constituencyName,
      constituencyState: isAddMode ? "" : clientContext.editData.constituency[0]?.constituencyState.toLowerCase(),
      constituencyDistrict: isAddMode ? "" : clientContext.editData.constituency[0]?.constituencyDistrict.toLowerCase(),
      selectedConstituency: isAddMode ? null : clientContext.editData.selectedConstituency,
    },
    enableReinitialize: true,
    validationSchema: FormSchema1,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit1(values, onSubmitProps);
    },
  });

  const handleSubmit1 = async (value) => {
    setCurrentStep(2);
  };

  const FormSchema2 = Yup.object().shape({
    headOffice: Yup.object().shape({
      officeName: Yup.string().trim().required('Office Name is required'),
      location: Yup.string().trim().required('Office Address is required').min(3, 'Office Address is minimum 3 character').max(50, 'Office Address is maximum 50 character'),
      officeState: Yup.string().trim().required('Office State is required'),
      officeCity: Yup.string().trim().required('Office City is required'),
    }),
  });

  const formik2 = useFormik({
    initialValues: {
      headOffice: {
        officeName: isAddMode ? "" : clientContext.editData.headOffice?.officeName || "",
        location: isAddMode ? "" : clientContext.editData.headOffice.location,
        officeState: isAddMode ? "" : clientContext.editData.headOffice.officeState,
        officeCity: isAddMode ? "" : clientContext.editData.headOffice.officeCity,
      },
    },
    enableReinitialize: true,
    validationSchema: FormSchema2,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit2(values, onSubmitProps);
    },
  });


  const handleSubmit2 = async (value) => {
    setCurrentStep(3);
  };



  const formik3 = useFormik({
    initialValues: {
      users: isAddMode ? [] : clientContext.editData.users.map(user => ({
        _id: user._id,
        firstName: user.firstName,
        role: user.role
      })),
    },
    enableReinitialize: true,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    },
  });

  const handleSubmit = async (value) => {
    const updatedHeadOffice = {
      ...formik2.values.headOffice,
      contactNumber: formik1.values.adminContact,
      email: formik1.values.adminEmail,
    };
    const data = {
      ...formik1.values,
      // ...formik2.values,
      ...value,
      headOffice: updatedHeadOffice,
      users: addedUsers,
    };

    if (isAddMode) {
      handleCreateClient.mutate(data);
    } else {
      handleUpdateClient.mutate(data);
    }
  };

  const handleCreateClient = useMutation((formData) => axiosInstance.post(`api/clients`, formData)
    .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess(data);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const handleUpdateClient = useMutation((formData) => axiosInstance.put(`api/clients/${clientContext.editData._id}`, formData)
    .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess(data);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const handleSuccess = (data) => {
    queryClient.invalidateQueries("clients");
    queryClient.invalidateQueries("allSearchClients");
    closeModal();
    toast.success(`${data?.message}`);
    formik1.resetForm();
    formik2.resetForm();
    formik3.resetForm();
    setCurrentStep(1);
    if (isAddMode) {
      setAddedUsers([]); // Reset addedUsers only for add mode
    }
  };

  const handleError = (error) => {
    if (error?.response?.data?.message) {
      let msg = error.response.data.message;
      console.log("Error message:", msg);
      if (msg.includes("email")) {
        formik1.setFieldError("email", msg);
        // toast.error(msg);
        setCurrentStep(1)
      }
    }
    toast.error(`${error?.response?.data?.message}`);
    setCurrentStep(1)
    // if (isAddMode) {
    //   setAddedUsers([]); // Reset addedUsers only for add mode
    // }
  };

  const closeModal = () => {
    queryClient.invalidateQueries("clients");
    clientContext.updateOpenModal(false);
    clientContext.updateAddMode(true);
    formik1.resetForm();
    formik2.resetForm();
    formik3.resetForm();
    setCurrentStep(1);
    setAddedUsers([]);
    setConstituencyStates("");
    setConstituencyDistricts("");
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      {isOpen && (
        <div className="client-modal-info">
          <Modal
            className="add-user-modal"
            open={isOpen}
            onCancel={closeModal}
            width={1040}
            maskClosable={false}
            footer={null}
          >
            <div className="client-modal">
              {currentStep === 1 && (
                <ClientInfoForm formik1={formik1} isAddMode={isAddMode}
                  constituencyStates={constituencyStates} setConstituencyStates={setConstituencyStates}
                  constituencyDistricts={constituencyDistricts} setConstituencyDistricts={setConstituencyDistricts}
                />
              )}

              {currentStep === 2 && (
                <MainOfficeForm formik1={formik1} formik2={formik2} isAddMode={isAddMode} handlePrevious={handlePrevious} />
              )}

              {currentStep === 3 && (
                <SelectUserForm formik3={formik3} isAddMode={isAddMode} handlePrevious={handlePrevious}
                  addedUsers={addedUsers} setAddedUsers={setAddedUsers}
                />
              )}
            </div>
          </Modal >
        </div >
      )}
    </>
  );
};

export default ClientForm;
