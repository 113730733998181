import React from "react";
import { Modal } from "antd";
import "../../styles/BasicInformation.css"
import CustSupportContext from "../../context/custSupportContext";
import { useContext } from "react";



const TicketDetails = ({ isOpenModal }) => {
  const custSupportContext = useContext(CustSupportContext);

  const closeModal = () => {
    custSupportContext.updateTicketDetails(null);
  };

  const selectedTicketView = custSupportContext.ticketDetails;

  return (
    <>
      {isOpenModal && (
        <Modal
          className="ticket-modal"
          open={selectedTicketView}
          onCancel={closeModal}
          width={996}
          height={824}
          // onCancel={handleClose}
          footer={false}
        >
          <div className='modal-title'>
            <h3>Ticket Details</h3>
          </div>
          <div className="modal-wrapper-body">
            <div className="pb-16 modal-wrapper-content">
              <div className=''>
                <div>
                  <div className='basicInfoDiv'>
                    <div className='firstname-field'>
                      <p><span>Logged By</span><span style={{ textTransform: "capitalize" }}>{selectedTicketView?.loggedBy}</span></p>
                      <p><span>Date</span><span>{new Date(selectedTicketView?.date).toLocaleDateString('en-GB')}</span></p>
                    </div>
                  </div>
                  <div className='basicInfoDiv'>
                    <div className='firstname-field'>
                      <p><span >Email</span><span>{selectedTicketView?.email}</span></p>
                      <p><span >Phone</span><span>{selectedTicketView?.phone}</span></p>
                    </div>
                  </div>
                  <div className='basicInfoDiv'>
                    <div className='firstname-field'>
                      <p><span>Description</span><span>{selectedTicketView?.comment}</span></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
export default TicketDetails;