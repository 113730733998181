import React, { useState, useContext } from "react";
import { Table, Pagination, Select, Skeleton, Empty } from "antd";
import SearchFilter from "../../SearchFilter";
import BillingContext from "../../../context/billingContext";
import InvoiceForm from "./InvoiceForm";

import { Link } from "react-router-dom";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { useQuery } from "react-query";

const { Column } = Table;
const durationMap = {
  monthly: "Monthly",
  quarterly: "Quarterly",
  half_yearly: "Half Yearly",
  yearly: "Yearly"
};

const BillingTable = () => {
  const axiosInstance = useAxiosInstance();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  // const [sort, setSort] = useState("DES");

  // FOR PAGINATION
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };
  const pagination = {
    total: total * limit,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange
  };

  const fetchInvoices = async (currentPage, limit) => {
    const response = await axiosInstance.get(
      `api/invoice/?page=${currentPage}&limit=${limit}`
    );
    return response;
  };

  const invoiceData = useQuery(
    ["invoice-list", currentPage, limit],
    () => fetchInvoices(currentPage, limit),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );
  const invoices = invoiceData?.data?.data?.message?.data || [];
  const isLoading = invoiceData?.isLoading;

  const data = invoices.map((invoice, index) => ({
    key: String(index + 1),
    _id: invoice?._id,
    name: `${invoice?.adminFirstName} ${invoice?.adminLastName}`,
    subscription: invoice?.planType.map((plan) => plan?.name).join(", "),
    duration: durationMap[invoice?.planDuration],
    amt: "₹" + parseFloat(invoice?.planAmount),
    date: invoice?.invoiceDetails[0]?.lastTransactionDate
      ? new Date(
          invoice?.invoiceDetails[0]?.lastTransactionDate
        ).toLocaleDateString()
      : "NA",
    ...invoice
  }));

  const billingContext = useContext(BillingContext);

  // const handleModal = () => {
  //   billingContext.updateOpenModal(true);
  // };

  // const handleEdit = (record) => {
  //   billingContext.updateEditData(record);
  //   billingContext.updateAddMode(false);
  //   billingContext.updateOpenModal(true);
  // };

  return (
    <>
      <SearchFilter />
      <div className=''>
        {/* <button onClick={() => handleModal()}>Upload Invoice</button> */}
        {/* <button onClick={() => handleEdit()}>Edit Invoice</button> */}

        <Table
          pagination={pagination}
          dataSource={data}
          className='billing-wrapper'
          locale={{
            emptyText: isLoading ? (
              <div
                style={{
                  marginLeft: "20px",
                  width: "95%"
                }}
              >
                {" "}
                {/* Container with 90% width */}
                <Skeleton
                  title={false}
                  active
                  paragraph={{
                    rows: 6,
                    width: ["100%", "100%", "100%", "100%", "100%", "100%"]
                  }}
                />
              </div>
            ) : (
              <Empty />
            )
          }}
        >
          <Column
            key='client_name'
            title='CLIENT NAME'
            dataIndex='name'
            render={(text, record) => (
              <Link
                to={`/billing/more-info/${record?._id}`}
                style={{ textDecoration: "underline", color: "var(--primary-color)" }}
                onClick={() => {
                  localStorage.setItem("clientInfo", JSON.stringify(record));
                }}
              >
                <span style={{ cursor: "pointer" }}>{text}</span>
              </Link>
            )}
            width='20%'
          />

          <Column
            key='plan'
            title='Plan'
            dataIndex='subscription'
            width='20%'
          />

          <Column key='duration' title='Duration' dataIndex='duration' />

          <Column key='amt' title='Plan Amount' dataIndex='amt' />

          <Column
            key='trans_date'
            title='Last Transaction Date'
            dataIndex='date'
            // sorter={(a, b) => new Date(a.date) - new Date(b.date)}
            // sortDirections={["ascend", "descend"]}
            className='custom-sorter w-21 mr-10'
            //   style={{width:"10% !important"}}
          />
        </Table>
        <div className='report-pagination '>
          <div>
            <Select
              value={limit.toString()}
              style={{ width: 56 }}
              onChange={(value) => {
                setLimit(parseInt(value));
                setCurrentPage(1);
              }}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "50", label: "50" },
                { value: "100", label: "100" }
              ]}
            />
            <span className='pl-2'> Entries per pages</span>
          </div>

          <div className='report-selection'>
            <Pagination
              className='pagination'
              current={currentPage}
              pageSize={limit}
              total={total * limit}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setLimit(pageSize);
              }}
            />
          </div>
        </div>

        <InvoiceForm
          isOpen={billingContext.isOpenModal}
          isAddMode={billingContext.isAddMode}
        />
      </div>
    </>
  );
};

export default BillingTable;
