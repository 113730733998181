import React, { useContext } from "react";
import { Modal, Select, Row, Col, Radio } from "antd";
// import { useQueryClient } from "react-query";
// import useAxiosInstance from '../../lib/useAxiosInstance';
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../../components/Forms/ErrorMessage";
import ConstituencyContext from "../../context/constituencyContext";

const ConstituencyExportForm = ({ isOpen, isExportMode }) => {
  const constituencyContext = useContext(ConstituencyContext);
  // const axiosInstance = useAxiosInstance();
  // const queryClient = useQueryClient();

  const FormSchema = Yup.object().shape({
    state: Yup.string().trim().required("State is required"),
    constituencyType: Yup.string()
      .trim()
      .required("Constituency Type is required"),
    constituency: Yup.string().trim().required("Constituency is required"),
    electionYear: Yup.string().required("Election Year is required"),
    exportType: Yup.string().required("Export Type is required")
  });

  const formik = useFormik({
    initialValues: {
      state: null,
      constituencyType: null,
      constituency: null,
      electionYear: null,
      exportType: ""
    },
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    }
  });

  const handleSubmit = async (value) => {
    // handleCreateUser.mutate(value);
  };

  const closeModal = () => {
    constituencyContext.updateOpenExportModal(false);
    constituencyContext.updateExportMode(true);
    formik.resetForm();
  };

  const allStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"
  ];
  return (
    <>
      {isOpen && (
        <Modal
          className='add-user-modal'
          open={isOpen}
          onCancel={closeModal}
          width={1040}
          footer={[
            <button
              key='export-data'
              form='export-form'
              type='submit'
              className='add-user-btn'
            >
              Export
            </button>
          ]}
        >
          <form id='export-form' onSubmit={formik.handleSubmit}>
            <div className='user-info-modal'>
              <div className='add-user-modal-header'>
                <h3>Export Data</h3>
              </div>
            </div>
            <div className='add-user-modal-body'>
              <div className='model-wrapper'>
                <Row className='add-user-modal-row'>
                  <Col span={8} className='mr-6'>
                    <label>Select State</label>
                    <Select
                      placeholder='Please Select'
                      name='state'
                      style={{ width: "100%" }}
                      value={formik.values.state}
                      onChange={(value) => formik.setFieldValue("state", value)}
                      options={allStates.map((state) => ({
                        value: state,
                        label: state
                      }))}
                    />
                    <ErrorMessage
                      hasError={Boolean(
                        formik.errors.state && formik.touched.state
                      )}
                      message={formik.errors.state}
                    />
                  </Col>
                  <Col span={8} className='mr-6'>
                    <label>Constituency Type</label>
                    <Select
                      placeholder='Please Select'
                      name='constituencyType'
                      style={{ width: "100%" }}
                      value={formik.values.constituencyType}
                      onChange={(value) =>
                        formik.setFieldValue("constituencyType", value)
                      }
                    >
                      <Select.Option value='anantapur'>
                        Parliamentary
                      </Select.Option>
                      <Select.Option value='akkalkuwa'>Assembly</Select.Option>
                    </Select>
                    <ErrorMessage
                      hasError={Boolean(
                        formik.errors.constituencyType &&
                          formik.touched.constituencyType
                      )}
                      message={formik.errors.constituencyType}
                    />
                  </Col>
                </Row>
                <Row className='add-user-modal-row'>
                  <Col span={8} className='mr-6'>
                    <label>Constituency</label>
                    <Select
                      placeholder='Please Select'
                      style={{ width: "100%" }}
                      name='constituency'
                      id='Constituency'
                      value={formik.values.constituency}
                      onChange={(value) =>
                        formik.setFieldValue("constituency", value)
                      }
                    >
                      <Select.Option value='anantapur'>Anantapur</Select.Option>
                      <Select.Option value='akkalkuwa'>Akkalkuwa</Select.Option>
                      <Select.Option value='shirpur'>Shirpur</Select.Option>
                      <Select.Option value='chalisgaon'>
                        Chalisgaon
                      </Select.Option>
                      <Select.Option value='malkapur'>Malkapur</Select.Option>
                    </Select>
                    <ErrorMessage
                      hasError={Boolean(
                        formik.errors.constituency &&
                          formik.touched.constituency
                      )}
                      message={formik.errors.constituency}
                    />
                  </Col>
                  <Col span={8} className='mr-6'>
                    <label>Election Year</label>
                    <Select
                      placeholder='Please Select'
                      name='electionYear'
                      style={{ width: "100%" }}
                      id='electionYear'
                      value={formik.values.electionYear}
                      onChange={(value) =>
                        formik.setFieldValue("electionYear", value)
                      }
                    >
                      <Select.Option value='2008'>2008</Select.Option>
                      <Select.Option value='2013'>2013</Select.Option>
                      <Select.Option value='2018'>2018</Select.Option>
                      <Select.Option value='2023'>2023</Select.Option>
                    </Select>
                    <ErrorMessage
                      hasError={Boolean(
                        formik.errors.electionYear &&
                          formik.touched.electionYear
                      )}
                      message={formik.errors.electionYear}
                    />
                  </Col>
                </Row>
                <Row className='add-user-modal-row constituency-radioBtn'>
                  <Col span={20}>
                    <label className='block'>Export as</label>
                    <Radio.Group
                      name='exportType'
                      className='flex'
                      onChange={(event) =>
                        formik.setFieldValue("exportType", event.target.value)
                      }
                      value={formik.values.exportType}
                    >
                      <Radio.Button value='pdf'>PDF</Radio.Button>
                      <Radio.Button value='excel' className='mx-3'>
                        Excel
                      </Radio.Button>
                      <Radio.Button value='csv'>CSV</Radio.Button>
                    </Radio.Group>
                    <ErrorMessage
                      hasError={Boolean(
                        formik.errors.exportType && formik.touched.exportType
                      )}
                      message={formik.errors.exportType}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default ConstituencyExportForm;
