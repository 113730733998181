import React, { useState } from "react";
import { Button, Modal, Col, Row, Input, Form } from "antd";
import { ChromePicker } from "react-color";

import useAxiosInstance from "../../lib/useAxiosInstance";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const AddNewParty = ({ isModalOpen, handleCancel }) => {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();

  const [value, setValue] = useState(1);
  const [formValue, setFormValue] = useState({});

  const onChange = (e) => {
    setValue(e.target.value);
  };
  const [color, setColor] = useState("#000000"); // Initial color

  const handleChange = (selectedColor) => {
    setColor(selectedColor.hex);
    setFormValue((prev) => ({
      ...prev,
      partyColorCode: selectedColor.hex || color
    }));
  };

  const handlePoliticalPartyCreate = useMutation(
    (formData) => {
      return axiosInstance
        .post(`api/politicalParty/`, formData)
        .then((response) => response.data);
    },
    {
      onSuccess: (data) => {
        toast.success("Party Created successfully");
        queryClient.invalidateQueries("party-list", { refetchActive: true });
        handleCancel();
      },
      onError: (error) => {
        toast.error("Failed to Create Party");
      }
    }
  );

  const handleOk = async () => {
    await handlePoliticalPartyCreate.mutateAsync(formValue);
  };

  return (
    <>
      <Modal
        className='custom-support-modal'
        open={isModalOpen}
        width={1040}
        height={824}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <Button
            type='button'
            className='gms-modal-button bg-sky-900 mr-10'
            onClick={handleOk}
          >
            Add New Party
          </Button>
        ]}
      >
        <Form className='cust-form'>
          <div className='modal-title'>
            <h3>Add New Party</h3>
          </div>
          <div className='modal-wrapper-body'>
            <div className='modal-wrapper-content mt-6 ml-4 gap-8'>
              <div className='grid grid-cols-1 pb-4'>
                <h4 className='text-heading'>Party Information</h4>
              </div>
              <Row key='partyName' className='add-modal-row Assigned'>
                <Col span={8}>
                  <label>Party Name</label>
                  <Input
                    placeholder='Enter Party Name'
                    onChange={(e) => {
                      setFormValue((prev) => ({
                        ...prev,
                        partyName: e.target.value
                      }));
                    }}
                    className='Assigned'
                  />
                </Col>

                <Col span={8} offset={1}>
                  {" "}
                  <label>Party Code</label>
                  <Input
                    type='text'
                    placeholder='Enter Party Code'
                    id='partyCode'
                    name='partyCode'
                    onChange={(e) => {
                      setFormValue((prev) => ({
                        ...prev,
                        partyCode: e.target.value
                      }));
                    }}
                    className='form-background rounded-lg border border-gray-30 text-base py-2 px-3'
                  />
                </Col>
              </Row>
              <Row key='partyColorCode' className='add-modal-row Assigned'>
                <Col span={8}>
                  <label>Party Color Code</label>

                  <input
                    type='text'
                    value={color}
                    placeholder='Enter Party Color Code'
                    name='partyColorCode'
                    readOnly // Prevent user input, show color changes from picker only
                    className='w-full rounded-lg border form-background border-gray-30 text-base py-2 px-3 mb-4'
                  />
                  <ChromePicker
                    color={color}
                    onChange={handleChange}
                    disableAlpha={true} // Optional: disable alpha channel
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddNewParty;
