import React, { useContext, useState } from 'react'
import { Button, Pagination, Select, Space, Table, Skeleton, Empty } from 'antd';
import Column from 'antd/es/table/Column';
import SearchFilter from '../../SearchFilter';
import callBackImg from '../../../assets/svg/callBack.svg'
import useAxiosInstance from '../../../lib/useAxiosInstance';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AppContext } from '../../../context/AppContextProvider';
import RequestUploadForm from './RequestUploadForm';
import ReportContext from '../../../context/reportContext';
import DeleteModal from '../../DeleteModal';

const ReportRequestedList = () => {
  const userString = localStorage.getItem("admin-user");
  const user = JSON.parse(userString);
  const { handleClick, handleMouseEnter, handleMouseLeave, isDropdownOpen } = useContext(AppContext);
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const reportContext = useContext(ReportContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line
  const [sort, setSort] = useState("DES");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedRequestData, setSelectedRequestData] = useState(null);
  const [selectedReportId, setSelectedReportId] = useState(null); 
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);

  // GET ALL REPORTS REQUESTED  DATA
  const fetchReportRequested = async () => {
    const response = await axiosInstance.get(
      `api/reports/getAllRequestedReportFromClient?page=${currentPage}&limit=${limit}&sort=${sort}`,
    );
    setTotal(response.data.totalpages)
    return response
  };
  const reportsRequestedData = useQuery(
    ["report-requested-list", currentPage, limit, sort],
    () => fetchReportRequested(currentPage, limit, sort),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  // GET ALL SEARCH REQUESTED REPORT DATA
  const fetchSearchRequestedReports = async () => {
    let response;
    if (searchKeyword) {
      response = await axiosInstance.get(
        `api/reports/searchRequestedReport/requested?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}&status=open`
      );
      setTotal(response?.data?.totalpages);
    }
    else {
      response = await axiosInstance.get(
        `api/reports/getAllRequestedReportFromClient?page=${currentPage}&limit=${limit}&sort=${sort}`
      );
      setTotal(response?.data?.totalpages);
    }
    return response;
  };

  const requestedSearchData = useQuery(
    ["search-requested", searchKeyword, currentPage, limit],
    () => fetchSearchRequestedReports(),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );


  const SearchUser = (value) => {
    setCurrentPage(1);
    setSearchKeyword(value);
  };

  // FOR PAGINATION
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const pagination = {
    total: total * limit,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange,
  };

  //For closing a report request
  const closeReportRequestMutation = useMutation(async (reportId) => {
    await axiosInstance.delete(`api/reports/closeReportRequest/${reportId}`);
  });


  const handleReportClose = async (reportId) => {
    try {
      await closeReportRequestMutation.mutateAsync(reportId);
      reportsRequestedData.refetch();
      queryClient.invalidateQueries("report-requested-list");
      queryClient.invalidateQueries("search-requested");
      toast.success('Report request closed successfully!');
    } catch (error) {
      console.error('Error closing report request:', error);
    }
  };

  const openUploadModal = (record) => {
    reportContext.updateOpenRequestModal(true);
    setSelectedRequestData(record);
  };

  const handleEdit = (record, type) => {
    setSelectedRequestData(record);
    reportContext.updateEditRequestData(record);
    reportContext.updateRequestAddMode(false);
    reportContext.updateOpenRequestModal(true);
  };

  // Decline Report
  const handleDeclineModal = (record) => {
    setSelectedReportId(record._id);
    setIsDeclineModalVisible(true);
  };

  const handleDecline = async () => {
    try {
      const response = await axiosInstance.put(`api/reports/declineReportRequest/${selectedReportId}`, {
        declinedById: user?._id ,
        declinedDate: new Date(),
        declinedByName:`${user?.firstName} ${user?.lastName}`
      });
      const message = response?.data?.message;
      toast.success(`${message}`);
      queryClient.invalidateQueries("report-requested-list");
      queryClient.invalidateQueries("search-requested");
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong.");
      }
    }
  };
  const isLoading = reportsRequestedData?.isLoading || requestedSearchData?.isLoading;
  return (
    <div className='report-page-section report-requested'>
      <div className='report-table-wrapper'>
        <div className="reports-table">
          <SearchFilter handleSearch={SearchUser} />

          <Table
            rowKey={(record) => record._id}
            dataSource={requestedSearchData?.data?.data?.data || reportsRequestedData?.data?.data?.data}
            className="report-tableRows"
            pagination={pagination}
            locale={{
              emptyText: isLoading ? (
                <div
                  style={{
                    marginLeft: "20px",
                    width: "95%"
                  }}
                >
                  {" "}
                  {/* Container with 90% width */}
                  <Skeleton
                    title={false}
                    active
                    paragraph={{
                      rows: 6,
                      width: ["100%", "100%", "100%", "100%", "100%", "100%"]
                    }}
                  />
                </div>
              ) : (
                <Empty />
              )
            }}
          >
            <Column title="" dataIndex="quickCall" className='w-16'
              render={(title, record) => (
                <>
                  <div className="flex pl-5">
                    {record.quickCall === 'true' && (
                      <img
                        src={callBackImg}
                        alt="pin icon"
                        className="mr-4"
                      />
                    )}
                  </div>
                </>
              )}
            />
            <Column title="CLIENT NAME" dataIndex="clientName" />
            <Column title="REPORT NAME" dataIndex="reportName" />
            <Column title="DESCRIPTION" dataIndex="description" className='report-name-tooltip' />
            <Column title="DATE REQUESTED" dataIndex="createdAt"
              sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
              defaultSortOrder="descend"
              render={(text, record) => (
                <span>
                  {new Date(record.createdAt).toLocaleDateString("en-GB")}
                </span>
              )}
            />
            <Column title="ACTION" key="actions"
              render={(text, record, index) => (
                <Space className="select-option" size="small" style={{}}>
                  <div
                    className="dropdown"
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <span onClick={() => handleClick(index)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 34 30" fill="none">
                        <circle cx="17.3889" cy="9.38845" r="1.38889" transform="rotate(-90 17.3889 9.38845)" fill="#004877" />
                        <circle cx="17.3889" cy="15.4998" r="1.38889" transform="rotate(-90 17.3889 15.4998)" fill="#004877" />
                        <circle cx="17.3889" cy="21.6111" r="1.38889" transform="rotate(-90 17.3889 21.6111)" fill="#004877" />
                      </svg>
                    </span>
                    {isDropdownOpen(index) && (
                      <div className="dropdown-content">
                        {record.resourceLocation ? (
                          <div key={`editRequestReport-${index}`} onClick={() => handleEdit(record)}>
                            Edit Report
                          </div>
                        ) : (
                          <div key={`statusRequestReport-${index}`} onClick={() => openUploadModal(record)}>
                            Upload Report
                          </div>
                        )}
                        <div onClick={() => handleDeclineModal(record)}>
                          Decline Report
                        </div>
                      </div>
                    )}
                  </div>
                </Space>
              )}
            />
            <Column key="action"
              render={(text, record) => (
                <Space>
                  <Button type="button" onClick={() => handleReportClose(record._id)}
                    disabled={!record.resourceLocation}>
                    Close Request
                  </Button>
                </Space>
              )}
            />
          </Table>


          <RequestUploadForm isOpen={reportContext.isOpenRequestModal} isRequestAddMode={reportContext.isRequestAddMode}
            selectedRequestData={selectedRequestData}
          />
          <DeleteModal
            textheading="Decline Report"
            deleteTitle={'Report'}
            deleteBtn="Decline"
            isDeleteModal={isDeclineModalVisible}
            handleDelete={handleDecline}
            showModalDelete={setIsDeclineModalVisible}
            action='decline'
          />
          <div className="report-pagination">
            <div>
              <Select
                value={limit.toString()}
                style={{ width: 56 }}
                onChange={(value) => {
                  setLimit(parseInt(value)); setCurrentPage(1);
                }}
                options={[
                  { value: "10", label: "10" },
                  { value: "20", label: "20" },
                  { value: "50", label: "50" },
                  { value: "100", label: "100" },


                ]}
              />
              <span className="px-4">Entries Per Pages</span>
            </div>
            <div className='report-selection'>

              <Pagination
                current={currentPage}
                pageSize={limit}
                total={total * limit}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                  setLimit(pageSize);
                }}
                className="pagination"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportRequestedList