import React, { useState } from 'react'
import useAxiosInstance from '../../../lib/useAxiosInstance';
import { useQuery } from 'react-query';
import SearchFilter from '../../SearchFilter';
import { Pagination, Select, Table, Skeleton, Empty } from 'antd';
import callBackImg from "../../../assets/svg/callBack.svg";
import Column from 'antd/es/table/Column';

const CompletedRequestList = () => {
    const axiosInstance = useAxiosInstance();
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    // eslint-disable-next-line
    const [sort, setSort] = useState("DES");
    const [searchKeyword, setSearchKeyword] = useState("");

    // GET ALL COMPLETED SURVEY REQUEST DATA
    const fetchSurveyCompleted = async () => {
        const response = await axiosInstance.get(
            `api/survey/client/getAllCompletedRequestedSurveyFromClient?page=${currentPage}&limit=${limit}&sort=${sort}`,
        );
        setTotal(response.data.totalpages)
        return response
    };
    const surveyCompletedData = useQuery(
        ["completed-report-request", currentPage, limit, sort],
        () => fetchSurveyCompleted(currentPage, limit, sort),
        {
            refetchOnWindowFocus: false,
            retry: 1
        }
    );

    // GET ALL SEARCH COMPLETED SURVEY REQUEST DATA
    const fetchSearchRequestedSurveys = async () => {
        let response;
        if (searchKeyword) {
            response = await axiosInstance.get(
                `api/survey/searchRequestedSurvey/requested?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}&status=close`
            );
            setTotal(response?.data?.totalpages);
        }
        else {
            response = await axiosInstance.get(
                `api/survey/client/getAllCompletedRequestedSurveyFromClient?page=${currentPage}&limit=${limit}&sort=${sort}`
            );
            setTotal(response?.data?.totalpages);
        }
        return response;
    };

    const completedSearchSurveyData = useQuery(
        ["search-survey-completed", searchKeyword, currentPage, limit],
        () => fetchSearchRequestedSurveys(),
        {
            refetchOnWindowFocus: false,
            retry: 1
        }
    );


    const SearchCompletedSurveyRequest = (value) => {
        setCurrentPage(1);
        setSearchKeyword(value);
    };

    // FOR PAGINATION
    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setLimit(pageSize);
    };

    const pagination = {
        total: total * limit,
        pageSize: limit,
        current: currentPage,
        onChange: handlePageChange,
    };
    const isLoading = surveyCompletedData.isLoading || completedSearchSurveyData.isLoading
    return (
        <>
            <div className='report-page-section report-requested'>
                <div className='report-table-wrapper'>
                    <div className="reports-table">
                        <SearchFilter
                            handleSearch={SearchCompletedSurveyRequest}
                        />

                        <Table
                            rowKey={(record) => record._id}
                            dataSource={completedSearchSurveyData?.data?.data?.data || surveyCompletedData?.data?.data?.data}
                            className="report-tableRows"
                            pagination={pagination}
                            locale={{
                                emptyText: isLoading ? (
                                    <div
                                        style={{
                                            marginLeft: "20px",
                                            width: "95%"
                                        }}
                                    >
                                        {" "}
                                        {/* Container with 90% width */}
                                        <Skeleton
                                            title={false}
                                            active
                                            paragraph={{
                                                rows: 6,
                                                width: ["100%", "100%", "100%", "100%", "100%", "100%"]
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <Empty />
                                )
                            }}
                        >
                            <Column
                                title=''
                                dataIndex='callBack'
                                className='w-16'
                                render={(title, record) => (
                                    <>
                                        <div className='flex pl-3'>
                                            {record.quickCall === "true" && (
                                                <img src={callBackImg} alt='pin icon' className='mr-4' />
                                            )}
                                        </div>
                                    </>
                                )}
                            />
                            <Column title="CLIENT NAME" dataIndex="clientName" className='' />
                            <Column title="SURVEY NAME" dataIndex="surveyName" />
                            <Column title="DESCRIPTION" dataIndex="description" className='report-name-tooltip' />
                            <Column title="REQ. DATE" dataIndex="createdAt"
                                sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
                                defaultSortOrder="descend"
                                render={(text, record) => (
                                    <span>
                                        {new Date(record.createdAt).toLocaleDateString("en-GB")}
                                    </span>
                                )}
                            />
                            <Column title="SUBMITTED DATE" dataIndex="createdAt"
                                sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
                                defaultSortOrder="descend"
                                render={(text, record) => (
                                    <span>
                                        {new Date(record.createdAt).toLocaleDateString("en-GB")}
                                    </span>
                                )}
                            />


                        </Table>



                        <div className="report-pagination">
                            <div>
                                <Select
                                    value={limit.toString()}
                                    style={{ width: 56 }}
                                    onChange={(value) => {
                                        setLimit(parseInt(value)); setCurrentPage(1);
                                    }}
                                    options={[
                                        { value: "10", label: "10" },
                                        { value: "20", label: "20" },
                                        { value: "50", label: "50" },
                                        { value: "100", label: "100" },


                                    ]}
                                />
                                <span className="px-4">Entries Per Pages</span>
                            </div>
                            <div className='report-selection'>

                                <Pagination
                                    current={currentPage}
                                    pageSize={limit}
                                    total={total * limit}
                                    onChange={(page, pageSize) => {
                                        setCurrentPage(page);
                                        setLimit(pageSize);
                                    }}
                                    className="pagination"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompletedRequestList