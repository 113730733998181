import React from "react";
import { Modal, Input, Row, Col, Radio, Form } from "antd";
// import PaymentConfirmationModal from "./PaymentConfirmationModal";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";

const RecordPayment = ({ recordPayment, setRecordPayment, selectedRecord }) => {
  const axiosInstance = useAxiosInstance();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  // const [isConfirmModal, setIsConfirmModal] = useState(false);

  const handleSubmit = (values) => {
    const recordId = selectedRecord?._id;
    const data = {
      ...values,
      transactionAmount: Number(values.transactionAmount)
    };

    axiosInstance
      .post(`/api/invoice/recordPayment/${recordId}`, data)
      .then((response) => {
        console.log("API response:", response.data);

        form.resetFields();
        // setIsConfirmModal(true);
        setRecordPayment(false);

        toast.success("Payment Recorded Successfully");

        // Invalidate the query
        queryClient.invalidateQueries("invoice-list");
      })
      .catch((error) => {
        console.error("Error recording payment:", error);
        toast.error("Failed to record payment. Please try again.");
      });
  };

  const closeModal = () => {
    setRecordPayment(false);
  };

  return (
    <>
      {recordPayment && (
        <Modal
          className='add-user-modal'
          open={recordPayment}
          onCancel={closeModal}
          width={1040}
          maskClosable={false}
          footer={[
            <button
              key='add-user'
              form='record-payment'
              type='submit'
              className='gms-modal-button'
            >
              Record Payment
            </button>
          ]}
        >
          <Form id='record-payment' onFinish={handleSubmit} form={form}>
            <div className='user-info-modal'>
              <div className='modal-title'>
                <h3>Record A Payment</h3>
              </div>
              <div className='modal-wrapper-body'>
                <div className='modal-wrapper-content'>
                  <div className='flex justify-between'>
                    <h4>Invoice Details</h4>
                    <div>
                      <span style={{ color: "var(--dark-color)" }}>Outstanding Amount:{" "}</span>
                      <span className='text-red' style={{ color: "red" }}>
                        {selectedRecord?.Outstanding_amt}{" "}
                      </span>
                    </div>
                  </div>
                  <Row className='add-user-modal-radio mb-5'>
                    <Col>
                      <label className='opacity-60'>Status</label>
                      <Form.Item
                        name='invoiceStatus'
                        rules={[
                          {
                            required: true,
                            message: "Please select the paid type"
                          }
                        ]}
                      >
                        <Radio.Group
                          className='mt-1 opacity-100'
                          name='invoiceStatus'
                        >
                          <Radio value='unpaid'>Unpaid</Radio>
                          <Radio value='partiallyPaid'>Partially Paid</Radio>
                          <Radio value='fullyPaid'>Fully Paid</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className='add-modal-row'>
                    <Col span={8} className='mr-6'>
                      <label htmlFor=''>Amount Paid</label>
                      <Form.Item
                        name='transactionAmount'
                        rules={[
                          {
                            required: true,
                            message: "Please enter the paid amount"
                          }
                        ]}
                      >
                        <Input type='number' name='transactionAmount' />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <label htmlFor=''>Date of Payment</label>
                      <Form.Item
                        name='transactionDate'
                        rules={[
                          {
                            required: true,
                            message: "Payment Date is required"
                          }
                        ]}
                      >
                        <Input type='date' name='transactionDate' />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className='add-modal-row'>
                    <Col span={8} className='mr-6'>
                      <label htmlFor=''>Transaction ID</label>
                      <Form.Item
                        name='transactionId'
                        rules={[
                          {
                            required: true,
                            message: "Transaction Id is required"
                          }
                        ]}
                      >
                        <Input
                          type='text'
                          placeholder='₹'
                          name='transactionId'
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default RecordPayment;
