import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "antd";
import { AppContext } from "../context/AppContextProvider";
import logout from "../assets/svg/logout.svg";
import sidebarToggle from "../assets/svg/sidebar-toggle.svg";
import Logo from "../assets/images/ps-logo.png";
import Logo1 from "../assets/images/polstrat-logo.png";
import LogOut from "./Modal/LogOut";
import SidebarMapping from "../utils/sidebarMapping.js";

const Sidebar = ({ role = "admin" }) => {
  const { isOpen, toggle } = useContext(AppContext);
  const [logoutModal, setLogoutModal] = useState(false);

  const mappedSidebar = SidebarMapping();
  // const menuItem = mappedSidebar[role];
  const mainMenuItems = mappedSidebar[role].slice(0, -2);
  const bottomMenuItems = mappedSidebar[role].slice(-2);

  return (
    <div style={{ width: isOpen ? "284px" : "80px" }} className='container'>
      <div style={{ width: isOpen ? "284px" : "80px" }} className='sidebar'>
        <div className='sidebar-content flex flex-col justify-between'>
          <div>
            <div className={`navLeft ${isOpen ? "sidebarLeft" : ""}`}>
              <img
                src={sidebarToggle}
                className={`menubar ${isOpen ? "sidebarToggle" : ""}`}
                alt=''
                onClick={toggle}
                size='1.3em'
              />
              <div className='logo'>
                <img src={isOpen ? Logo1 : Logo} alt='Logo' />
              </div>
            </div>
            {mainMenuItems.map((item) => (
              <Tooltip placement='right' key={item.id} title={item.name}>
                <NavLink
                  to={item.path}
                  className={`link ${isOpen ? "sidebarLeft" : ""} ${item.id === 11 ? "settings-link" : ""} `}
                  activeclasname='active'
                >
                  <div className='icon'>{item.icon}</div>
                  <div
                    style={{ display: isOpen ? "block" : "none" }}
                    className='link_text'
                  >
                    {item.name}
                  </div>
                </NavLink>
              </Tooltip>
            ))}
          </div>
          <div>
            {bottomMenuItems.map((item) => (
              <Tooltip placement='right' key={item.id} title={item.name}>
                <NavLink
                  to={item.path}
                  className={`link ${isOpen ? "sidebarLeft" : ""} ${item.id === 11 ? "settings-link" : ""} `}
                  activeclasname='active'
                >
                  <div className='icon'>{item.icon}</div>
                  <div
                    style={{ display: isOpen ? "block" : "none" }}
                    className='link_text'
                  >
                    {item.name}
                  </div>
                </NavLink>
              </Tooltip>
            ))}
            <Tooltip placement='right' title='Logout'>
              <div
                className={`link logout ${isOpen ? "sidebarLeft" : ""}`}
                onClick={() => setLogoutModal(true)}
              >
                <div className='icon'>
                  <img src={logout} alt='' />
                </div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className='link_text'
                >
                  Logout
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <LogOut logoutModal={logoutModal} setLogoutModal={setLogoutModal} />
    </div>
  );
};

export default Sidebar;
