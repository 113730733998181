import React from 'react'
import assigned from "../../assets/images/assigned.svg";
import unassigned from "../../assets/images/unassigned.svg";
import pending from "../../assets/images/pending.svg";
import resolved from "../../assets/images/resolved.svg";
import allTicketsImg from "../../assets/images/alltickets.svg";
import { useQuery } from 'react-query';

const TicketSummary = ({ fetchTicketSummary,selectedTicket, setSelectedTicket, setCurrentPage }) => {
    
    const allTicketsData = useQuery(["allTickets"], () => fetchTicketSummary("all"));
    const assignedTicketsData = useQuery(["assignedTickets"], () => fetchTicketSummary("assigned"));
    const unassignedTicketsData = useQuery(["unassignedTickets"], () => fetchTicketSummary("open"));
    const pendingTicketsData = useQuery(["pendingTickets"], () => fetchTicketSummary("pending"));
    const resolvedTicketsData = useQuery(["resolvedTickets"], () => fetchTicketSummary("resolved"));

    const allTickets = allTicketsData?.data?.TicketCount || 0;
    const assignedTickets = assignedTicketsData?.data?.TicketCount || 0;
    const unassignedTickets = unassignedTicketsData?.data?.TicketCount || 0;
    const pendingTickets = pendingTicketsData?.data?.TicketCount || 0;
    const resolvedTickets = resolvedTicketsData?.data?.TicketCount || 0;

    return (
        <>
            <div className="customer-support-stats">
                <div className="w-full grid grid-cols-10 gap-12 grid-parent overview-top py-5">
                    <div className={`col-span-12 sm:col-span-2 ${selectedTicket === 'all' ? 'selected-ticket' : ''}`}>
                        <div className="p-3 cust-supprt-tabs flex flex-col items-center"
                            onClick={() => {
                                setSelectedTicket('all');
                                setCurrentPage(1)
                            }}
                        >
                            <div className="cust-img-wrapper pl-2" style={{ background: "#3BBEE8" }}>
                                <img src={allTicketsImg} alt="stat" />
                            </div>
                            <h2 className="mt-2">{allTickets}</h2>
                            <p>All Tickets</p>
                        </div>
                    </div>
                    <div className={`col-span-12 sm:col-span-2 ${selectedTicket === 'assigned' ? 'selected-ticket' : ''}`}>
                        <div className="p-3 cust-supprt-tabs flex flex-col items-center"
                            onClick={() => {
                                setSelectedTicket('assigned');
                                setCurrentPage(1)
                            }}
                        >
                            <div className="cust-img-wrapper" style={{ background: "var(--assigned-ticket)" }}>
                                <img src={assigned} alt="stat" />
                            </div>
                            <h2 className="mt-2">{assignedTickets}</h2>
                            <p>Assigned Tickets</p>
                        </div>
                    </div>
                    <div className={`col-span-12 sm:col-span-2 ${selectedTicket === 'open' ? 'selected-ticket' : ''}`}>
                        <div className="p-3  cust-supprt-tabs flex flex-col items-center"
                            onClick={() => {
                                setSelectedTicket('open');
                                setCurrentPage(1)
                            }}
                        >
                            <div className="cust-img-wrapper" style={{ background: "#59748A" }}>

                                <img src={unassigned} alt="stat" />
                            </div>

                            <h2 className="mt-2">{unassignedTickets}</h2>
                            <p>Unassigned Tickets</p>
                        </div>
                    </div>
                    <div className={`col-span-12 sm:col-span-2 ${selectedTicket === 'pending' ? 'selected-ticket' : ''}`}>

                        <div className="bg-white p-3 cust-supprt-tabs flex flex-col items-center"
                            onClick={() => {
                                setSelectedTicket('pending');
                                setCurrentPage(1)
                            }}
                        >
                            <div className="cust-img-wrapper" style={{ background: "#E67E22" }}>
                                <img src={pending} alt="stat" />
                            </div>
                            <h2 className="mt-2">{pendingTickets}</h2>
                            <p>Pending Tickets</p>
                        </div>
                    </div>
                    <div className={`col-span-12 sm:col-span-2 ${selectedTicket === 'resolved' ? 'selected-ticket' : ''}`}>

                        <div className="bg-white p-3 cust-supprt-tabs flex flex-col items-center"
                            onClick={() => {
                                setSelectedTicket('resolved');
                                setCurrentPage(1)
                            }}
                        >
                            <div className="cust-img-wrapper" style={{ background: "#27AE60" }}>
                                <img src={resolved} alt="stat" />
                            </div>
                            <h2 className="mt-2">{resolvedTickets}</h2>
                            <p>Resolved Tickets</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TicketSummary

