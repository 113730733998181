import React from "react";
import "../../styles/cust-supprt.css";
import { CustSupportContextProvider } from "../../context/custSupportContext";
import Result from "../../components/CustomerSupport/Result";



const CustomerSupport = () => {


  return (
    <>
      <CustSupportContextProvider>
        <Result />
      </CustSupportContextProvider>
    </>
  );
};
export default CustomerSupport;
