import React, { useEffect } from "react";
import "../../../styles/overView.css";
const Stats = (props) => {
  useEffect(() => {
    const element = document.getElementById(props.title);
    if (element) {
      animateValue(element, 0, parseInt(props.stat), 600);
    }
  }, [props.stat, props.title]);

  function animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    end = isNaN(end) ? 0 : end;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.textContent = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  return (
    <div className='billing-stats mt-3'>
      <div className='overview-stats flex flex-row '>
        <div className='stats-icon'>
          <img src={props.icon} alt='' />
        </div>

        <div className='stats-wrapper'>
          <div className='stats-content'>
            <div className='float-child flex'>
              <p className='stats-stat mr-4' id={props.title}>
                {props.stat}
              </p>
              <div className='float-child-arrow'>
                <div className='change-icon'>
                  <i>{props.changeIcon}</i>
                </div>
              </div>
              <div className='float-child-arrow'>
                <p className='stats-percent'>{props.percent}</p>
              </div>
            </div>
            <p className='stats-title'>{props.title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;
