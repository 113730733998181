import React from 'react'
import { Col, Input, Modal, Radio, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import CustSupportContext from '../../context/custSupportContext';
import useAxiosInstance from '../../lib/useAxiosInstance';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import ErrorMessage from '../Forms/ErrorMessage';


const CustSupportForm = ({ isOpen }) => {
    const custSupportContext = useContext(CustSupportContext);
    const axiosInstance = useAxiosInstance();
    const queryClient = useQueryClient();

    const FormSchema = Yup.object().shape({
        assignedToUserName: Yup.string().required('Please select user to assign ticket'),
        ticketStatus: Yup.string().required('Ticket status is required').test('valid-status', 'Ticket status is required', (value) => {
            return value === 'pending' || value === 'resolved';
        }),
        comment: Yup.string().required('Description is required')
    });

    const formik = useFormik({
        initialValues: {
            _id: custSupportContext.editData._id,
            // companyName:custSupportContext.editData.companyName,
            issueName: custSupportContext.editData.issueName,
            loggedBy: custSupportContext.editData.loggedBy,
            email: custSupportContext.editData.email,
            phone: custSupportContext.editData.phone,
            date: custSupportContext.editData.date,
            category: custSupportContext.editData.category,
            assignedToUserName: custSupportContext.editData.assignedToUserName,
            assignedTo: custSupportContext.editData.assignedTo,
            comment: custSupportContext.editData.comment,
            ticketStatus: custSupportContext.editData.ticketStatus,
        },
        enableReinitialize: true,
        validationSchema: FormSchema,
        onSubmit: (values, onSubmitProps) => {
            handleSubmit(values, onSubmitProps);
        }
    });

    const handleSubmit = async (value) => {

        handleUpdateCustSupport.mutate(value);
    };
    const handleUpdateCustSupport = useMutation((formData) => axiosInstance.put(`api/tickets/${custSupportContext.editData._id}`, formData).then((response) => response.data),
        {
            onSuccess: (data) => {
                handleSuccess(data);
            },
            // onError: (error) => {
            //     handleError(error);
            // },
        }
    );

    const handleSuccess = (data) => {
        queryClient.invalidateQueries("customerData");
        queryClient.invalidateQueries("assignedTickets");
        queryClient.invalidateQueries("unassignedTickets");
        queryClient.invalidateQueries("pendingTickets");
        queryClient.invalidateQueries("resolvedTickets");
        queryClient.invalidateQueries("allSearchTickets");
        queryClient.invalidateQueries("ticketSummary");
        closeModal();
        toast.success(`${data?.message}`);
    };
    const closeModal = () => {
        custSupportContext.updateOpenModal(false);
        // custSupportContext.updateAddMode(true);
        formik.resetForm();
    };

    // USERS
    const fetchAssignUser = async () => {
        return axiosInstance.get("api/users/info");
    };
    const assignUserQuery = useQuery("assign-user", fetchAssignUser);
    const handleSelectUser = (value) => {
        const selectedUser = assignUserQuery.data?.data?.data?.results?.find((user) => user._id === value);

        if (selectedUser) {
            formik.setFieldValue('assignedToUserName', `${selectedUser.firstName} ${selectedUser.lastName}`);
            formik.setFieldValue('assignedTo', value);
        }
    };
    return (
        <div>
            {isOpen && (
                <Modal
                    className="custom-support-modal"
                    open={isOpen}
                    width={1040}
                    maskClosable={false}
                    onCancel={closeModal}
                    footer={[
                        <button key="edit_tickets" form="cust-forms" type='submit' className='gms-modal-button bg-sky-900 mr-10'>
                            Save Changes
                        </button>
                    ]}
                >
                    <form className="cust-form modal-body-container" id='cust-forms' onSubmit={formik.handleSubmit}>
                        <div className='modal-title'>
                            <h3>Edit Tickets</h3>
                        </div>
                        <div className="modal-wrapper-body">
                            <div className=" modal-wrapper-content my-2">
                                <div className="grid grid-cols-1">
                                    <div className="pt-4 pb-4">
                                        <h4 className="text-xl font-black">Ticket Details</h4>
                                    </div>
                                </div>

                                <Row className="add-modal-row Assigned">
                                    <Col span={8} >
                                        <label className="block">Ticket ID</label>
                                        <Input type="text" id="name" readOnly
                                            name="id" value={formik.values._id}
                                            className="rounded-lg border border-gray-30 text-base"
                                        />
                                    </Col>

                                    <Col span={8} offset={1}> 
                                    <label className="block">Issue Name</label>
                                        <Input type="text" id="issueName" readOnly
                                            name="issueName" value={formik.values.issueName}
                                            className="form-background rounded-lg border border-gray-30 text-base"
                                        />
                                    </Col>
                                </Row>
                                <Row className="add-modal-row Assigned">
                                    <Col span={8}> 
                                     <label className="block">Logged By</label>
                                        <Input type="text" id="loggedBy" readOnly
                                            name="loggedBy" value={formik.values.loggedBy}
                                            className="w-full rounded-lg border form-background border-gray-30 text-base"
                                        />
                                    </Col>
                                    <Col span={8} offset={1}> 
                                     <label className="block">Email</label>
                                        <Input type="text" id="email" readOnly style={{ textTransform: "lowercase" }}
                                            name="email" value={formik.values.email}
                                            className="w-full rounded-lg border border-gray-30 text-base"
                                        />
                                    </Col>
                                </Row>
                                <Row className="add-modal-row Assigned">
                                    <Col span={8} >
                                        <label className="block">Phone</label>
                                        <Input type="phone" readOnly
                                            addonBefore="+91" id='phone' name='phone' value={formik.values.phone}
                                            className="w-full  text-base rounded-r-lg"
                                        />
                                    </Col>
                                    <Col span={8} offset={1}>
                                        <label className="block">Date</label>
                                        <Input type='dateTime' readOnly value={new Date(formik.values.date).toLocaleDateString('en-GB')} />
                                    </Col>
                                </Row>
                                <Row className="add-modal-row Assigned">
                                    <Col span={8}>
                                        <label>Category</label>
                                        <Input id="category" name="category" readOnly
                                            value={formik.values.category}
                                        />
                                    </Col>

                                    <Col span={7} offset={1}>
                                        <label>Assigned To</label>
                                        <Select
                                            style={{ width: 313, height: "48" }}
                                            defaultValue="Select"
                                            value={formik.values.assignedToUserName}
                                            className="com"
                                            name="assignedToUserName"
                                            onChange={handleSelectUser}
                                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        >
                                            {assignUserQuery?.data?.data?.data?.results?.map((assignTo) => (
                                                <Select.Option key={assignTo._id} value={assignTo._id}>
                                                    {`${assignTo.firstName} ${assignTo.lastName}`}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                        <ErrorMessage
                                            hasError={Boolean(formik.errors.assignedToUserName && formik.touched.assignedToUserName)}
                                            message={formik.errors.assignedToUserName}
                                        />
                                    </Col>
                                </Row>
                                <Row className="add-modal-row">
                                    <Col span={17}>
                                        <label className="block">Comments</label>
                                        <TextArea className="py-1 pl-4" onChange={formik.handleChange}
                                            rows={2} name="comment" value={formik.values.comment}
                                            placeholder="Write Report Description"
                                        />
                                        <ErrorMessage
                                            hasError={Boolean(formik.errors.comment && formik.touched.comment)}
                                            message={formik.errors.comment}
                                        />
                                    </Col>
                                </Row>
                                <Row className="add-user-modal-radio">
                                    <Col className="mt-9 mb-5">
                                        <label className='mt-5 pt-4 pl-4  opacity-60'>Ticket Status</label>
                                        <Radio.Group
                                            className="mt-1 ml-4 pb-1 opacity-100"
                                            // name='ticketStatus'
                                            value={formik.values.ticketStatus}
                                            onChange={(e) => formik.setFieldValue('ticketStatus', e.target.value)}
                                        >
                                            <Radio value="pending">In Progress</Radio>
                                            <Radio value="resolved">Resolved</Radio>
                                        </Radio.Group>
                                        <ErrorMessage
                                            hasError={Boolean(formik.errors.ticketStatus && formik.touched.ticketStatus)}
                                            message={formik.errors.ticketStatus}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </form>
                </Modal>
            )}
        </div>
    )
}

export default CustSupportForm