import React, { useState } from "react";
import refreshIcon from "../../../../assets/images/icons/refresh.png";
import uploadedIcon from "../../../../assets/svg/green_tick.svg";
import unUploadedIcon from "../../../../assets/svg/gray_tick.svg";

import { MdCheck, MdClose } from "react-icons/md";
import ConfirmationModal from "./ConfirmationModal";
import useAxiosInstance from "../../../../lib/useAxiosInstance";
import { toast } from "react-toastify";
import FileUpload from "./FormUpload";

const checkIconMapping = {
  Yes: <img src={uploadedIcon} alt='yes' className='h-5 w-5' />,
  YES: <img src={uploadedIcon} alt='yes' className='h-5 w-5' />,
  NO: <img src={unUploadedIcon} alt='yes' className='h-5 w-5' />
};
const ElectionDataTabs = ({
  polygonData,
  year,
  handleRefreshClick = () => {},
  setRefresh = () => {}
}) => {
  const [activeTab, setActiveTab] = useState("form20");
  const [form20Loading, setForm20Loading] = useState(false);
  const [electoralLoading, setElectoralLoading] = useState(false);
  const [villageLoading, setVillageLoading] = useState(false);
  const [psVillageLoading, setPsVillageLoading] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const summaryData = JSON.parse(localStorage.getItem("summaryUploadData"));

  const axiosInstance = useAxiosInstance();

  const uploadedData = JSON.parse(localStorage.getItem("summaryUploadData"));

  const deleteUploadedData = async (activeTab) => {
    let apiEndpoint = "";
    try {
      switch (activeTab) {
        case "form20":
          apiEndpoint = "deleteUploadedForm20DataByParlimentId";
          break;
        case "electoral-roll":
          apiEndpoint = "deleteUploadedElectoralRollsDataByParlimentId";
          break;
        case "village-data":
          apiEndpoint = "deleteUploadedVillageDataByParlimentId";
          break;
        case "village-to-PS":
          apiEndpoint = "deleteUploadedPSvillageMappingDataByParlimentId";
          break;
        default:
          console.log("No API call for selectedOption 4");
          return;
      }

      const response = await axiosInstance.delete(
        `api/parliment/${apiEndpoint}/${polygonData[0]?.constituencyId}?years=${year}`
      );
      const responseData = response?.data;
      toast.success("Data Deleted Successfully");

      return responseData;
    } catch (error) {
      console.error(`Error deleting data for ${apiEndpoint}:`, error);
      throw error;
    }
  };

  const handleDeleteForm20 = () => {
    deleteUploadedData(activeTab)
      .then((responseData) => {
        handleCancel();
        handleRefreshClick().then(() => {
          setRefresh((prev) => {
            return !prev;
          });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        handleCancel();
      });
  };

  const handleCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };
  const renderTabContent = () => {
    switch (activeTab) {
      case "form20":
        return (
          <FileUpload
            title='Form 20'
            key='Form 20'
            model='form20'
            uploadApiEndpoint='/api/form20/addCsvData'
            deleteModalHeading='Delete'
            deleteModalText='Are you sure you want to Delete Form 20?'
            // localStorageKey='form20-data'
            setLoading={setForm20Loading}
            polygonData={polygonData}
            loading={form20Loading}
            uploadedData={uploadedData?.Form20DataFileName}
            setIsDeleteModalVisible={setIsDeleteModalVisible}
            year={year}
            isDeleteModalVisible={isDeleteModalVisible}
            handleRefreshClick={handleRefreshClick}
            activeTab={activeTab}
            setRefresh={setRefresh}
          />
        );
      case "electoral-roll":
        return (
          <FileUpload
            title='Electoral Roll'
            key='Electoral Roll'
            model='electoralrolls'
            uploadApiEndpoint='/api/form20/uploadElectoralRollsData'
            deleteModalHeading='Delete'
            deleteModalText='Are you sure you want to Delete Electoral Roll?'
            // localStorageKey='electoral-data'
            setLoading={setElectoralLoading}
            loading={electoralLoading}
            polygonData={polygonData}
            uploadedData={uploadedData?.ElectoralRollDataFileName}
            setIsDeleteModalVisible={setIsDeleteModalVisible}
            year={year}
            isDeleteModalVisible={isDeleteModalVisible}
            handleRefreshClick={handleRefreshClick}
            activeTab={activeTab}
            setRefresh={setRefresh}
          />
        );
      case "village-data":
        return (
          <FileUpload
            title='Village Data'
            key='Village Data'
            model='villagedatas'
            uploadApiEndpoint='/api/form20/uploadVillageDataToDb'
            deleteModalHeading='Delete'
            deleteModalText='Are you sure you want to Delete Village Data?'
            // localStorageKey='village-data'
            setLoading={setVillageLoading}
            loading={villageLoading}
            polygonData={polygonData}
            uploadedData={uploadedData?.VillageDataFileName}
            setIsDeleteModalVisible={setIsDeleteModalVisible}
            year={year}
            isDeleteModalVisible={isDeleteModalVisible}
            handleRefreshClick={handleRefreshClick}
            activeTab={activeTab}
            setRefresh={setRefresh}
          />
        );
      case "village-to-PS":
        return (
          <FileUpload
            title='Village PS Mapping'
            key='Village PS Mapping'
            model='psvillagemappingdatas'
            uploadApiEndpoint='/api/form20/uploadPSVillageMappingData'
            deleteModalHeading='Delete'
            deleteModalText='Are you sure you want to Delete Village PS Mapping?'
            // localStorageKey='village-to-ps'
            setLoading={setPsVillageLoading}
            loading={psVillageLoading}
            polygonData={polygonData}
            uploadedData={uploadedData?.PSVillageMappingFileName}
            setIsDeleteModalVisible={setIsDeleteModalVisible}
            year={year}
            isDeleteModalVisible={isDeleteModalVisible}
            handleRefreshClick={handleRefreshClick}
            activeTab={activeTab}
            setRefresh={setRefresh}
          />
        );
      default:
        return (
          <FileUpload
            title='Form 20'
            key='Form 20'
            model='form20'
            uploadApiEndpoint='/api/form20/addCsvData'
            deleteModalHeading='Delete'
            deleteModalText='Are you sure you want to Delete Form 20?'
            // localStorageKey='form20-data'
            setLoading={setForm20Loading}
            polygonData={polygonData}
            loading={form20Loading}
            uploadedData={uploadedData?.Form20DataFileName}
            setIsDeleteModalVisible={setIsDeleteModalVisible}
            year={year}
            isDeleteModalVisible={isDeleteModalVisible}
            handleRefreshClick={handleRefreshClick}
            activeTab={activeTab}
            setRefresh={setRefresh}
          />
        );
    }
  };
  return (
    <>
      <div className='constituency-data mt-5 mx-6 p-0'>
        <h4>Election Data</h4>
        <div className='tabs1 election-data-tabs'>
          <div className='flex justify-between'>
            <div className='gms-table-tabs'>
              <button
                className={`tab-button tab-btn1 ${
                  activeTab === "form20" ? "active" : ""
                }`}
                onClick={() => handleTabChange("form20")}
              >
                <span className='flex justify-center gap-2'>
                  {" "}
                  Form 20{" "}
                  {checkIconMapping[summaryData["Form 20"]] ||
                    checkIconMapping["NO"]}
                </span>
              </button>
              <button
                className={`tab-button ${
                  activeTab === "electoral-roll" ? "active" : ""
                } ml-5`}
                onClick={() => handleTabChange("electoral-roll")}
              >
                <span className='flex justify-center gap-2 px-3'>
                  {" "}
                  Electoral Roll{" "}
                  {checkIconMapping[summaryData["Electoral Roll"]] ||
                    checkIconMapping["NO"]}
                </span>
              </button>
              <button
                className={`tab-button ${
                  activeTab === "village-data" ? "active" : ""
                } ml-5`}
                onClick={() => handleTabChange("village-data")}
              >
                <span className='flex justify-center gap-2 px-3'>
                  Village Data{" "}
                  {checkIconMapping[summaryData["Village Data"]] ||
                    checkIconMapping["NO"]}
                </span>
              </button>
              <button
                className={`tab-button ${
                  activeTab === "village-to-PS" ? "active" : ""
                } ml-5`}
                onClick={() => handleTabChange("village-to-PS")}
              >
                <span className='flex justify-center gap-2 px-3'>
                  Village To Polling Station{" "}
                  {checkIconMapping[summaryData["PS Village Mapping Data"]] ||
                    checkIconMapping["NO"]}
                </span>
              </button>
            </div>
            {/* <div className='flex'>
              <button
                className='mr-4 mt-1'
                onClick={() => {
                  setRefresh((prev) => {
                    return !prev;
                  });
                  // handleRefreshClick().then(() => {
                  //   window.location.reload();
                  // });
                }}
              >
                <img src={refreshIcon} alt='Refresh Icon' className='w-5 h-5' />
              </button>
            </div> */}
            {isDeleteModalVisible && (
              <ConfirmationModal
                modalHeading='Delete'
                modalText='Are you sure you want to Delete Form 20 Data?'
                isConfirmModal={isDeleteModalVisible}
                handleCancel={handleCancel}
                handleConfirmation={handleDeleteForm20}
                activeTab={activeTab}
              />
            )}
          </div>
        </div>
        <div className='tab-content'>{renderTabContent()} </div>
      </div>
    </>
  );
};

export default ElectionDataTabs;
