import React, { useContext, useState } from 'react'
import { Button, Pagination, Select, Space, Table, Skeleton, Empty } from "antd";
import { useQuery, useQueryClient } from "react-query";
import "../../../styles/Report.css";
import Column from "antd/es/table/Column";
import DeleteModal from "../../DeleteModal";
import { reportContext } from "../../../context/ReportContextProvider";
import { AppContext } from "../../../context/AppContextProvider";
import SearchFilter from "../../SearchFilter";
import shareIcon from "../../../assets/svg/share.svg"
import { toast } from "react-toastify";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import ReportContext from '../../../context/reportContext';
import ManageSubscribers from './ManageSubscribers';
import ShareReports from './ShareReports';
import DeactivateReport from './DeactivateReport';

const ReportDatabaseList = () => {
    const reportsContexts = useContext(ReportContext);

    const axiosInstance = useAxiosInstance();
    const queryClient = useQueryClient();

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    // eslint-disable-next-line
    const [sort, setSort] = useState("DES");
    const [searchKeyword, setSearchKeyword] = useState("");
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [recordToDelete, setRecordToDelete] = useState(null);

    const { handleClick, handleMouseEnter, handleMouseLeave, isDropdownOpen } = useContext(AppContext);
    const { showSubscribersModal } = useContext(reportContext);

    // GET ALL REPORTS DATA
    const fetchReports = async () => {
        const response = await axiosInstance.get(
            `api/reports?page=${currentPage}&limit=${limit}&sort=${sort}`,
        );
        queryClient.invalidateQueries('Subscribers-List');
        setTotal(response.data.totalpages)
        return response
    };
    const reportsData = useQuery(
      ["report-list", currentPage, limit, sort],
      () => fetchReports(currentPage, limit, sort),
      {
        refetchOnWindowFocus: false,
        retry: 1
      }
    );

    // GET ALL SEARCH REPORT DATA
    const fetchSearchUsers = async () => {
        let response;
        if (searchKeyword) {
          response = await axiosInstance.get(
            `/api/reports/searchAndFilter?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}`
          );
          setTotal(response?.data?.totalpages);
        } else {
          response = await axiosInstance.get(
            `api/reports?page=${currentPage}&limit=${limit}&sort=${sort}`
          );
          setTotal(response?.data?.totalpages);
        }
        return response;
    };

    const reportSearchData = useQuery(
      ["allSearchReports", searchKeyword, currentPage, limit],
      () => fetchSearchUsers(),
      {
        refetchOnWindowFocus: false,
        retry: 1
      }
    );
    // const reportDataSearch = reportSearchData?.data?.data?.data
    const SearchUser = (value) => {
        setCurrentPage(1);
        setSearchKeyword(value);
    };

    // FOR PAGINATION
    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setLimit(pageSize);
    };

    const pagination = {
        total: total * limit,
        pageSize: limit,
        current: currentPage,
        onChange: handlePageChange,
    };

    // SORTING
    const uploadDateSorter = (a, b) => {
        const aDate = new Date(a.createdAt).getTime();
        const bDate = new Date(b.createdAt).getTime();
        return aDate - bDate;
    };

    // DELETE REPORT
    const handleShowModalDelete = (record) => {
        setRecordToDelete(record);
        setIsDeleteModalVisible(true);
    };
    const handleDelete = async (record) => {
        try {
            const response = await axiosInstance.delete(`api/reports/${record._id}`);
            const message = response?.data?.message;
            toast.success(`${message}`);
            queryClient.invalidateQueries("report-list");
            queryClient.invalidateQueries("allSearchReports");
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Something went wrong.");
            }
        }
    };

    // REPORT SELECTION
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    // SHARE REPORT
    const [shareReport, setShareReport] = useState(false);
    const [selectedReportData, setSelectedReportData] = useState(null);

    const showSelectReportsError = () => {
        toast.error("Please select one or more reports to share.");
    };

    const openShareModal = () => {
        if (selectedRowKeys.length === 0) {
            showSelectReportsError();
        } else {
            const selectedReports = reportsData?.data?.data?.data?.filter((record) =>
                selectedRowKeys.includes(record._id)
            );

            const hasDeactivatedReports = selectedReports.some((report) => report.reportStatus === 'deactivated');

            if (hasDeactivatedReports) {
                toast.error("Inactive reports cannot be shared.");
            } else {
                setShareReport(!shareReport);
                setSelectedReportData(selectedReports);
            }
        }
    };

    const handleEdit = (record, type) => {
        reportsContexts.updateEditData(record);

        if (type === 'edit') {
            reportsContexts.updateAddMode(false);
            reportsContexts.updateOpenModal(true);
        } else if (type === 'status') {
            reportsContexts.updateStatusModal(true);
        }
    };
    const isLoading = reportsData?.isFetching || reportsData?.isLoading || reportSearchData?.isFetching || reportSearchData?.isLoading;
    return (
        <>
            <div className="search-share">
                <SearchFilter handleSearch={SearchUser} />
                <div className="share-modal-btn">
                    <Button key="share_reports_modal" onClick={() => openShareModal(selectedRowKeys)}>Share <img src={shareIcon} alt="" /></Button>
                </div>
            </div>
            <div className="reports-table">
                <Table rowSelection={rowSelection}
                    rowKey={(record) => record._id}
                    dataSource={reportSearchData?.data?.data?.data || reportsData?.data?.data?.data}
                    className="report-tableRows"
                    pagination={pagination}
                                locale={{
          emptyText: isLoading ? (
            <div
              style={{
                marginLeft: "20px",
                width: "95%"
              }}
            >
              {" "}
              {/* Container with 90% width */}
              <Skeleton
                title={false}
                active
                paragraph={{
                  rows: 6,
                  width: ["100%", "100%", "100%", "100%", "100%", "100%"]
                }}
              />
            </div>
          ) : (
            <Empty />
          )
        }}
                >
                    <Column title="REPORT NAME" dataIndex="reportName" className='w-80'
                        render={(text, record) => (
                            <>
                                {record.reportStatus === 'deactivated' && <span className="deactivate-report">inactive</span>}
                                <span title={text}>{text}</span>
                                {/* <Tooltip title={text.length > 100 ? text : null}>
                                    {text.length > 100 ? text.slice(0, 100) + "..." : text}
                                </Tooltip> */}
                            </>
                        )} />
                    <Column title="DESCRIPTION" dataIndex="reportDescription" className='report-name-tooltip' />
                    <Column title="UPLOADED BY" dataIndex="uploaderName" className='w-44'/>
                    <Column title="UPLOAD DATE" dataIndex="createdAt" className='w-36'
                        sorter={uploadDateSorter} // Enable sorting
                        defaultSortOrder="descend" // Set default sorting order
                        render={(text, record) => (
                            <span>
                                {new Date(record.createdAt).toLocaleDateString("en-GB")}
                            </span>
                        )}
                    />
                    <Column title="COUNT OF SUBSCRIBERS" dataIndex="subscribers"
                        render={(subscribers, record) => (
                            <span>
                                {Array.isArray(record?.subscribers)
                                    ? record.subscribers.length
                                    : 0}
                            </span>
                        )}
                    />
                    {!selectedRowKeys.length && (
                        <Column title="ACTION" key="action"
                            render={(text, record, index) => (
                                <Space className="select-option" size="small" style={{}}>
                                    <div
                                        className="dropdown"
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <span onClick={() => handleClick(index)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 34 30" fill="none">
                                                <circle cx="17.3889" cy="9.38845" r="1.38889" transform="rotate(-90 17.3889 9.38845)" fill="#004877" />
                                                <circle cx="17.3889" cy="15.4998" r="1.38889" transform="rotate(-90 17.3889 15.4998)" fill="#004877" />
                                                <circle cx="17.3889" cy="21.6111" r="1.38889" transform="rotate(-90 17.3889 21.6111)" fill="#004877" />
                                            </svg>
                                        </span>
                                        {isDropdownOpen(index) && (
                                            <div className="dropdown-content">
                                                <div key={`statusReport-${index}`} onClick={() => handleEdit(record, 'status')}>
                                                    {record.reportStatus === 'activated' ? 'Deactivate' : 'Activate'}
                                                </div>

                                                {record.reportStatus === 'activated' &&
                                                    <div key={`editReport-${index}`} onClick={() => handleEdit(record, 'edit')}>Edit</div>
                                                }

                                                <div key={`deleteReport-${index}`} onClick={() => handleShowModalDelete(record)}>Delete</div>

                                                {record.reportStatus === 'activated' &&
                                                    <div key={`manageReport-${index}`} onClick={() => showSubscribersModal(record)}>Manage Subscribers</div>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </Space>
                            )}
                        />
                    )}
                </Table>
                <div className="report-pagination">
                    <div>
                        <Select value={limit.toString()} style={{ width: 56 }}
                            onChange={(value) => {
                                setLimit(parseInt(value)); setCurrentPage(1);
                            }}
                            options={[
                                { value: "10", label: "10" },
                                { value: "20", label: "20" },
                                { value: "50", label: "50" },
                                { value: "100", label: "100" },
                            ]}
                        />
                        <span className="px-4">Entries Per Pages</span>
                    </div>
                    <div className='report-selection'>
                        <Pagination
                            current={currentPage}
                            pageSize={limit}
                            total={total * limit}
                            onChange={(page, pageSize) => {
                                setCurrentPage(page); setLimit(pageSize);
                            }}
                            className="pagination"
                        />
                    </div>

                </div>
            </div>
            {recordToDelete && (
                <DeleteModal
                    textheading="Delete Report"
                    deleteTitle="Report"
                    deleteBtn="Delete Report"
                    handleDelete={() => handleDelete(recordToDelete)}
                    isDeleteModal={isDeleteModalVisible}
                    showModalDelete={handleShowModalDelete}
                    action='delete'
                />
            )}
            {shareReport && (
                <ShareReports
                    shareReport={shareReport}
                    openShareModal={openShareModal}
                    selectedReportData={selectedReportData}
                    setSelectedReportData={setSelectedReportData}
                />
            )}
            <ManageSubscribers />

            {reportsContexts.isStatusModal && (
                <DeactivateReport isStatusModal={reportsContexts.isStatusModal} editData={reportsContexts.editData} />
            )}
        </>
    )
}

export default ReportDatabaseList