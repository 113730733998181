import React, { useContext } from "react";
// eslint-disable-next-line no-unused-vars
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import Overview from "../pages/overview";
// import Tabs from "../pages/clients/Tabs";
import Users from "../pages/users";
import Client from "../pages/client";
import ClientInfo from "../pages/client/ClientInfo";
import Survey from "../pages/survey";
import Billing from "../pages/billing";
import MoreInfo from "../components/Billing/BillingClientInfo/MoreInfo";
import CustomerSupport from "../pages/customerSupprt";
import Sidebar from "../components/Sidebar";

import Navbar from "../components/Navbar";
import Notifications from "../pages/notifications/Notifications";
import TMS from "../pages/tms/index";
import { AppContext } from "../context/AppContextProvider";
import ConstituencyManagement from "../pages/ConstituencyManagement";
// import Survey from '../pages/survey';
import CommunityDictionary from "../pages/CommunityDictionary";
import PartyManagement from "../pages/PartyManagement";
import HelpCenter from "../pages/helpCenter";
// import AppContextProvider from "../context/AppContextProvider";
// import ClientContextProvider from '../context/ClientContextProvider';
import HelpcenterInnerpage from "../pages/helpCenter/helpcenter-innerpage";
import Reports from "../pages/reports";
import GMS from "../pages/gms";
import Settings from "../pages/settings";

// For Auth
import SignIn from "../pages/auth/SignIn";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import ParliamentaryViewDetails from "../components/ConstituencyManagement/Parliamentary";
import AssemblyViewDetails from "../components/ConstituencyManagement/Assembly";
// import UnauthorizedPage from "../utils/unAuthorized";

const RouteApps = () => {
  const { isLoggedIn } = useContext(AppContext);
  const userString = localStorage.getItem("admin-user");
  const user = JSON.parse(userString);
  const role = user?.role;

  return (
    <>
      {isLoggedIn ? (
        <>
          <Navbar />
          <div className='grid-container'>
            <div className='grid-child'>
              <Sidebar role={role} />
            </div>

            <div className='grid-child'>
              <Routes>
                <Route
                  path='*'
                  element={<Navigate to='/dashboard' replace />}
                />
                <Route path='/dashboard' element={<Overview />} />
                {/* <Route path='/clients' element={<Clients />} />
                <Route path='/client-info/:clientId' element={<Tabs />} /> */}
                <Route path='/client'>
                  <Route index element={<Navigate to='client' replace />} />
                  <Route path=':source' element={<Client />} />
                </Route>
                <Route
                  path='/clients-info/:clientId'
                  element={<ClientInfo />}
                />
                <Route path='/tms' element={<TMS />} />

                {/* <Route path='/tms' element={<Tms />}/> */}
                {/* <Route path='/constituency-management' element={<ConstituencyManagement />} /> */}

                <Route path='/constituency-management'>
                  <Route index element={<Navigate to='assembly' replace />} />
                  <Route path='assembly' element={<ConstituencyManagement />} />
                  <Route
                    path='parliamentary'
                    element={<ConstituencyManagement />}
                  />
                  <Route
                    path='parliamentary/view-constituency/:selectedYear'
                    element={<ParliamentaryViewDetails />}
                  />
                  <Route
                    path='assembly/view-constituency/:selectedYear'
                    element={<AssemblyViewDetails />}
                  />
                </Route>

                <Route path='/reports'>
                  <Route
                    index
                    element={<Navigate to='report-database' replace />}
                  />
                  <Route path=':source' element={<Reports />} />
                </Route>
                <Route path='/survey'>
                  <Route
                    index
                    element={<Navigate to='survey-database' replace />}
                  />
                  <Route path=':source' element={<Survey />} />
                </Route>
                <Route
                  path='/community-dictionary'
                  element={<CommunityDictionary />}
                />
                <Route path='/party-management' element={<PartyManagement />} />
                <Route path='/users' element={<Users />} />

                <Route path='/billing'>
                  <Route index element={<Navigate to='overview' replace />} />
                  <Route path=':source' element={<Billing />} />
                </Route>
                <Route
                  path='/billing/more-info/:clientId'
                  element={<MoreInfo />}
                />

                <Route path='/customer-support' element={<CustomerSupport />} />
                <Route path='/notifications' element={<Notifications />} />
                <Route path='/help-center' element={<HelpCenter />} />
                <Route
                  path='/helpcenter-innerpage'
                  element={<HelpcenterInnerpage />}
                />
                <Route path='/settings'>
                  <Route index element={<Navigate to='general' replace />} />
                  <Route path=':source' element={<Settings />} />
                </Route>

                <Route path='/gms'>
                  <Route index element={<Navigate to='gms' replace />} />
                  <Route path=':source' element={<GMS />} />
                </Route>
              </Routes>
            </div>
          </div>
        </>
      ) : (
        <Routes>
          <Route path='/' element={<SignIn />} />
          <Route path='*' element={<Navigate to='/' replace />} />
          <Route path='/login/forgot-password' element={<ForgotPassword />} />
          <Route path='/api/users/auth/passwordReset' element={<ResetPassword />} />    
        </Routes>
      )}
    </>
  );
};

export default RouteApps;
