import React, { useRef, useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Polygon,
  InfoWindow,
  Marker
} from "@react-google-maps/api";
import { mapStyles } from "../../../../utils/mapStyles";
import sampleData from "../ConstituencyInfo/sampleData.json";
import { Spin } from "antd";

const lib = ["places"];

const MapBox = (props) => {
  const { center, polygonData } = props;

  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [polygonPaths, setPolygonPaths] = useState([]);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [markers, setMarkers] = useState([]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: lib
  });

  const mapRef = useRef(null);

  const handleMarkerClick = (position) => {
    props.setSelectedMarker(position);
    setShowInfoWindow(true);

    if (mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(position);

      mapRef.current.fitBounds(bounds);

      const zoomLevel = 13;
      mapRef.current.setZoom(zoomLevel);
    }
  };

  useEffect(() => {
    if (mapRef.current && typeof mapRef.current.fitBounds === "function") {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(props.selectedMarker);

      mapRef.current.fitBounds(bounds);

      const zoomLevel = 13;
      mapRef.current.setZoom(zoomLevel);
    }
  }, [props.selectedMarker]);

  const handleMarkerOver = (position) => {
    props.setSelectedMarker(position);
    setShowInfoWindow(true);
  };

  const renderMarkers = () => {
    return (
      isLoaded &&
      isMapLoaded &&
      center.map((village, index) => (
        <Marker
          icon={{
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            fillColor: village?.winnerParty?.colorCode || "#000000",
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 3
          }}
          key={index}
          title={village?.title || "No Title"}
          position={{
            lat: village.lat,
            lng: village.lng
          }}
          onClick={() => handleMarkerClick(village)}
          // onMouseOver={() => handleMarkerOver(village)}
        />
      ))
    );
  };

  const onLoad = (map) => {
    mapRef.current = map;
    setIsMapLoaded(true);
    setMarkers(renderMarkers());
  };

  useEffect(() => {
    if (isLoaded && isMapLoaded) {
      setPolygonPaths(polygonData);
    }
  }, [isLoaded, isMapLoaded]);

  useEffect(() => {
    if (isLoaded && center.length > 0) {
      onLoad(mapRef.current);
      setShowInfoWindow(!!props.selectedMarker);
    }
  }, [isLoaded, JSON.stringify(center), props.selectedMarker]);

  useEffect(() => {
    if (
      polygonPaths &&
      Array.isArray(polygonPaths) &&
      polygonPaths.length > 0
    ) {
      const bounds = new window.google.maps.LatLngBounds();
      polygonPaths.forEach((polygon) => {
        polygon?.coordinate.forEach((item) => {
          bounds.extend(new window.google.maps.LatLng(item?.lat, item?.lng));
        });
      });
      const polygonCenter = bounds.getCenter();
      mapRef.current.fitBounds(bounds);
      mapRef.current.setCenter(polygonCenter);
    }
  }, [polygonPaths]);

  if (loadError) {
    return <div className='loader'>Error loading the map</div>;
  }
  if (isLoaded) {
    return (
      <div className='map-container'>
        <GoogleMap
          google={props.google}
          mapContainerClassName='map-container'
          options={{
            styles: mapStyles,
            disableDefaultUI: true
          }}
          onLoad={onLoad}
          ref={mapRef}
        >
          {(polygonPaths || []).map((polygon, index) => {
            const bounds = new window.google.maps.LatLngBounds();
            polygon?.coordinate.forEach((point) => {
              if (
                typeof point?.lat === "number" &&
                typeof point?.lng === "number"
              ) {
                bounds.extend(point);
              } else {
                console.error("Invalid coordinate:", point?.lat);
              }
            });
            const center = bounds.getCenter();

            return (
              <React.Fragment key={index}>
                <Polygon
                  paths={polygon?.coordinate}
                  options={{
                    // strokeColor: "black",
                    // strokeOpacity: 0.4,
                    // strokeWeight: 1,
                    // fillColor: "#663399",
                    // fillOpacity: 0.5
                    strokeColor: "#810FCB",
                    strokeOpacity: 1.0,
                    strokeWeight: 3.0,
                    fillColor: "#810FCB",
                    fillOpacity: 0.5
                  }}
                  // onMouseOver={() => handlePolygonClick(index)}
                />
              </React.Fragment>
            );
          })}

          {/* props.isDataAvailable && props.isDataAvailable && */}
          {markers}
          {showInfoWindow && (
            <InfoWindow
              position={{
                lat: props?.selectedMarker?.lat || 0,
                lng: props?.selectedMarker?.lng || 0
              }}
              onCloseClick={() => {
                mapRef.current.setZoom(10);
                setShowInfoWindow(false);
              }}
              options={{ maxWidth: 200 }}
            >
              <div
                style={{
                  padding: "10px",
                  textAlign: "center"
                }}
              >
                <div className='font-bold text-lg village-name'>
                  {props?.selectedMarker?.title || "Unknown Village"}
                </div>
                <h3 className='mt-3'>
                  Winner Party:{" "}
                  <span
                    style={{
                      color:
                        props?.selectedMarker?.winnerParty?.colorCode ||
                        "black",
                      fontWeight: "800"
                    }}
                  >
                    {props?.selectedMarker?.winnerParty?.name ||
                      "Unknown Village"}
                  </span>
                </h3>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    );
  } else {
    return (
      <div className='loader flex flex-col'>
        <Spin>
          <div className='mt-12'>Loading ...</div>
        </Spin>
      </div>
    );
  }
};

export default MapBox;
