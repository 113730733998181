import React from "react";
import { ResponsiveLine } from "@nivo/line";
import NoDataIcon from "../../../assets/svg/no-data.svg";
const data1 = [
  {
    id: "User Income",
    color: "#E67E22",
    border: "1px solid #E67E22",
    data: [
      { x: 0, y: 0 },
      { x: "JAN", y: 200000 },
      { x: "FEB", y: 400000 },
      { x: "Mar", y: 600000 },
      { x: "ARL", y: 400000 },
      { x: "MAY", y: 600000 },
      { x: "JUN", y: 800000 }
    ]
  }
];

const TotalRevenue = ({ totalRevenue = [] }) => {
  const data = [
    {
      id: "User Income",
      color: "#E67E22",
      border: "1px solid #E67E22",
      data: totalRevenue.map((item, index) => ({
        x: new Date(item.date)
          .toLocaleString("default", { month: "short" })
          .toUpperCase(),
        y: item.count
      }))
    }
  ];
  const formatYAxis = (value) => {
    if (value === null) return "";
    if (value >= 1000 && value <= 100000) return `${value / 1000}K`;
    if (value >= 100000) return `${value / 100000}L`;

    return value;
  };
  const customYAxisTicks = [200000, 400000, 600000, 800000, 1000000, 1200000];

  const lineColors = ["#3BBEE8", "#004877"]; // Define the line colors

  const theme = {
    axis: {
      ticks: {
        text: {
          fontSize: 15,
          fill: "var(--stat-text)",
          fontWeight: 400
        }
      },
      legend: {
        text: {
          fontSize: 15,
          fill: "var(--stat-text)",
          fontWeight: 400
        }
      }
    }
  };
  return (
    <div className='billing-revenue-graph'>
      <div className='overview-user-section overview-section'>
        <div className='overview-heading'>
          <h4 className='overview-text'>Income Generated Per Month</h4>
        </div>
        <div
          className='active-user-map flex justify-center'
          style={{ height: "350px", padding: "0 0 20px 25px" }}
        >
          {!totalRevenue.length ? (
            <div className='flex flex-col justify-center items-center no-data-icon'>
              <img src={NoDataIcon} alt='No data' />
              <span>No data</span>
            </div>
          ) : (
            <ResponsiveLine
              data={data}
              margin={{ top: 30, right: 60, bottom: 60, left: 60 }}
              axisBottom={{
                orient: "bottom",
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0,
                legend: "Months",
                legendOffset: 50,
                legendPosition: "middle"
              }}
              axisLeft={{
                tickValues: customYAxisTicks, // Set custom tick values
                tickSize: 0,
                tickPadding: 8,
                // tickCount: 3,
                tickRotation: 0,
                legend: "Amount (₹)",
                legendOffset: -55,
                legendPosition: "middle",
                format: formatYAxis
              }}
              theme={theme}
              colors={lineColors}
              lineWidth={6}
              enablePoints={false}
              pointSize={2}
              pointBorderWidth={1}
              pointLabelYOffset={-12}
              useMesh={true}
              isInteractive={true}
              tooltip={({ point }) => (
                <div
                  style={{
                    background: "white",
                    padding: "5px 10px",
                    border: "1px solid #ccc",
                    borderRadius: "5px"
                  }}
                >
                  <div>X: {point.data.xFormatted}</div>
                  <div>Y: {point.data.yFormatted}</div>
                </div>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TotalRevenue;
