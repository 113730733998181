const formatGenderValues = (genderData) => {
  const totalPeople = genderData.reduce(
    (sum, { noOfPeople }) => sum + noOfPeople,
    0
  );

  return genderData.map(({ name, noOfPeople }) => {
    let genderLabel;
    if (name === "Unknown") {
      genderLabel = "Missing";
    } else {
      genderLabel = name;
    }

    return {
      gender: genderLabel,
      "No of People": parseFloat(((noOfPeople / totalPeople) * 100).toFixed(2))
    };
  });
};

export default formatGenderValues;
