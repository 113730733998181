import React, { useContext, useState, useRef, useEffect } from "react";
import Column from "antd/es/table/Column";
import { AppContext } from "../../context/AppContextProvider";
import SearchFilter from "../SearchFilter";
import Edit from "./Edit";
import AddNewParty from "./AddNewParty";
import { useQuery, useQueryClient } from "react-query";
import DeleteModal from "../DeleteModal";
import useAxiosInstance from "../../lib/useAxiosInstance";

import { Pagination, Select, Space, Table } from "antd";
import { toast, ToastContainer } from "react-toastify";

const PartyDataTable = () => {
  const { handleClick, handleMouseEnter, handleMouseLeave, isDropdownOpen } =
    useContext(AppContext);
  const queryClient = useQueryClient();
  const axiosInstance = useAxiosInstance();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");

  const [isModalUpload, setIsModalUpload] = useState(false);

  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);

  // For DELETE USER
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const showModalDelete = (record) => {
    setSelectedRecord(record);
    setDeleteModal(!isDeleteModal);
  };

  const fetchParty = async (currentPage, limit, search) => {
    const response = await axiosInstance.get(
      `api/politicalParty?page=${currentPage}&limit=${limit}&keyword=${search}&sort=DES`
    );

    setTotal(response?.data?.message?.partyCount);
    return response;
  };

  const reportsData = useQuery(
    ["party-list", currentPage, limit, search],
    () => fetchParty(currentPage, limit, search),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );
  const partyData = reportsData?.data?.data?.message?.data || [];

  const DeleteParty = () => {
    axiosInstance
      .delete(`api/politicalParty/${selectedRecord?._id}`)
      .then((response) => {
        toast.success("Party Deleted successfully");
        queryClient.invalidateQueries("party-list", { refetchActive: true });
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error("Failed to delete party:", error);
        toast.error("Failed to delete party");
      });
  };

  // edit modal
  const showEditModal = (record) => {
    setSelectedRecord(record);
    setIsModal(!isModal);
  };

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  // bulk upload

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalUpload(false);
    setIsModal(false);
  };

  // dropdown1

  const dropdownRef = useRef(null);
  const toggleDropdown1 = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
  };

  const closeDropdown = () => {
    setIsDropdownOpen1(false);
  };
  useEffect(() => {
    const closeDropdownOnClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("click", closeDropdownOnClickOutside);
    return () => {
      document.removeEventListener("click", closeDropdownOnClickOutside);
    };
  }, []);
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const pagination = {
    total: total * limit,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange
  };

  const handleSearch = (keyword) => {
    setCurrentPage(1);
    setSearch(keyword);
  };
  return (
    <>
      <div className='community-page'>
        <ToastContainer />
        <div className='community-header flex justify-between'>
          <h1 className='heading-text'>Party Management</h1>
          <div>
            <div className='dropdown1'>
              <button
                className='add-dict-btn'
                onClick={() => {
                  showModal();
                }}
                onBlur={closeDropdown}
                key='#123'
                // ref={dropdownRef}
              >
                + Add New Party
              </button>
            </div>
          </div>
        </div>
        <div className='table-list'>
          <SearchFilter handleSearch={handleSearch} />
          <div className='reports-table'>
            <Table
              rowKey={(record) => record._id}
              dataSource={partyData}
              className='report-tableRows'
              pagination={pagination}
            >
              <Column
                title='Party Name'
                dataIndex='partyName'
                // render={(text, record) => (
                //   <>

                //   </>
                // )}
              />
              <Column title='Party Code' dataIndex='partyCode' />
              <Column
                title='Party Color Code'
                dataIndex='partyColorCode'
                render={(colorCode) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        backgroundColor: colorCode,
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        border: "1px solid #000",
                        marginRight: "8px"
                      }}
                    ></div>
                    <span>{colorCode}</span>
                  </div>
                )}
              />

              <Column
                title='ACTION'
                key='action'
                render={(text, record, index) => (
                  <Space className='select-option' size='small' style={{}}>
                    <div
                      className='dropdown'
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <span onClick={() => handleClick(index)}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='34'
                          height='30'
                          viewBox='0 0 34 30'
                          fill='none'
                        >
                          <circle
                            cx='17.3889'
                            cy='9.38845'
                            r='1.38889'
                            transform='rotate(-90 17.3889 9.38845)'
                            fill='#004877'
                          />
                          <circle
                            cx='17.3889'
                            cy='15.4998'
                            r='1.38889'
                            transform='rotate(-90 17.3889 15.4998)'
                            fill='#004877'
                          />
                          <circle
                            cx='17.3889'
                            cy='21.6111'
                            r='1.38889'
                            transform='rotate(-90 17.3889 21.6111)'
                            fill='#004877'
                          />
                        </svg>
                      </span>
                      {isDropdownOpen(index) && (
                        <div className='dropdown-content'>
                          <div
                            key={`edit-${index}`}
                            onClick={() => {
                              //   handleEditSubmit(record._id);
                              showEditModal(record);
                            }}
                          >
                            Edit
                          </div>
                          <div
                            key={`delete-${index}`}
                            onClick={() => showModalDelete(record)}
                          >
                            Delete
                          </div>
                        </div>
                      )}
                    </div>
                  </Space>
                )}
              />
            </Table>
            <div className='report-pagination'>
              <div>
                {" "}
                <span className='mr-2'>Showing</span>
                <Select
                  value={limit.toString()}
                  defaultValue={5}
                  style={{ width: 56 }}
                  onChange={(value) => {
                    setLimit(parseInt(value));
                    setCurrentPage(1);
                  }}
                  options={[
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "50", label: "50" },
                    { value: "100", label: "100" }
                  ]}
                />{" "}
                <span className='ml-2'>Of 50</span>
              </div>
              <div className='report-selection'>
                <Pagination
                  current={currentPage}
                  pageSize={limit}
                  total={total}
                  onChange={(page, pageSize) => {
                    setCurrentPage(page);
                    setLimit(pageSize);
                  }}
                  className='pagination'
                />
              </div>
            </div>
          </div>
        </div>

        {isModal && (
          <Edit
            isModal={isModal}
            handleCancel={handleCancel}
            selectedRecord={selectedRecord}
            setIsModal={setIsModal}
          />
        )}
        {isModalOpen && (
          <AddNewParty isModalOpen={isModalOpen} handleCancel={handleCancel} />
        )}
        <DeleteModal
          textheading='Delete Party'
          deleteBtn='Delete Party'
          handleDelete={() => DeleteParty(selectedRecord)}
          isDeleteModal={isDeleteModal}
          showModalDelete={() => setDeleteModal(false)}
          action='delete'
        />
      </div>
    </>
  );
};

export default PartyDataTable;
