import React from 'react'
import '../../styles/Report.css';
import { ToastContainer } from "react-toastify";
import {  MdUpload } from 'react-icons/md';
import ReportContext from '../../context/reportContext';
import ReportUploadForm from './ReportDatabase/ReportUploadForm';
import ReportTabs from './ReportTabs';
import PageHeading from '../PageHeading';
import { useContext } from 'react';

const Result = () => {
    const reportContext = useContext(ReportContext);

    const handleModal = () => {
        reportContext.updateOpenModal(true);
    };
    return (
        <div className="gms-page-section report-tabs">
            <ToastContainer />

            <div className="gms-wrapper">
                <PageHeading
                    pageTitle='Reports'
                    icon={<MdUpload />}
                    pageModalTitle='Upload New Report'
                    pageModalOpen={handleModal}
                />

                <ReportUploadForm isOpen={reportContext.isOpenModal} isAddMode={reportContext.isAddMode} />

                <ReportTabs />
            </div>
        </div>
    )
}

export default Result