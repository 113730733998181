import React from "react";

import { Button, Modal, Col, Row, Input, Form, Select } from "antd";

const { TextArea } = Input;

const Edit = ({ isModal, handleCancel, handleOk }) => {
  // const [value, setValue] = useState(1);
  // const onChange = (e) => {
  //   setValue(e.target.value);
  // };

  return (
    <>
      <Modal
        className='custom-support-modal'
        open={isModal}
        width={1040}
        height={824}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <Button
            type='button'
            className='gms-modal-button bg-sky-900 mr-10'
            onClick={handleOk}
          >
            Save Changes
          </Button>
        ]}
      >
        <Form className='cust-form'>
          <div className='modal-title'>
            <h3>Edit Individual Dictionary</h3>
          </div>
          <div className='modal-wrapper-body'>
            <div className='modal-wrapper-content mt-6 ml-4 gap-8'>
              <div className='grid grid-cols-1 pb-4'>
                <h4 className='text-heading'>Dictionary Information</h4>
              </div>
              <Row className='add-modal-row Assigned'>
                <Col span={8}>
                  <label>Community</label>
                  <Select
                    defaultValue='Select Community'
                    // onChange={handleChange}
                    className='Assigned'
                    style={{ width: "100%" }}
                    options={[
                      {
                        value: "Hindu",
                        label: "Hindu"
                      },
                      {
                        value: "Muslim",
                        label: "Muslim"
                      },
                      {
                        value: "CHRISTIAN",
                        label: "CHRISTIAN"
                      }
                    ]}
                  />
                </Col>

                <Col span={8} offset={1}>
                  <label className='block'>Sub Community</label>
                  <Input
                    type='text'
                    placeholder='Sub Community'
                    id='name'
                    name='name'
                    className='form-background'
                  />
                </Col>
              </Row>
              <Row className='add-modal-row Assigned'>
                <Col span={8}>
                  <label className='block pb-2'>Group</label>
                  <Input
                    type='text'
                    placeholder='SINGHAL'
                    id='name'
                    name='name'
                    className=''
                  />
                </Col>
              </Row>
              <Row className='add-modal-row'>
                <Col span={17}>
                  <label className='block pb-2'>Titles</label>
                  <TextArea className='pt-4 pl-4 pb-4' rows={2} />
                </Col>
              </Row>
              <Row className='add-modal-row Assigned'>
                <Col span={8}>
                  <label>State</label>
                  <Select
                    defaultValue='Select State'
                    className='com'
                    // onChange={handleChange}
                    options={[
                      {
                        value: "Maharastra",
                        label: "Maharastra"
                      },
                      {
                        value: "Goa",
                        label: "Goa"
                      }
                    ]}
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col span={8} offset={1}>
                  <label>Category</label>
                  <Select
                    defaultValue='Select Category'
                    style={{ width: "100%" }}
                    // onChange={handleChange}
                    className='Assigned'
                    options={[
                      {
                        value: "Category1",
                        label: "Category1"
                      },
                      {
                        value: "Category2",
                        label: "Category2"
                      }
                    ]}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default Edit;
