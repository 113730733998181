import React, { useState } from 'react'
import useAxiosInstance from '../../../lib/useAxiosInstance';
import { useQuery } from 'react-query';
import SearchFilter from '../../SearchFilter';
import { Pagination, Select, Table, Skeleton, Empty } from 'antd';
import callBackImg from "../../../assets/svg/callBack.svg";
import Column from 'antd/es/table/Column';

const DeclinedRequestList = () => {
    const axiosInstance = useAxiosInstance();
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    // eslint-disable-next-line
    const [sort, setSort] = useState("DES");
    const [searchKeyword, setSearchKeyword] = useState("");

    // GET ALL COMPLETED REPORT REQUEST DATA
    const fetchReportCompleted = async () => {
        const response = await axiosInstance.get(
            `api/reports/getAllDeclinedRequestedReportFromClient?page=${currentPage}&limit=${limit}&sort=${sort}`,
        );
        setTotal(response.data.totalpages)
        return response
    };
    const reportsDeclinedData = useQuery(
        ["declined-report-request", currentPage, limit, sort],
        () => fetchReportCompleted(currentPage, limit, sort),
        {
            refetchOnWindowFocus: false,
            retry: 1
        }
    );

    // GET ALL SEARCH COMPLETED REPORT REQUEST DATA
    const fetchSearchRequestedReports = async () => {
        let response;
        if (searchKeyword) {
            response = await axiosInstance.get(
                `api/reports/searchDeclinedReport/requested?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}&status=decline`
            );
            setTotal(response?.data?.totalpages);
        }
        else {
            response = await axiosInstance.get(
                `api/reports/getAllDeclinedRequestedReportFromClient?page=${currentPage}&limit=${limit}&sort=${sort}`
            );
            setTotal(response?.data?.totalpages);
        }
        return response;
    };

    const declinedSearchData = useQuery(
        ["search-completed", searchKeyword, currentPage, limit],
        () => fetchSearchRequestedReports(),
        {
            refetchOnWindowFocus: false,
            retry: 1
        }
    );


    const SearchCompletedRequest = (value) => {
        setCurrentPage(1);
        setSearchKeyword(value);
    };

    // FOR PAGINATION
    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setLimit(pageSize);
    };

    const pagination = {
        total: total * limit,
        pageSize: limit,
        current: currentPage,
        onChange: handlePageChange,
    };
    const isLoading = reportsDeclinedData.isLoading || declinedSearchData.isLoading
    return (
        <>
            <div className='report-page-section report-requested'>
                <div className='report-table-wrapper'>
                    <div className="reports-table">
                        <SearchFilter
                            handleSearch={SearchCompletedRequest}
                        />

                        <Table
                            rowKey={(record) => record._id}
                            dataSource={declinedSearchData?.data?.data?.data || reportsDeclinedData?.data?.data?.data}
                            className="report-tableRows"
                            pagination={pagination}
                            locale={{
                                emptyText: isLoading ? (
                                    <div
                                        style={{
                                            marginLeft: "20px",
                                            width: "95%"
                                        }}
                                    >
                                        {" "}
                                        {/* Container with 90% width */}
                                        <Skeleton
                                            title={false}
                                            active
                                            paragraph={{
                                                rows: 6,
                                                width: ["100%", "100%", "100%", "100%", "100%", "100%"]
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <Empty />
                                )
                            }}
                        >
                            <Column
                                title=''
                                dataIndex='callBack'
                                className='w-16'
                                render={(title, record) => (
                                    <>
                                        <div className='flex pl-3'>
                                            {record.quickCall === "true" && (
                                                <img src={callBackImg} alt='pin icon' className='mr-4' />
                                            )}
                                        </div>
                                    </>
                                )}
                            />
                            <Column title="CLIENT NAME" dataIndex="clientName" className='' />
                            <Column title="REPORT NAME" dataIndex="reportName" />
                            <Column title="DESCRIPTION" dataIndex="description" className='report-name-tooltip' />
                            <Column title="DECLIEND BY" dataIndex="declinedByName" className='' />
                            <Column title="REQ. DATE" dataIndex="createdAt"
                                sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
                                defaultSortOrder="descend"
                                render={(text, record) => (
                                    <span>
                                        {new Date(record.createdAt).toLocaleDateString("en-GB")}
                                    </span>
                                )}
                            />
                            <Column title="DECLINED DATE" dataIndex="declinedDate"
                                sorter={(a, b) => new Date(a.declinedDate) - new Date(b.declinedDate)}
                                defaultSortOrder="descend"
                                render={(text, record) => (
                                    <span>
                                        {new Date(record.declinedDate).toLocaleDateString("en-GB")}
                                    </span>
                                )}
                            />
                            {/* <Column title="ACTION" key="actions" className='w-44'
                                render={(text, record, index) => (
                                    <Space className="select-option" size="small" style={{}}>
                                        <div
                                            className="dropdown"
                                            onMouseEnter={() => handleMouseEnter(index)}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <span onClick={() => handleClick(index)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 34 30" fill="none">
                                                    <circle cx="17.3889" cy="9.38845" r="1.38889" transform="rotate(-90 17.3889 9.38845)" fill="#004877" />
                                                    <circle cx="17.3889" cy="15.4998" r="1.38889" transform="rotate(-90 17.3889 15.4998)" fill="#004877" />
                                                    <circle cx="17.3889" cy="21.6111" r="1.38889" transform="rotate(-90 17.3889 21.6111)" fill="#004877" />
                                                </svg>
                                            </span>
                                            {isDropdownOpen(index) && (
                                                <div className="dropdown-content">
                                                    <div key={`statusRequestReport-${index}`} onClick={() => openUploadModal(record)}>
                                                        Upload Report
                                                    </div>
                                                    <div key={`editRequestReport-${index}`} onClick={() => handleEdit(record)}>
                                                        Edit Report
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Space>
                                )}
                            /> */}


                        </Table>



                        <div className="report-pagination">
                            <div>
                                <Select
                                    value={limit.toString()}
                                    style={{ width: 56 }}
                                    onChange={(value) => {
                                        setLimit(parseInt(value)); setCurrentPage(1);
                                    }}
                                    options={[
                                        { value: "10", label: "10" },
                                        { value: "20", label: "20" },
                                        { value: "50", label: "50" },
                                        { value: "100", label: "100" },


                                    ]}
                                />
                                <span className="px-4">Entries Per Pages</span>
                            </div>
                            <div className='report-selection'>

                                <Pagination
                                    current={currentPage}
                                    pageSize={limit}
                                    total={total * limit}
                                    onChange={(page, pageSize) => {
                                        setCurrentPage(page);
                                        setLimit(pageSize);
                                    }}
                                    className="pagination"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeclinedRequestList