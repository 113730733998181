import React from 'react'
import '../../styles/helpcenter.css'
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { MdKeyboardArrowRight } from 'react-icons/md';

const { Panel } = Collapse;
const text = `Massa enim nec dui nunc mattis enim ut tellus elementum. Maecenas sed enim ut sem viverra. Pretium fusce id velit ut. At augue eget arcu dictum varius duis. Sed libero enim sed 
faucibus. Cursus metus aliquam eleifend mi in nulla posuere sollicitudin. Vestibulum lorem sed risus ultricies tristique nulla. Duis convallis convallis tellus id interdum. Volutpat ac tincidunt 
vitae semper quis lectus nulla at. Sed risus pretium quam vulputate dignissim suspendisse in. Nunc mi ipsum faucibus vitae aliquet. Interdum varius sit amet mattis. Et molestie ac feugiat 
sed lectus vestibulum.`;

const HelpcenterInnerpage = () => {
    // const { token } = theme.useToken();
    const panelStyle = {
        marginTop: 16,
        background: '#FFFFFF',
        width: '1318    px',
        borderRadius: '10px',
        border: '1px solid rgba(0, 0, 0, 0.15)',
    };

    return (
        <div className='helpcenter-page'>
            <div className='get-started'>
                <h3 className=''>Help Center <MdKeyboardArrowRight style={{marginTop:"2px",display:"inline-block"}}/><span>Getting Started</span> </h3>
            </div>
            <div className='helpcenter-wrapper'>
                <h4>Getting Started</h4>

                <Collapse
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => <CaretRightOutlined style={{ transform: isActive ? 'rotate(90deg)' : 'rotate(-90deg)', float: 'right' }} />}
                    style={{
                        padding: '16px',
                        margin: 'auto',
                    }}

                >
                    <Panel className='text-lg' header="Lorem ipsum dolor sit amet, consectetur adipiscing elit?" key="1" style={panelStyle}>
                        <p className='text-base'>{text}</p>
                    </Panel>
                    <Panel className='text-lg' header="Lorem ipsum dolor sit amet, consectetur adipiscing elit?" key="2" style={panelStyle}>
                        <p className='text-base'>{text}</p>
                    </Panel>
                    <Panel className='text-lg' header="Lorem ipsum dolor sit amet, consectetur adipiscing elit?" key="3" style={panelStyle}>
                        <p className='text-base'>{text}</p>
                    </Panel>
                    <Panel className='text-lg' header="Lorem ipsum dolor sit amet, consectetur adipiscing elit?" key="4" style={panelStyle}>
                        <p className='text-base'>{text}</p>
                    </Panel>
                    <Panel className='text-lg' header="Lorem ipsum dolor sit amet, consectetur adipiscing elit?" key="5" style={panelStyle}>
                        <p className='text-base'>{text}</p>
                    </Panel>
                    <Panel className='text-lg' header="Lorem ipsum dolor sit amet, consectetur adipiscing elit?" key="6" style={panelStyle}>
                        <p className='text-base'>{text}</p>
                    </Panel>
                </Collapse>
            </div>
        </div>

    );


}

export default HelpcenterInnerpage;