import React, { useState, useEffect } from "react";

import NoDataIcon from "../../assets/svg/no-data.svg";
import NotificationItem from "./notificationItem";
import "../../styles/notificationsPage.css";

import { Pagination, Select, Skeleton } from "antd";
import useAxiosInstance from "../../lib/useAxiosInstance";

import { useQuery } from "react-query";
import { useQueryClient, useMutation } from "react-query";

const Notifications = () => {
  // FOR UNREAD MESSAGE
  const queryClient = useQueryClient();
  const bgColor = "var(--notification-unread-background)";
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [seeAllLink, setSeeAllLink] = useState("var(--dark-color)");
  // const [unreadNotifications, setUnreadNotifications] = useState();

  const [notifications, setNotifications] = useState([]);
  const axiosInstance = useAxiosInstance();
  const fetchNotifications = async () => {
    const response = await axiosInstance.get(
      `api/notification?&page=${currentPage}&limit=${limit}`
    );
    return response.data;
  };

  const { data, isLoading } = useQuery(
    ["notifications", currentPage, limit],
    fetchNotifications,
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  const handleMarkAllRead = async () => {
    try {
      await markAllReadMutation.mutateAsync();
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const markAllReadMutation = useMutation(
    async () => {
      await axiosInstance.post("/api/notification/markAsReadAll");
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notifications");
        setSeeAllLink("var(--stat-text)");
      }
    }
  );
  // const pagination = {
  //   total: total * limit,
  //   pageSize: limit,
  //   current: currentPage,
  //   onChange: (page, pageSize) => {
  //     setCurrentPage(page);
  //     setLimit(pageSize);
  //   }
  // };
  useEffect(() => {
    if (data?.message?.data) {
      setNotifications(data?.message?.data);
      setTotal(data?.message?.notificationCount);
    }
  }, [data]);

  return (
    <div className='see-all-notifications'>
      <div className='heading-text-div'>
        <p className='heading-text'>Notifications</p>

        <p
          className='read-all-text'
          onClick={handleMarkAllRead}
          style={{ color: seeAllLink }}
        >
          Mark all as Read
        </p>
      </div>
      <div className='allNotifications' style={{ backgroundColor: bgColor }}>
        {isLoading ? (
          <Skeleton
            title={false}
            className='mt-4 mb-4 p-8 '
            active
            paragraph={{
              rows: 6,
              width: [
                "100%",
                "100%",
                "100%",
                "100%",
                "100%",
                "100%",
                "100%",
                "100%",
                "100%",
                "100%"
              ],
              height: ["30px", "30px", "30px", "30px", "30px"]
            }}
          />
        ) : notifications && notifications.length > 0 ? (
          notifications.map((item) => (
            <NotificationItem
              key={item?._id}
              url={item?.profileURL}
              title={item?.title}
              content={item?.content}
              time={item?.createdAt}
              bgColor={
                item?.isViewed ? "var(--notification-read-background)" : bgColor
              }
            />
          ))
        ) : (
          <div className='no-data-icon m-20'>
            <img src={NoDataIcon} alt='' />
            <h1>No Notification Available</h1>
          </div>
        )}

        <div className='report-pagination '>
          <div>
            <Select
              value={limit.toString()}
              style={{ width: 56 }}
              onChange={(value) => {
                setLimit(parseInt(value));
                setCurrentPage(1);
              }}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "50", label: "50" },
                { value: "100", label: "100" }
              ]}
            />
            <span className='pl-2'> Entries per pages</span>
          </div>

          <div className='report-selection'>
            <Pagination
              className='pagination'
              current={currentPage}
              pageSize={limit}
              total={total}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setLimit(pageSize);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
