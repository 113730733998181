// useGeoData.js
import { useState, useEffect } from "react";
import useAxiosInstance from "../../../../lib/useAxiosInstance";

const useGeoData = (polygonData) => {
  const axiosInstance = useAxiosInstance();
  const [polyData, setPolyData] = useState([]);

  const fetchConstituencyById = async (id) => {
    if (id) {
      return axiosInstance.get(`api/constituency/getConstituencyById/${id}`);
    } else {
      return Promise.resolve({ data: null });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const initialData = await fetchConstituencyById(
          polygonData?.constituencyId
        );
        setPolyData([initialData?.data?.data]);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    if (polygonData?.constituencyId) {
      fetchData();
    }
  }, [polygonData?.constituencyId]);

  return [polyData, setPolyData];
};

export default useGeoData;
