import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BillingTable from "./BillingClientInfo/BillingTable";
import BillingOverview from "./BillingOverview/BillingOverview";
// import MoreInfo from "./BillingClientInfo/MoreInfo";

const BillingTabs = () => {

  const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('overview');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        navigate(`../${tab}`);
    };

    return (
        <>
      <div className='tabs1 billing-tabs'>
        <div className='gms-table-tabs'>
          <button
            className={`tab-button tab-btn1 ${
              activeTab === "overview" ? "active" : ""
            }`}
            onClick={() => handleTabChange("overview")}
          >
            Overview
          </button>
          <button
            className={`tab-button ${
              activeTab === "client-info" ? "active" : ""
            } ml-5`}
            onClick={() => handleTabChange("client-info")}
          >
            Client Info
          </button>
        </div>
      </div>

      <div className='tab-content'>
        <div className='report-page-section'>
          <div className='report-table-wrapper upload-invoice-table'>
            {activeTab === "overview" && <BillingOverview />}
            {activeTab === "client-info" && <BillingTable />}
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingTabs;
