import { ResponsivePie } from "@nivo/pie";
import NoDataIcon from "../../../assets/svg/no-data.svg";


const colors = ["#004877", "#06CCF3", "#E5E5E5"];

const BillingPieChart = ({ totalStats = {} }) => {
  const data = [
    {
      id: "Fully Paid",
      label: "Fully Paid ",
      label: ` Fully Paid (${totalStats?.totalFullyPaid})`,
      value: totalStats?.totalFullyPaid,
      color: "#004877"
    },
    {
      id: "Partially Paid",
      label: `Partially Paid (${totalStats?.totalPartiallyPaid})`,
      value: totalStats?.totalPartiallyPaid,
      color: " #E67E22"
    },
    {
      id: "Unpaid",
      label: `Unpaid (${totalStats?.totalUnpaid})`,
      value: totalStats?.totalUnpaid,
      color: "#27AE60"
    }
  ];
  return (
    <div style={{ height: "370px" }} className='flex justify-center'>
      {!totalStats ? (
        <div className='flex flex-col justify-center items-center no-data-icon'>
          <img src={NoDataIcon} alt='No data' />
          <span>No data</span>
        </div>
      ) : (
        <ResponsivePie
          data={data}
          colors={colors}
          margin={{ top: 0, right: 50, bottom: 90, left: 50 }}
          innerRadius={0.6}
          padAngle={0.7}
          activeOuterRadiusOffset={5}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]]
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor='none'
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={null}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]]
          }}
          fill={(datum) => datum.data.color}
          legends={[
            {
              anchor: "bottom",
              direction: "column",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 8,
              itemWidth: 100,
              itemHeight: 7,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 10,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000"
                  }
                }
              ]
            }
          ]}
        />
      )}
    </div>
  );
};

export default BillingPieChart;
