import React, { useContext } from 'react'
import { Modal, Input, Row, Col } from 'antd';
import { useMutation, useQueryClient } from "react-query";
import useAxiosInstance from '../../../lib/useAxiosInstance';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ErrorMessage from '../../../components/Forms/ErrorMessage';
import GmsContext from '../../../context/gmsContext';

const CategoryForm = ({ isOpen, isAddMode }) => {

    const gmsContext = useContext(GmsContext);

    const axiosInstance = useAxiosInstance();
    const queryClient = useQueryClient();

    const FormSchema = Yup.object().shape({
        categoryName: Yup.string().trim().required('Category Name is required').min(3, 'Category Name is minimum 3 character'),
        categoryDescription: Yup.string().trim().required('Category Description is required').min(10, 'Category Description is minimum 10 character')
    });

    const formik = useFormik({
        initialValues: {
            categoryName: isAddMode ? '' : gmsContext.editData.name,
            categoryDescription: isAddMode ? '' : gmsContext.editData.description,
        },
        enableReinitialize: true,
        validationSchema: FormSchema,
        onSubmit: (values, onSubmitProps) => {
            handleSubmit(values, onSubmitProps);
        }
    });

    const handleSubmit = async (value) => {
        if (isAddMode) {
            handleCreate.mutate(value);
        } else {
            const obj = {
                name: value.categoryName,
                description: value.categoryDescription
            };
            handleUpdate.mutate(obj);
        }
    };

    const handleCreate = useMutation((formData) => axiosInstance.post(`api/category`, formData).then((response) => response.data),
        {
            onSuccess: (data) => {
                handleSuccess(data);
            },
            onError: (error) => {
                handleError(error);
            },
        }
    );

    const handleUpdate = useMutation((formData) => axiosInstance.put(`api/category/${gmsContext.editData._id}`, formData).then((response) => response.data),
        {
            onSuccess: (data) => {
                handleSuccess(data);
            },
            onError: (error) => {
                handleError(error);
            },
        }
    );

    const handleSuccess = (data) => {
        queryClient.invalidateQueries("categories");
        queryClient.invalidateQueries("allSearchCategory");
        closeModal();
        toast.success(`${data?.message}`);
    };

    const handleError = (error) => {
        toast.error("Something went wrong.");
        closeModal();
    };

    const closeModal = () => {
        gmsContext.updateOpenModal(false);
        gmsContext.updateAddMode(true);
        formik.resetForm();
    };

    return (
        <>
            {isOpen && (
                <Modal className='add-user-modal' open={isOpen} onCancel={closeModal} width={1040}
                    maskClosable={false}
                    footer={[
                        <button form="category-form" type='submit' className='gms-modal-button'>
                            {isAddMode ? 'Add New Category' : 'Save Changes'}
                        </button>
                    ]}
                >
                    <form id="category-form" onSubmit={formik.handleSubmit}>
                        <div className='user-info-modal'>
                            <div className='modal-title'>
                                <h3>{isAddMode ? 'Add New Category' : 'Edit Category'}</h3>
                            </div>
                            <div className='modal-wrapper-body'>
                                <div className='modal-wrapper-content pb-7'>
                                    <h4>Category Details</h4>

                                    <Row className='add-modal-row mt-7'>
                                        <Col span={8} className='mr-6'>
                                            <label htmlFor="">Category Name</label>
                                            <Input type="text" placeholder="Category Name" name="categoryName"
                                                onChange={formik.handleChange} value={formik.values.categoryName}
                                            />
                                            <ErrorMessage
                                                hasError={Boolean(formik.errors.categoryName && formik.touched.categoryName)}
                                                message={formik.errors.categoryName}
                                            />
                                        </Col>

                                        <Col span={8}>
                                            <label htmlFor="">Category Description</label>
                                            <Input type='text' placeholder='Category Description' name='categoryDescription'
                                                onChange={formik.handleChange} value={formik.values.categoryDescription}
                                            />
                                            <ErrorMessage
                                                hasError={Boolean(formik.errors.categoryDescription && formik.touched.categoryDescription)}
                                                message={formik.errors.categoryDescription}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal>
            )}
        </>
    )
};

export default CategoryForm;