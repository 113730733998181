import React from "react";
import { Modal } from "antd";

const AssemblyInformation = ({
  isViewDetails,
  setIsInfoModalOpen,
  polygonData
}) => {
  const closeModal = () => {
    setIsInfoModalOpen(false);
  };
  return (
    <>
      {isViewDetails && (
        <Modal
          className='add-user-modal'
          open={isViewDetails}
          width={1040}
          onCancel={closeModal}
          footer={false}
        >
          <div className='modal-title'>
            <h3>Constituency Information</h3>
          </div>
          <div className='modal-wrapper-body rounded-b-lg'>
            <div className='modal-wrapper-content'>
              <div className='basicInfoDiv'>
                <div className='firstname-field'>
                  <p>
                    <span>Constituency ID</span>
                    <span style={{ textTransform: "capitalize" }}>
                      {" "}
                      {polygonData?.constituencyId},{" "}
                    </span>
                  </p>
                  <p>
                    <span>State</span> <span> {polygonData?.state}</span>
                  </p>
                </div>
              </div>
              <div className='basicInfoDiv'>
                <div className='firstname-field'>
                  <p>
                    <span>District</span>
                    <span>{polygonData?.district}</span>
                  </p>
                  <p>
                    <span>Established Year</span>
                    <span>1951</span>
                  </p>
                </div>
              </div>
              <div className='basicInfoDiv'>
                <div className='firstname-field'>
                  <p>
                    <span>No. of Villages</span>
                    <span>-</span>
                  </p>
                  <p>
                    <span>No. of Polling Stations</span>
                    <span>-</span>
                  </p>
                </div>
              </div>
              <div className='basicInfoDiv'>
                <div className='firstname-field'>
                  <p className='flex'>
                    <span>Description</span>
                    <span className='inline-block w-3/5'>
                      -
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
export default AssemblyInformation;
