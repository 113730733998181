const lib = ["places"];

const indiaCenter = { lat: 23, lng: 78.9629 };
const initialZoom = 4.8;
const indiaBounds = {
  north: 40.5, // Northernmost point
  south: 0, // Southernmost point
  west: 45, // Westernmost point
  east: 110 // Easternmost point
};
export { lib, indiaCenter, initialZoom, indiaBounds };