import React from "react";

import Overview from "../assets/svg/dashboard.svg";
import ClientIcon from "../assets/svg/client.svg"; // Renamed to avoid conflict with the variable name
import Users from "../assets/svg/Users.svg";
import GMS from "../assets/svg/gms.svg";
import TMS from "../assets/svg/tms.svg";
import ConstituencyManagement from "../assets/svg/constituency.svg";
import Reports from "../assets/svg/reports.svg";
import Survey from "../assets/svg/survey.svg";
import CommunityDictionary from "../assets/svg/community.svg";
import Billing from "../assets/svg/billing.svg";
import CustomerSupport from "../assets/svg/customerSupport.svg";
import Help from "../assets/svg/help.svg";
import Settings from "../assets/svg/settings.svg";
import PartyIcon from "../assets/svg/party.svg";

// Define Client variable for direct usage
const Client = <img src={ClientIcon} alt='' />;

const SidebarMapping = () => {
  return {
    admin: [
      {
        id: 0,
        path: "/dashboard",
        name: "Overview",
        icon: <img src={Overview} alt='' />
      },
      {
        id: 16,
        path: "/client",
        name: "Client",
        icon: Client
      },
      {
        id: 2,
        path: "/users",
        name: "Users",
        icon: <img src={Users} alt='' />
      },
      {
        id: 3,
        path: "/gms",
        name: "GMS",
        icon: <img src={GMS} alt='' className='sidebar-icons' />
      },
      {
        id: 4,
        path: "/tms",
        name: "TMS",
        icon: <img src={TMS} alt='' className='sidebar-icons' />
      },
      {
        id: 5,
        path: "/constituency-management",
        name: "Constituency Management",
        icon: <img src={ConstituencyManagement} alt='' />
      },
      {
        id: 6,
        path: "/reports",
        name: "Reports",
        icon: <img src={Reports} alt='' />
      },
      {
        id: 7,
        path: "/survey",
        name: "Survey",
        icon: <img src={Survey} alt='' />
      },
      {
        id: 8,
        path: "/community-dictionary",
        name: "Community Dictionary",
        icon: (
          <img
            src={CommunityDictionary}
            alt=''
            className='sidebar-icons community-dictionary'
          />
        )
      },
      {
        id: 9,
        path: "/party-management",
        name: "Party Management",
        icon: (
          <img
            src={PartyIcon}
            alt=''
            className='sidebar-icons community-dictionary'
          />
        )
      },
      {
        id: 10,
        path: "/billing",
        name: "Billing",
        icon: <img src={Billing} alt='' />
      },
      {
        id: 11,
        path: "/customer-support",
        name: "Customer Support",
        icon: <img src={CustomerSupport} alt='' className='sidebar-icons' />
      },
      {
        id: 12,
        path: "/settings",
        name: "Settings",
        icon: <img src={Settings} alt='' className='sidebar-icons' />
      },
      {
        id: 13,
        path: "/help-center",
        name: "Help",
        icon: <img src={Help} alt='' className='sidebar-icons' />
      }
    ],
    superadmin: [
      {
        id: 0,
        path: "/dashboard",
        name: "Overview",
        icon: <img src={Overview} alt='' />
      },
      {
        id: 16,
        path: "/client",
        name: "Client",
        icon: Client
      },
      {
        id: 2,
        path: "/users",
        name: "Users",
        icon: <img src={Users} alt='' />
      },
      {
        id: 3,
        path: "/gms",
        name: "GMS",
        icon: <img src={GMS} alt='' className='sidebar-icons' />
      },
      {
        id: 4,
        path: "/tms",
        name: "TMS",
        icon: <img src={TMS} alt='' className='sidebar-icons' />
      },
      {
        id: 5,
        path: "/constituency-management",
        name: "Constituency Management",
        icon: <img src={ConstituencyManagement} alt='' />
      },
      {
        id: 6,
        path: "/reports",
        name: "Reports",
        icon: <img src={Reports} alt='' />
      },
      {
        id: 7,
        path: "/survey",
        name: "Survey",
        icon: <img src={Survey} alt='' />
      },
      {
        id: 8,
        path: "/community-dictionary",
        name: "Community Dictionary",
        icon: (
          <img
            src={CommunityDictionary}
            alt=''
            className='sidebar-icons community-dictionary'
          />
        )
      },
      {
        id: 9,
        path: "/party-management",
        name: "Party Management",
        icon: (
          <img
            src={PartyIcon}
            alt=''
            className='sidebar-icons community-dictionary'
          />
        )
      },
      {
        id: 10,
        path: "/billing",
        name: "Billing",
        icon: <img src={Billing} alt='' />
      },
      {
        id: 11,
        path: "/customer-support",
        name: "Customer Support",
        icon: <img src={CustomerSupport} alt='' className='sidebar-icons' />
      },
      {
        id: 12,
        path: "/settings",
        name: "Settings",
        icon: <img src={Settings} alt='' className='sidebar-icons' />
      },
      {
        id: 13,
        path: "/help-center",
        name: "Help",
        icon: <img src={Help} alt='' className='sidebar-icons' />
      }
    ],
    clientManager: [
      {
        id: 16,
        path: "/client",
        name: "Client",
        icon: Client
      },

      // {
      //   id: 2,
      //   path: "/users",
      //   name: "Users",
      //   icon: <img src={Users} alt='' />
      // },
      {
        id: 3,
        path: "/gms",
        name: "GMS",
        icon: <img src={GMS} alt='' className='sidebar-icons' />
      },
      {
        id: 4,
        path: "/tms",
        name: "TMS",
        icon: <img src={TMS} alt='' className='sidebar-icons' />
      },
      {
        id: 5,
        path: "/constituency-management",
        name: "Constituency Management",
        icon: <img src={ConstituencyManagement} alt='' />
      },
      {
        id: 6,
        path: "/reports",
        name: "Reports",
        icon: <img src={Reports} alt='' />
      },
      {
        id: 7,
        path: "/survey",
        name: "Survey",
        icon: <img src={Survey} alt='' />
      },
      {
        id: 8,
        path: "/community-dictionary",
        name: "Community Dictionary",
        icon: (
          <img
            src={CommunityDictionary}
            alt=''
            className='sidebar-icons community-dictionary'
          />
        )
      },
      {
        id: 9,
        path: "/party-management",
        name: "Party Management",
        icon: (
          <img
            src={PartyIcon}
            alt=''
            className='sidebar-icons community-dictionary'
          />
        )
      },
      {
        id: 10,
        path: "/customer-support",
        name: "Customer Support",
        icon: <img src={CustomerSupport} alt='' className='sidebar-icons' />
      },
      {
        id: 11,
        path: "/settings",
        name: "Settings",
        icon: <img src={Settings} alt='' className='sidebar-icons' />
      },
      {
        id: 12,
        path: "/help-center",
        name: "Help",
        icon: <img src={Help} alt='' className='sidebar-icons' />
      }
    ],
    accounts: [
      {
        id: 0,
        path: "/dashboard",
        name: "Overview",
        icon: <img src={Overview} alt='' />
      },
      {
        id: 9,
        path: "/billing",
        name: "Billing",
        icon: <img src={Billing} alt='' />
      },
      {
        id: 10,
        path: "/customer-support",
        name: "Customer Support",
        icon: <img src={CustomerSupport} alt='' />
      },
      {
        id: 11,
        path: "/settings",
        name: "Settings",
        icon: <img src={Settings} alt='' />
      },
      {
        id: 12,
        path: "/help-center",
        name: "Help",
        icon: <img src={Help} alt='' />
      }
    ]
  };
};

export default SidebarMapping;
