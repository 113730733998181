import React from "react";
import PartyDataTable from "../../components/PartyManagement/PartyDataTable";
import "../../styles/community.css";

const PartyManagement = () => {
  return (
    <div>
      <PartyDataTable />
    </div>
  );
};

export default PartyManagement;
