import React from "react";
import { RightOutlined } from "@ant-design/icons";
import { MdCheck, MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { useQuery } from "react-query";

// function formatYearFromDate(dateString) {
//   const date = new Date(dateString);

//   const year = date.getFullYear();

//   return year;
// }

const checkIconMapping = {
  Yes: <MdCheck className='text-green-600 inline-block font-semibold' />,
  YES: <MdCheck className='text-green-600 inline-block font-semibold' />,
  NO: <MdClose className='text-red-600 inline-block font-semibold' />
};
const AssemblyDataList = ({ polygon = {}, year }) => {
  const axiosInstance = useAxiosInstance();

  const constituencyId = polygon?.constituencyId;
  const fetchConstituencySummary = async (id, year) => {
    const response = axiosInstance.get(
      `api/constituency/getConstituencySummaryById/${id}?year=${year}`
    );
    const summaryData = response?.data?.data?.data;
    const uploadSummaryDataString = JSON.stringify(summaryData);
    localStorage.setItem("summaryUploadData", uploadSummaryDataString);
    return response;
  };
  const queryData = useQuery(
    ["summary-data", constituencyId, year],
    () => fetchConstituencySummary(constituencyId, year),
    {
      refetchOnWindowFocus: false,
      enabled: constituencyId !== undefined && year !== undefined
    },
    { retry: 1 }
  );
  const summaryData = queryData?.data?.data?.data || {};

  const polygonDataString = JSON.stringify(polygon);
  localStorage.setItem("polygonData", polygonDataString);

  const uploadSummaryDataString = JSON.stringify(summaryData);
  localStorage.setItem("summaryUploadData", uploadSummaryDataString);

  return (
    <>
      <div
        className='constituency-tooltip overflow-hidden'
        style={{ maxHeight: "calc(100vh - 80px)" }}
      >
        <p className='text-sky-600 text-base font-normal'>{polygon?.state}</p>
        <p className='text-2xl font-bold'>
          {polygon?.name}, {polygon?.district}{" "}
          <RightOutlined className='pb-1' />
        </p>
        <p className='text-sm font-normal flex items-center'>
          Constituency ID:{" "}
          <span
            className='constituencyID ml-3'
            style={{
              maxWidth: "8ch",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap"
            }}
            title={constituencyId}
          >
            {constituencyId}
          </span>
        </p>
        <p className='text-sm font-normal'>
          Established Year: <span className='font-semibold ml-4'>{year}</span>
        </p>
        <p className='text-sm font-normal'>
          Form 20:{" "}
          <span className='font-semibold ml-4'>
            {checkIconMapping[summaryData["Form 20"]] || checkIconMapping["NO"]}
          </span>
        </p>
        <p className='text-sm font-normal'>
          Electoral Roll:{" "}
          <span className='font-semibold ml-4'>
            {checkIconMapping[summaryData["Electoral Roll"]] ||
              checkIconMapping["NO"]}
          </span>
        </p>
        <p className='text-sm font-normal'>
          Village Data:{" "}
          <span className='font-semibold ml-4'>
            {checkIconMapping[summaryData["Village Data"]] ||
              checkIconMapping["NO"]}
          </span>
        </p>
        <p className='text-sm font-normal'>
          PS Village Mapping Data:{" "}
          <span className='font-semibold ml-4'>
            {checkIconMapping[summaryData["PS Village Mapping Data"]] ||
              checkIconMapping["NO"]}
          </span>
        </p>

        <Link
          to={`/constituency-management/assembly/view-constituency/${year}`}
        >
          <button type='button'>View Constituency</button>
        </Link>
      </div>
    </>
  );
};

export default AssemblyDataList;
