import React, {useContext, useState} from 'react'
import {Table, Space, Skeleton, Empty} from "antd";
import GmsContext from "../../../context/gmsContext";
import {AppContext} from "../../../context/AppContextProvider";
import DeleteModal from "../../DeleteModal";

const {Column} = Table;

const CategoryDataTable = ({categoryData, categorySearchData, pagination, handleDelete,isLoading}) => {

    const gmsContext = useContext(GmsContext);
    const {handleMouseLeave, isDropdownOpen, handleClick, handleMouseEnter} = useContext(AppContext);

    const [isDeleteModal, setDeleteModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const showModalDelete = (record) => {
        setSelectedRecord(record);
        setDeleteModal(!isDeleteModal)
    };

    const handleEdit = (record) => {
        gmsContext.updateEditData(record);
        gmsContext.updateAddMode(false);
        gmsContext.updateOpenModal(true);
    };
    return (
        <div className='user-data-table'>
            <Table rowKey={(record) => record?._id} className="gms-table-rows" 
            dataSource={categorySearchData || categoryData?.data}
             pagination={pagination}
                        locale={{
          emptyText: isLoading ? (
            <div
              style={{
                marginLeft: "20px",
                width: "95%"
              }}
            >
              {" "}
              {/* Container with 90% width */}
              <Skeleton
                title={false}
                active
                paragraph={{
                  rows: 6,
                  width: ["100%", "100%", "100%", "100%", "100%", "100%"]
                }}
              />
            </div>
          ) : (
            <Empty />
          )
        }}
        >
                <Column title="SR. NO." dataIndex="_id" render={(value, item, index) => index + 1}/>
                <Column title="CATEGORY" dataIndex="name"/>
                <Column title="DESCRIPTION" dataIndex="description" width="30%"/>
                <Column title="ACTION" key="action"
                        render={(text, record, index) => (
                            <Space className="select-option" size="small" style={{}}>
                                <div className="dropdown" onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
                                <span onClick={() => handleClick(index)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 34 30" fill="none">
                                        <circle cx="17.3889" cy="9.38894" r="1.38889" transform="rotate(-90 17.3889 9.38894)" fill="#004877"/>
                                        <circle cx="17.3889" cy="15.5" r="1.38889" transform="rotate(-90 17.3889 15.5)" fill="#004877"/>
                                        <circle cx="17.3889" cy="21.6111" r="1.38889" transform="rotate(-90 17.3889 21.6111)" fill="#004877"/>
                                    </svg>
                                </span>
                                    {isDropdownOpen(index) && (
                                        <div className="dropdown-content">
                                            <div onClick={() => handleEdit(record)}>Edit</div>
                                            <div onClick={() => showModalDelete(record)}>Delete</div>
                                        </div>
                                    )}
                                </div>
                            </Space>
                        )}
                />
            </Table>

            {isDeleteModal && (
                <DeleteModal
                    textheading="Delete Category"
                    deleteBtn="Delete Category"
                    deleteTitle="Category"
                    handleDelete={() => handleDelete(selectedRecord)}
                    isDeleteModal={isDeleteModal}
                    showModalDelete={() => setDeleteModal(false)}
                    action='delete'
                />
            )}
        </div>
    )
};

export default CategoryDataTable;