import React, { useContext, useState } from "react";
import "../../../styles/ShareReports.css";
import { Button, Modal, Row, Col, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "react-query";
import { reportContext } from "../../../context/ReportContextProvider";
import { toast } from "react-toastify";
import useAxiosInstance from "../../../lib/useAxiosInstance";

const ManageSubscribers = () => {
  const {
    manageSubscribers,
    showSubscribersModal,
    selectedClient,
    setSelectedClient
  } = useContext(reportContext);
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();

  const handleCancel = () => {
    showSubscribersModal();
    setSelectedClientsList([]);
    setSelectedSubcribers(null);
  };
  const subscribersQuery = queryClient.getQueryData("Subscribers-List");

  const [selectedSubcribers, setSelectedSubcribers] = useState(null);
  const [selectedClientsList, setSelectedClientsList] = useState([]);

  const handleClientChange = (value) => {
    setSelectedSubcribers(value);
  };

  // SELECT SUBSCRIBERS
  const handleAddClient = () => {
    if (selectedClient && selectedSubcribers) {
      // Check if the subscriber is already in either of the lists
      const isSubscriberAlreadyAdded =
        selectedClient.subscribers.some(
          (subscriber) => subscriber._id === selectedSubcribers
        ) || selectedClientsList.includes(selectedSubcribers);

      if (isSubscriberAlreadyAdded) {
        toast.error("Subscriber already added to the list.");
      } else {
        setSelectedClientsList([...selectedClientsList, selectedSubcribers]);
        setSelectedSubcribers(null);
      }
    }
  };
  const handleRemoveClient = (clientToRemove) => {
    setSelectedClientsList(
      selectedClientsList.filter((client) => client !== clientToRemove)
    );

    // Remove the subscriber from selectedClient.subscribers if it exists
    setSelectedClient((prevSelectedClient) => ({
      ...prevSelectedClient,
      subscribers: prevSelectedClient.subscribers.filter(
        (subscriber) => subscriber !== clientToRemove
      )
    }));
  };

  // UPDATE SUBSCRIBERS
  const updateSubscribersMutation = useMutation(
    (updatedSubscribers) => {
      return axiosInstance.put(`api/reports/${updatedSubscribers._id}`, {
        subscribers: updatedSubscribers.subscribers,
        reportName: updatedSubscribers.reportName,
        resourceLocation: [
          {
            name: updatedSubscribers.resourceLocation[0].name,
            key: updatedSubscribers.resourceLocation[0].key,
            publicUrl: updatedSubscribers.resourceLocation[0].publicUrl
          }
        ]
      });
    },
    {
      onSuccess: () => {
        showSubscribersModal();
        setSelectedClientsList([]);
        queryClient.invalidateQueries("report-list");
        queryClient.invalidateQueries("allSearchReports");
        queryClient.invalidateQueries("Subscribers-List");
        // setSelectedClient(null);
        toast.success("Subscribers updated successfully.");
      },
      onError: (error) => {
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Failed to update subscribers.");
        }
      }
    }
  );
  const handleSubmit = () => {
    const selectedSubscribers = selectedClient?.subscribers || [];

    const addedSubscribers = selectedClientsList.map((subscriberId) => {
      const subscriber = subscribersQuery?.find(
        (sub) => sub._id === subscriberId
      );
      return subscriber
        ? {
            _id: subscriber._id,
            firstName: subscriber.adminFirstName,
            lastName: subscriber.adminLastName
          }
        : null;
    });

    const updatedSubscribers = {
      ...selectedClient,
      subscribers: [...selectedSubscribers, ...addedSubscribers]
    };

    updateSubscribersMutation.mutate(updatedSubscribers);
  };
  return (
    <>
      <div className='report-page-title'>
        {manageSubscribers && (
          <Modal
            className='share-report-modal'
            open={manageSubscribers}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            width={1040}
            maskClosable={false}
            footer={[
              <Button
                type='button'
                key='manage_subscribers'
                className='report-upload-modal-button'
                onClick={handleSubmit}
              >
                Save
              </Button>
            ]}
          >
            <div className='modal-title'>
              <h3>Manage Subscribers</h3>
            </div>
            <div className='modal-wrapper-body h-50'>
              <div className='modal-wrapper-content manage-subscriber-modal'>
                <h4>Client Name</h4>
                <Row className='add-modal-row my-2'>
                  <Col span={10} className='add-clients'>
                    {selectedClient?.subscribers &&
                      selectedClient?.subscribers?.map((manageclients) => (
                        <div
                          key={manageclients._id}
                          className='flex justify-between'
                        >
                          <div className='client-name'>{`${manageclients.firstName} ${manageclients.lastName}`}</div>

                          <CloseOutlined
                            onClick={() => handleRemoveClient(manageclients)}
                            className='manage-subscribers'
                          />
                        </div>
                      ))}

                    {selectedClient?.subscribers &&
                      selectedClientsList.map((subscriberId) => {
                        const subscriber = subscribersQuery?.find(
                          (sub) => sub._id === subscriberId
                        );
                        if (subscriber) {
                          return (
                            <div
                              key={subscriber._id}
                              className='flex justify-between'
                            >
                              <div className='client-name'>
                                {`${subscriber.adminFirstName} ${subscriber.adminLastName}`}
                              </div>
                              <CloseOutlined
                                onClick={() =>
                                  handleRemoveClient(subscriber._id)
                                }
                                className='manage-subscribers'
                              />
                            </div>
                          );
                        }
                        return null;
                      })}
                  </Col>
                </Row>
                <h4 className='pt-6'>Add Subscribers</h4>
                <Row className='add-modal-row manage-subscriber-row my-5'>
                  <Col span={20}>
                    <label className='d-block'>Client Name</label>
                    <Select
                      onChange={handleClientChange}
                      value={selectedSubcribers}
                      size='large'
                      id='state'
                      placeholder='Search client'
                      style={{ width: 365, height: "60px" }}
                      showSearch
                      filterOption={(input, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      {subscribersQuery?.map((managesubscriber) => (
                        <Select.Option
                          key={managesubscriber._id}
                          value={managesubscriber._id}
                        >
                          {`${managesubscriber.adminFirstName} ${managesubscriber.adminLastName}`}
                        </Select.Option>
                      ))}
                    </Select>
                    <Button
                      onClick={handleAddClient}
                      className='manage-subscribers'
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default ManageSubscribers;
