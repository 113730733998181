import React, {useContext, useState} from 'react'
import {Button, Pagination, Select, Space, Table, Skeleton, Empty} from "antd";
import {useQuery, useQueryClient} from "react-query";
import SearchFilter from '../../SearchFilter';
import useAxiosInstance from '../../../lib/useAxiosInstance';
import {toast} from 'react-toastify';

import Column from "antd/es/table/Column";
import DeleteModal from "../../DeleteModal";
import shareIcon from "../../../assets/svg/share.svg"
import {AppContext} from "../../../context/AppContextProvider";
import {surveyContext} from '../../../context/SurveyContextProvider';
import SurveyContext from "../../../context/surveyContext";
import DeactivateSurvey from "./DeactivateSurvey";
import ManageSurveySubscribers from './ManageSurveySubscribers';
import ShareSurvey from "./ShareSurvey";

const SurveyDatabaseList = () => {

    const surveyContext1 = useContext(SurveyContext);

    const axiosInstance = useAxiosInstance();
    const queryClient = useQueryClient();

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const {handleClick, handleMouseEnter, handleMouseLeave, isDropdownOpen} = useContext(AppContext);
    const {showSurveySubscribers} = useContext(surveyContext);

    // GET ALL SURVEY DATA
    const fetchSurveyList = async () => {
        const response = await axiosInstance.get(`api/survey?page=${currentPage}&limit=${limit}`);
        setTotal(response?.data?.data?.totalPages);
        return response;
    };

    const surveyListData = useQuery(
      ["survey-list", currentPage, limit],
      () => fetchSurveyList(),
      {
        refetchOnWindowFocus: false,
        retry: 1
      }
    );

    // GET ALL SEARCH SURVEY DATA
    const SearchUser = (value) => {
        setCurrentPage(1);
        setSearchKeyword(value);
    };

    const fetchSearchSurvey = async () => {
        let response;
        if (searchKeyword) {
            response = await axiosInstance.get(
              `api/survey/searchAndFilter?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}`
            );
           
        setTotal(response?.data?.totalpages);
        }
        else{
            const response = await axiosInstance.get(
              `api/survey?page=${currentPage}&limit=${limit}`
            );
            setTotal(response.data.data.totalPages);
        }
        return response;
    };

    const surveySearchData = useQuery(
      ["allSearchSurvey", searchKeyword],
      () => fetchSearchSurvey(),
      {
        refetchOnWindowFocus: false,
        retry: 1
      }
    );

    // FOR PAGINATION
    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setLimit(pageSize);
    };

    const pagination = {
        total: total * limit,
        pageSize: limit,
        current: currentPage,
        onChange: handlePageChange,
    };

    // SORTING
    const uploadDateSorter = (a, b) => {
        const aDate = new Date(a.createdAt).getTime();
        const bDate = new Date(b.createdAt).getTime();
        return aDate - bDate;
    };

    // DELETE SURVEY
    const showModalDelete = (record) => {
        setSelectedRecord(record);
        setDeleteModal(true);
    };

    const handleDelete = async (records) => {
        try {
            const response = await axiosInstance.delete(`api/survey/${records._id}`);
            const message = response?.data?.message;
            toast.success(`${message}`);
            queryClient.invalidateQueries('survey-list');
            queryClient.invalidateQueries('allSearchSurvey')
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Something went wrong.");
            }
        }
    };

    // SURVEY SELECTION
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // SHARE REPORT
    const [shareSurvey, setShareSurvey] = useState(false);
    const [selectedSurveyData, setSelectedSurveyData] = useState(null);

    const showSelectSurveyError = () => {
        toast.error("Please select one or more reports to share.");
    };

    const openShareModal = () => {
        if (selectedRowKeys.length === 0) {
            showSelectSurveyError();
        } else {
            const selectedSurveys = surveyListData?.data?.data?.data?.results?.filter((record) =>
                selectedRowKeys.includes(record._id)
            );

            const hasDeactivatedSurveys = selectedSurveys.some((survey) => survey.surveyStatus === 'deactivated');

            if (hasDeactivatedSurveys) {
                toast.error("Inactive reports cannot be shared.");
            } else {
                setShareSurvey(!shareSurvey);
                setSelectedSurveyData(selectedSurveys);
            }
        }
    };

    const handleEdit = (record, type) => {
        surveyContext1.updateEditData(record);

        if (type === 'edit') {
            surveyContext1.updateAddMode(false);
            surveyContext1.updateOpenModal(true);
        } else if (type === 'status') {
            surveyContext1.updateStatusModal(true);
        }
    };
    const isLoading = surveyListData?.isLoading|| surveySearchData?.isLoading;

    return (
        <>
            <div className="search-share">
                <SearchFilter handleSearch={SearchUser}/>

                <div className="share-modal-btn">
                    <Button key="share_reports_modal" onClick={() => openShareModal(selectedRowKeys)}>Share <img src={shareIcon} alt=""/></Button>
                </div>
            </div>

            <div className="reports-table">
                <Table className="report-tableRows" rowSelection={rowSelection} rowKey={(record) => record._id}
                       dataSource={surveySearchData?.data?.data?.data || surveyListData?.data?.data?.data?.results} pagination={pagination}
                                   locale={{
          emptyText: isLoading ? (
            <div
              style={{
                marginLeft: "20px",
                width: "95%"
              }}
            >
              {" "}
              {/* Container with 90% width */}
              <Skeleton
                title={false}
                active
                paragraph={{
                  rows: 6,
                  width: ["100%", "100%", "100%", "100%", "100%", "100%"]
                }}
              />
            </div>
          ) : (
            <Empty />
          )
        }}
                >
                    <Column title="SURVEY NAME" dataIndex="surveyName" className='w-80'
                            render={(text, record) => (
                                <>
                                    {record.surveyStatus === 'deactivated' && <span className="deactivate-report">inactive</span>}
                                    <span>{record.surveyName}</span>
                                </>
                            )}
                    />
                    <Column title="DESCRIPTION" dataIndex="surveyDescription" className='report-name-tooltip'/>
                    <Column title="UPLOADED BY" dataIndex="uploaderName" className='w-44'/>
                    <Column title="UPLOAD DATE" dataIndex="createdAt" sorter={uploadDateSorter} className='w-40'
                            defaultSortOrder="descend"
                            render={(text, record) => (
                                <span>
                                {new Date(record.createdAt).toLocaleDateString("en-GB")}
                            </span>
                            )}
                    />
                    <Column title="COUNT OF SUBSCRIBERS" dataIndex="subscribers"
                            render={(subscribers, record) => (
                                <span>{Array.isArray(record?.subscribers) ? record.subscribers.length : 0}</span>
                            )}
                    />
                    {!selectedRowKeys.length && (
                        <Column title="ACTION" key="action"
                                render={(text, record, index) => (
                                    <Space className="select-option" size="small" style={{}}>
                                        <div className="dropdown" onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave}>
                                        <span onClick={() => handleClick(index)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 34 30" fill="none">
                                                <circle cx="17.3889" cy="9.38845" r="1.38889" transform="rotate(-90 17.3889 9.38845)" fill="#004877"/>
                                                <circle cx="17.3889" cy="15.4998" r="1.38889" transform="rotate(-90 17.3889 15.4998)" fill="#004877"/>
                                                <circle cx="17.3889" cy="21.6111" r="1.38889" transform="rotate(-90 17.3889 21.6111)" fill="#004877"/>
                                            </svg>
                                        </span>

                                            {isDropdownOpen(index) && (
                                                <div className="dropdown-content">
                                                    <div key={`status-${index}`} onClick={() => handleEdit(record, 'status')}>
                                                        {record.surveyStatus === 'activated' ? 'Deactivate' : 'Activate'}
                                                    </div>

                                                    {record.surveyStatus === 'activated' &&
                                                    <div key={`edit-${index}`} onClick={() => handleEdit(record, 'edit')}>Edit</div>
                                                    }

                                                    <div key={`delete-${index}`} onClick={() => showModalDelete(record)}>Delete</div>

                                                    {record.surveyStatus === 'activated' &&
                                                    <div key={`manage-${index}`} onClick={() => showSurveySubscribers(record)}>Manage Subscribers</div>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    </Space>
                                )}
                        />
                    )}
                </Table>

                <div className="report-pagination">
                    <div>
                        <Select value={limit.toString()} style={{width: 56}} onChange={(value) => setLimit(parseInt(value))}
                                options={[
                                    {value: "10", label: "10"},
                                    {value: "20", label: "20"},
                                    {value: "50", label: "50"},
                                    {value: "100", label: "100"},


                                ]}
                        /> 
                        <span className="px-4">Entries Per Pages</span>
                    </div>

                    <div className='report-selection'>
                        <Pagination current={currentPage} pageSize={limit} total={total * limit}
                                    onChange={(page, pageSize) => {
                                        setCurrentPage(page);
                                        setLimit(pageSize);
                                    }}
                                    className="pagination"
                        />
                    </div>
                </div>
            </div>

            {surveyContext1.isStatusModal &&
            <DeactivateSurvey isStatusModal={surveyContext1.isStatusModal} editData={surveyContext1.editData}/>
            }

            {isDeleteModal &&
            <DeleteModal
                textheading="Delete Survey"
                deleteTitle="Survey"
                deleteBtn="Delete Survey"
                handleDelete={() => handleDelete(selectedRecord)}
                isDeleteModal={isDeleteModal}
                showModalDelete={() => setDeleteModal(false)}
                action='delete'
            />
            }

            <ManageSurveySubscribers/>

            {shareSurvey && (
                <ShareSurvey
                    shareSurvey={shareSurvey}
                    openShareModal={openShareModal}
                    selectedSurveyData={selectedSurveyData}
                    setSelectedSurveyData={setSelectedSurveyData}
                />
            )}
        </>
    );
};

export default SurveyDatabaseList;