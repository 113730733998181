import React from 'react'
import '../../styles/helpcenter.css'
import Balu from '../../assets/videos/movie.mp4'
// import HelpcenterInnerpage from './helpcenter-innerpage'
import Assismanagement from "../../assets/images/icons/Assignment.png"
import icon1 from "../../assets/images/icons/rocket.png"
import accountPlus from "../../assets/images/icons/account-plus.png"
import icon3 from "../../assets/images/icons/icon3.png"
import accountBox from "../../assets/images/icons/account-box.png"
import gms from "../../assets/images/icons/Description.png"
import { RiSlideshow3Fill } from 'react-icons/ri'
import { BsQuestionCircleFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const HelpCenter = () => {
  return (
    <>

      <div className='mx-6'> <h1 className='heading-text'>Help Center</h1>
      </div>
      <div className='helpcenter-common mt-7'>
        <h3 className='font-bold text-lg leading-4 pb-5 flex '><span className='icon-clr'><BsQuestionCircleFill className='di mr-3 ' /></span> Help Topics</h3>
        <div className="grid grid-cols-3 gap-20">
          <div className='help-topic'>

            <div className='flex'>
              <div className='helptopic-innerbox'> <img src={icon1} alt="icon" /></div>
              <p className='pt-7 font-bold text-lg leading-4'> <Link to="/helpcenter-innerpage">Getting Started</Link></p>
            </div>

            <p className='help-subpara px-4'>This topic will help you to get you started
              quickly and help you get familiar with the
              application.</p>

          </div>
          <div className='help-topic'>
            <div className='flex'>
              <div className='helptopic-innerbox'> <img src={accountBox} alt="icon" /></div>
              <p className='pt-7 font-bold text-lg leading-4'>Account & Porfile</p>
            </div>

            <p className='help-subpara px-4'>This topic will help you to get you started
              quickly and help you get familiar with the
              application.</p>

          </div>

          <div className='help-topic'>
            <div className='flex'>
              <div className='helptopic-innerbox'> <img src={accountPlus} alt="icon" /></div>
              <p className='pt-7 font-bold text-lg leading-4'>Managing/ Adding Clients</p>
            </div>

            <p className='help-subpara px-4'>This topic will help you to get you started
              quickly and help you get familiar with the
              application.</p>

          </div>
          <div className='help-topic'>
            <div className='flex'>
              <div className='helptopic-innerbox'> <img src={gms} alt="icon" /></div>

              <p className='pt-7 font-bold text-lg leading-4'>GMS</p>
            </div>

            <p className='help-subpara px-4'>This topic will help you to get you started
              quickly and help you get familiar with the
              application.</p>

          </div>
          {/* <div className='help-topic'>
                    <div className='flex'>
                        <img className='helptopic-innerbox'></img>
                        <p className='pt-7 font-bold text-lg leading-4'>Cadre Management</p>
                    </div>

                    <p className='help-subpara px-4'>This topic will help you to get you started
                        quickly and help you get familiar with the
                        application.</p>

                </div> */}

          <div className='help-topic'>
            <div className='flex'>
              <div className='helptopic-innerbox'> <img src={Assismanagement} alt="icon" /></div>

              <p className='pt-7 font-bold text-lg leading-4'>Reports</p>
            </div>

            <p className='help-subpara px-4'>This topic will help you to get you started
              quickly and help you get familiar with the
              application.</p>

          </div>

          <div className='help-topic'>
            <div className='flex'>
              <div className='helptopic-innerbox'> <img src={icon3} alt="icon" /></div>
              <p className='pt-7 font-bold text-lg leading-4'>Users</p>
            </div>

            <p className='help-subpara px-4'>This topic will help you to get you started
              quickly and help you get familiar with the
              application.</p>

          </div>

          {/* <div className='help-topic'>
                    <div className='flex'>
                        <img className='helptopic-innerbox'></img>
                        <p className='pt-7 font-bold text-lg leading-4'>Troubleshooting & FAQs</p>
                    </div>

                    <p className='help-subpara px-4'>This topic will help you to get you started
                        quickly and help you get familiar with the
                        application.</p>
                </div> */}
        </div>


        {/* usefull Videos */}

        <div className='help-center-videos'>
          <h3 className='font-bold text-lg leading-4 text-black pb-5 pt-10 mt-15 flex'><span><RiSlideshow3Fill className='di mr-3 icon-clr' /> </span>Useful Videos</h3>
          <div className="grid grid-cols-3 gap-20">
            <div>
              <video className='rounded-lg' width="100%" height="200" autoPlay muted>
                <source src={Balu} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div >
                <p className='pt-4 font-bold text-lg leading-4'>Getting Started</p>
                <p className='help-subpara pt-4'>Watch this video for a walkthrough of the
                  application.</p>
              </div>
            </div>


            <div>
              <video className='rounded-lg' width="100%" height="200" autoPlay muted>
                <source src={Balu} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div >
                <p className='pt-4 font-bold text-lg leading-4'>Adding New Grievance</p>
                <p className='help-subpara pt-4'>Watch this video for a walkthrough of the
                  application.</p>
              </div>
            </div>

            <div>
              <video className='rounded-lg' width="100%" height="200" autoPlay muted>
                <source src={Balu} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div >
                <p className='pt-4 font-bold text-lg leading-4'>Assigning Task to Cadre</p>
                <p className='help-subpara pt-4'>Watch this video for a walkthrough of the
                  application.</p>
              </div>
            </div>



          </div>
        </div>
      </div>


    </>
  )
}

export default HelpCenter