import React from "react";
// import VillageData from "./VillageInfo";
// import ConstituencyData from "./ConstituencyInfo";
import VillageDataTable from "./VillageInfo/VillageDataTable";
import AssemblyMap from "./ParliamentaryMap";
import sampleVillageData from "./VillageInfo/sampleVillageData.json";
import { getGraphData } from "../utils/getFromattedValue";
import { renderCharts } from "../utils/renderCharts";
import { demographyOptions } from "../utils/demographyConfig";
// import GraphModal from "../GraphModal";
import sampleData from "./ConstituencyInfo/sampleData.json";
import ConstituencyDataTable from "./ConstituencyInfo/constituencyDataTable";
import { Modal, Button } from "antd";
import { Margin, usePDF } from "react-to-pdf";
const Report = ({
  polygonData,
  constituencyDataList,
  center,
  setSelectedMarker = () => {},
  villageFetchedData,
  isDataAvailable,
  selectedMarker,

  isExport,
  setIsExport
}) => {
  const { toPDF, targetRef } = usePDF({
    filename: `${selectedMarker?.title || "Village"}.pdf`,
    page: { margin: Margin.MEDIUM }
  });
  const villageGraphData = demographyOptions.reduce((acc, option) => {
    acc[option.value] = getGraphData(
      option.value,
      sampleVillageData,
      villageFetchedData
    );
    return acc;
  }, {});

  const constituencyGraphData = demographyOptions.reduce((acc, option) => {
    acc[option.value] = getGraphData(
      option.value,
      sampleData,
      constituencyDataList
    );
    return acc;
  }, {});

  return (
    <Modal
      open={isExport}
      onCancel={() => {
        setIsExport(false);
      }}
      width={1040}
      footer={[
        <Button
          key='back'
          onClick={() => setIsExport(false)}
          className='cancel-button mr-2'
        >
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={toPDF}
          className='delete-btn'
        >
          Download
        </Button>
      ]}
    >
      <div className='export-modal modal-body-container'>
        <div className='modal-title'>
          <h3 className=''>Constituency Report</h3>
        </div>

        <div className='modal-wrapper-body'>
          <div ref={targetRef} className='modal-wrapper-content'>
            <div className='mt-3 text-4xl semibold m-auto' style={{color:"var(--dark-color)"}}>
              Constituency Report
            </div>
            <h4 className='mt-10 ml-5'>
              1. Map View of Constituency
            </h4>
            <div className='constituency-graph-modal mt-5 m-auto w-11/12 h-screen'>
              <AssemblyMap
                polygonData={polygonData}
                constituencyDataList={constituencyDataList}
                center={center}
                setSelectedMarker={setSelectedMarker}
                selectedMarker={selectedMarker}
                isDataAvailable={isDataAvailable}
              />
            </div>
            <h4 className='mt-20 ml-5'>
              {" "}
              2. Village Electoral Roll
            </h4>
            <div className='constituency-graph-modal mt-5 m-auto w-11/12'>
              <VillageDataTable
                isDataAvailable={isDataAvailable}
                data={
                  villageFetchedData?.electoralData
                  // ||sampleVillageData?.electoralData
                }
                villageFetchedData={villageFetchedData?.electoralData}
                selectedMarker={selectedMarker}
              />
            </div>
            <h4 className='mt-20 ml-5 pb-0'>
              {" "}
              3. Village Demography
            </h4>
            {demographyOptions && demographyOptions.length > 0 && (
              <div className='flex flex-col gap-4 mt-5'>
                <ol type='a'>
                  {demographyOptions.map((option, index) => (
                    <li key={option.value}>
                      <p className='ml-12'>
                        {String.fromCharCode(97 + index)}. {option.label} Chart
                      </p>

                      <div className='constituency-graph-modal m-auto my-4 w-11/12'>
                        {renderCharts(
                          option.value,
                          isDataAvailable,
                          villageGraphData[option.value]
                        )}
                      </div>
                    </li>
                  ))}
                </ol>
              </div>
            )}
            <h4 className='mt-20 ml-5 pb-0'>
              {" "}
              4. Constituency Electoral Roll
            </h4>
            <div className='constituency-graph-modal mt-5 m-auto  w-11/12'>
              <ConstituencyDataTable
                isDataAvailable={isDataAvailable}
                // data={sampleData?.electoralData}
                data={constituencyDataList?.electoralData}
              />
            </div>

            <h4 className='mt-20 ml-5 pb-0'>
              {" "}
              5. Constituency Demography
            </h4>
            {demographyOptions && demographyOptions.length > 0 && (
              <div className='flex flex-col gap-4 mt-5'>
                <ol type='a'>
                  {demographyOptions.map((option, index) => (
                    <li key={option.value}>
                      <p className='ml-12'>
                        {String.fromCharCode(97 + index)}. {option.label} Chart
                      </p>

                      <div className='constituency-graph-modal m-auto my-4 w-11/12'>
                        {renderCharts(
                          option.value,
                          isDataAvailable,
                          constituencyGraphData[option.value]
                        )}
                      </div>
                    </li>
                  ))}
                </ol>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default Report;
