import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import ExpandIcon from "../../../../assets/svg/expandIcon.svg";
import GraphModal from "../../GraphModal";
import AssemblyMap from "../AssemblyMap";
import VillageDataTable from "./VillageDataTable";
import sampleVillageData from "./sampleVillageData.json";

import { getGraphData } from "../../utils/getFromattedValue";
import { renderCharts } from "../../utils/renderCharts";
import { demographyOptions } from "../../utils/demographyConfig";
// import { getIsDataAvailable } from "../../utils/getIsDataAvailable";

const VillageData = ({
  polygonData,
  constituencyDataList,
  center,
  setSelectedMarker = () => {},
  villageFetchedData,
  isDataAvailable,
  selectedMarker,
  isLoading
}) => {
  // const isDataAvailable = getIsDataAvailable();
  const [selectedOption, setSelectedOption] = useState("age");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  // const data = sampleVillageData?.electoralData;

  //graphData for age VS Gender
  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  // const graphData = getGraphData(selectedOption, sampleVillageData);

  const graphData = getGraphData(
    selectedOption,
    sampleVillageData,
    villageFetchedData
  );
  // const graphData = villageFetchedData;
  // const graphData = getGraphData(
  //   selectedOption,
  //   sampleVillageData,
  //   villageFetchedData
  // );

  return (
    <>
      <div className='constituency-data'>
      
        <h4 style={{ textAlign: "center", marginBottom: "5px" }}>
          Village Data
        </h4>

        <div className='w-full grid grid-cols-12 gap-4'>
          <div className='col-span-12 sm:col-span-7 relative'>
            <div className='village-data-tooltip absolute top-0 right-0 z-1'>
              {/* <h3>Village Result </h3> */}
              <div className='flex justify-between village-type'>
                <p
                  style={{
                    background: selectedMarker?.winnerParty?.colorCode,
                    marginRight: "5px"
                  }}
                >
                  {selectedMarker?.winnerParty?.name}
                </p>
                <p
                  style={{
                    background: selectedMarker?.runnerUpParty?.colorCode,
                    marginRight: "5px"
                  }}
                >
                  {selectedMarker?.runnerUpParty?.name}
                </p>
                <p
                  style={{
                    background: selectedMarker?.firstRunnerUpParty?.colorCode
                  }}
                >
                  {selectedMarker?.firstRunnerUpParty?.name}
                </p>
              </div>
              {/* <div className='flex flex-col justify-around tooltip-options mt-3'>
                  <div className='flex justify-between tooltip-content'>
                    <p>Strong Hold </p>
                    <div className='flex items-center'>
                      <FaChevronRight />
                      <p>10</p>
                      <span>Margin</span>
                    </div>
                  </div>
                  <div className='flex justify-between tooltip-content'>
                    <p>Battlefield </p>
                    <div className='flex items-center'>
                      <p>5-10 %</p>
                      <span>Margin</span>
                    </div>
                  </div>
                  <div className='flex justify-between tooltip-content'>
                    <p>Swing </p>
                    <div className='flex items-center'>
                      <FaChevronLeft />
                      <p>5 %</p>
                      <span>Margin</span>
                    </div>
                  </div>
                </div> */}
            </div>

            <AssemblyMap
              polygonData={polygonData}
              constituencyDataList={constituencyDataList}
              center={center}
              setSelectedMarker={setSelectedMarker}
              selectedMarker={selectedMarker}
              isDataAvailable={isDataAvailable}
            ></AssemblyMap>
          </div>

          <div className='col-span-12 sm:col-span-5 village-data'>
            <VillageDataTable
              isDataAvailable={isDataAvailable}
              data={
                villageFetchedData?.electoralData
                // ||sampleVillageData?.electoralData
              }
              villageFetchedData={villageFetchedData?.electoralData}
              selectedMarker={selectedMarker}
              villageLoading={isLoading}
            />

          <div className='constituenct-data-table  min-h-50 mt-4'>
            <div className='flex justify-end'>
              <h1 className='ml-4'>Village Electoral Data</h1>
              <div className='flex-grow' />
              <Select
                className='mr-3'
                placeholder='Select'
                value={selectedOption}
                onChange={handleSelectChange}
                disabled={!isDataAvailable}
              >
                {demographyOptions.map(({ value, label }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
              <img
                className='cursor-pointer'
                src={ExpandIcon}
                alt=''
                onClick={openModal}
              />
            </div>

            {renderCharts(
              selectedOption,
              isDataAvailable,
              graphData,
              isLoading
            )}
          </div>
          </div>


        </div>

      </div>

      <GraphModal
        isDataAvailable={isDataAvailable}
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        graphData={graphData}
        selectedOption={selectedOption}
      />
    </>
  );
};

export default VillageData;
