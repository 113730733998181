import React, { useState } from "react";
import { Select, Pagination } from "antd";
import "../../../styles/Gms.css";
import SearchFilter from "../../../components/SearchFilter";
import GmsClientDetails from "./GmsClientDetails";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { useQuery } from "react-query";
import GmsClientDataTable from "./GmsClientDataTable";

const GmsClientList = () => {
  const axiosInstance = useAxiosInstance();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line
  const [sort, setSort] = useState("DES");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [clientDetails, setClientDetails] = useState("");
  const [isModal, setIsModal] = useState(false);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const pagination = {
    current: currentPage,
    pageSize: limit,
    total: total * limit,
    onChange: handlePageChange
  };

  const getClientById = (clientId) => {
    axiosInstance
      .get(`api/clients/${clientId}`)
      .then((response) => {
        setClientDetails(response);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const ShowGmsEditModal = () => {
    setIsModal(!isModal);
  };

  const handleCancel = () => {
    setIsModal(false);
  };

  const fetchGmsData = async () => {
    try {
      let url;
      let response;
      if (searchKeyword) {
        url = `api/clients/SearchClientAdminByKeyword?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}`;
      } else {
        url = `api/clients/getAllClientAdminByType/GMS?page=${currentPage}&limit=${limit}&sort=${sort}`;
      }

      response = await axiosInstance.get(url);
      setTotal(response?.data?.totalpages);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  };

  const allGmsData = useQuery(
    ["gmsData", currentPage, limit, sort],
    () => fetchGmsData(currentPage, limit, sort),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  const gmsSearchData = useQuery(
    ["allGmsClients", searchKeyword, currentPage, limit],
    () => fetchGmsData(currentPage, limit, sort, searchKeyword),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  const SearchGmsClient = (value) => {
    setCurrentPage(1);
    setSearchKeyword(value);
  };

  const SearchGmsClientData = gmsSearchData?.data?.data?.data;

  return (
    <div>
      <div className='gms-page-section'>
        <div className='gms-table-wrapper'>
          <SearchFilter handleSearch={SearchGmsClient} />

          <GmsClientDataTable
            pagination={pagination}
            SearchGmsClientData={SearchGmsClientData}
            allGmsData={allGmsData}
            getClientById={getClientById}
            ShowGmsEditModal={ShowGmsEditModal}
          />

          <div className='report-pagination'>
            <div>
              {/* <span>Showing</span> */}
              <Select
                value={limit.toString()}
                style={{ width: 56 }}
                onChange={(value) => {
                  setLimit(parseInt(value));
                  setCurrentPage(1);
                }}
                options={[
                  { value: "10", label: "10" },
                  { value: "20", label: "20" },
                  { value: "50", label: "50" },
                  { value: "100", label: "100" }
                ]}
              />{" "}
              <span className='px-4'>Entries Per Pages</span>
              {/* <span className="px-4"> of 50</span> */}
            </div>
            <div className='report-selection'>
              <Pagination
                current={currentPage}
                pageSize={limit}
                total={total * limit}
                onChange={(page, pageSize) => {
                  setCurrentPage(page);
                  setLimit(pageSize);
                }}
                className='pagination'
              />
            </div>
          </div>
        </div>
      </div>

      {isModal && (
        <GmsClientDetails
          isModal={isModal}
          handleCancel={handleCancel}
          clientDetails={clientDetails}
        />
      )}
    </div>
  );
};

export default GmsClientList;
