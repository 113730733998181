import React, { useState } from "react";
import "../../styles/constituencyManagement.css";
import { ToastContainer } from "react-toastify";
// import SearchFilter from "../SearchFilter";
import { MdAdd } from "react-icons/md";
// import exportIcon from "../../assets/svg/exportFile.svg";
import ConstituencyTabs from "./ConstituencyTabs";
import ConstituencyExportForm from "./ConstituencyExportForm";
import { useContext } from "react";
import ConstituencyContext from "../../context/constituencyContext";
// import ConstituencyAddForm from "./ConstituencyAddForm";
import ConstituencySummary from "./ConstituencySummary";
import AddConstituency from "./AddConstituency";
import EditConstituency from "./EditConstituency";

const Result = () => {
  const constituencyContext = useContext(ConstituencyContext);
  const [showEdit, setShowEdit] = useState(false);
  // const handleExportModal = () => {
  //   constituencyContext.updateOpenExportModal(true);
  // };
  const handleAddModal = () => {
    constituencyContext.updateOpenModal(true);
  };
  // const handleEditModal = () => {
  //   setShowEdit(true);
  // };
  return (
    <div className='constituency-section'>
      <ToastContainer />



      <div className='pageTitle flex items-center justify-between pr-5'>
      <div className='heading-sec pl-4'>
        <h1 className='heading-text'>Constituency Management Dashboard</h1>
      </div>
        {/* <SearchFilter /> */}
        <div className='flex items-center'>
          {/* <button className='rounded-lg mr-4' onClick={handleExportModal}>
            <img src={exportIcon} alt='Export Icon' width={13} />
            Export Data
          </button> */}
          <div></div>

          <button className='rounded-lg mr-2' onClick={handleAddModal}>
            <MdAdd /> Add New Constituency
          </button>
          {/* <button className='rounded-lg mr-2' onClick={handleEditModal}>
            {" "}
            Edit Constituency{" "}
          </button> */}
        </div>
      </div>

      <ConstituencySummary />
      <ConstituencyExportForm
        isOpen={constituencyContext.isOpenExportModal}
        isExportMode={constituencyContext.isExportMode}
      />
      {/* <ConstituencyAddForm
        isOpen={constituencyContext.isOpenModal}
        isAddMode={constituencyContext.isAddMode}
      /> */}
      <AddConstituency
        isOpen={constituencyContext.isOpenModal}
        isAddMode={constituencyContext.isAddMode}
      />
      <EditConstituency
        isOpen={showEdit}
        setShowEdit={setShowEdit}
        isAddMode={constituencyContext.isAddMode}
      />

      <div className='constituency-map'>
        <ConstituencyTabs />
      </div>
    </div>
  );
};

export default Result;
