export const getIsDataAvailable = () => {
  const summaryUploadData = localStorage.getItem("summaryUploadData");
  const summaryData = JSON.parse(summaryUploadData);

  const checkAvailability = (key) => {
    return summaryData && ["YES", "Yes"].includes(summaryData[key]);
  };

  return (
    checkAvailability("Form 20") &&
    checkAvailability("Electoral Roll") &&
    checkAvailability("PS Village Mapping Data") &&
    checkAvailability("Village Data")
  );
};
