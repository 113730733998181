// import React, { useState } from 'react'
// import { Modal, Select, Row, Col } from 'antd';
// import AddYearConfirmation from './ConfirmationModal';
// import ConfirmationModal from './ConfirmationModal';

// const AddElectionYear = ({ isAddYearModalOpen, setIsAddYearModalOpen }) => {
//     const [isConfirmModal, setIsConfirmModal] = useState(false)
//     const closeModal = () => {
//         setIsAddYearModalOpen(false);
//     };
//     const openConfirmModal = () => {
//         setIsConfirmModal(true)
//         setIsAddYearModalOpen(false)

//     }
//     const handleCancel = () => {
//         setIsConfirmModal();
//         setIsAddYearModalOpen(true)
//     };
//     const handleConfirmation = () => {
//         setIsConfirmModal();
//     }
//     return (
//         <>
//             {isAddYearModalOpen && (
//                 <Modal className='add-user-modal' open={isAddYearModalOpen} onCancel={closeModal} width={1040}
//                     footer={[
//                         <button key="addYear" form="add-year" type='button' className='add-user-btn' onClick={openConfirmModal}>
//                             Save
//                         </button>
//                     ]}
//                 >
//                     <form id="add-year">
//                         <div className='user-info-modal'>
//                             <div className='add-user-modal-header'>
//                                 <h3>Add an Election Year</h3>
//                             </div>
//                         </div>
//                         <div className='add-user-modal-body'>
//                             <div className='model-wrapper'>

//                                 <Row className='add-user-modal-row'>

//                                     <Col span={8} className='mr-6'>
//                                         <label>Select Year</label>
//                                         <Select placeholder="Please Select" name='electionYear' id="electionYear" >
//                                             <Select.Option value="2008">2008</Select.Option>
//                                             <Select.Option value="2013">2013</Select.Option>
//                                             <Select.Option value="2018">2018</Select.Option>
//                                             <Select.Option value="2013">2023</Select.Option>
//                                         </Select>
//                                         {/* <ErrorMessage
//                                             hasError={Boolean(formik.errors.electionYear && formik.touched.electionYear)}
//                                             message={formik.errors.electionYear}
//                                         /> */}
//                                     </Col>
//                                 </Row>

//                             </div>
//                         </div>
//                     </form>
//                 </Modal>
//             )}
//             <ConfirmationModal
//                 modalHeading="Add an Election Year"
//                 modalText="Are you sure you want to add an election year?"
//                 isConfirmModal={isConfirmModal}
//                 setIsConfirmModal={setIsConfirmModal}
//                 handleCancel={handleCancel}
//                 handleConfirmation={handleConfirmation}
//             />
//         </>
//     )
// }

// export default AddElectionYear

import React, { useState } from "react";
import { Modal, Select, Row, Col } from "antd";

import ConfirmationModal from "./ConfirmationModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../../components/Forms/ErrorMessage";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { toast } from "react-toastify";

const AddElectionYear = ({
  setIsYearAdded,
  constituencyId,
  isAddYearModalOpen,
  setIsAddYearModalOpen
}) => {
  const axiosInstance = useAxiosInstance();
  const { OptGroup, Option } = Select;
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = 1951; year <= currentYear; year++) {
    years.push(year);
  }

  const FormSchema = Yup.object().shape({
    electionYear: Yup.string().trim().required("Year is required")
  });

  const formik = useFormik({
    initialValues: {
      electionYear: null
    },
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    }
  });

  const handleSubmit = (values, onSubmitProps) => {
    setIsConfirmModal(true);
    setIsAddYearModalOpen(false);
  };

  const closeModal = () => {
    setIsAddYearModalOpen(false);
    formik.resetForm();
  };

  const handleCancel = () => {
    setIsConfirmModal();
    setIsAddYearModalOpen(true);
  };

  const handleAddElectionYear = async () => {
    try {
      const requestBody = {
        year: formik.values.electionYear
      };

      await axiosInstance.put(
        `api/constituency/addElectionYear/${constituencyId}`,
        requestBody
      );
      setIsYearAdded(true);
      toast.success("Election year added successfully");
    } catch (error) {
      console.error(error);
      toast.error("Error adding election year");
    }
  };

  const handleConfirmation = () => {
    handleAddElectionYear();
    setIsConfirmModal();
  };
  return (
    <>
      {isAddYearModalOpen && (
        <Modal
          className='add-user-modal'
          open={isAddYearModalOpen}
          onCancel={closeModal}
          width={1040}
          footer={[
            <button
              key='addYear'
              form='add-year'
              type='submit'
              className='add-user-btn'
            >
              Save
            </button>
          ]}
        >
          <form id='add-year' onSubmit={formik.handleSubmit}>
            <div className='user-info-modal'>
              <div className='modal-title'>
                <h3>Add an Election Year</h3>
              </div>
            </div>
            <div className='modal-wrapper-body'>
              <div className='modal-wrapper-content'>
                <Row className='add-modal-row'>
                  <Col span={8} className='mr-6'>
                    <label>Select Year</label>
                    <Select
                      placeholder='Please Select'
                      name='electionYear'
                      id='electionYear'
                      value={formik.values.electionYear}
                      onChange={(value) =>
                        formik.setFieldValue("electionYear", value)
                      }
                    >
                      {years.map((year) => (
                        <Option key={year} value={year}>
                          {year}
                        </Option>
                      ))}
                    </Select>
                    <ErrorMessage
                      hasError={Boolean(
                        formik.errors.electionYear &&
                          formik.touched.electionYear
                      )}
                      message={formik.errors.electionYear}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </form>
        </Modal>
      )}
      <ConfirmationModal
        modalHeading='Add an Election Year'
        modalText='Are you sure you want to add an election year?'
        isConfirmModal={isConfirmModal}
        setIsConfirmModal={setIsConfirmModal}
        handleCancel={handleCancel}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
};

export default AddElectionYear;
