import React from 'react';
import {GmsContextProvider} from '../../context/gmsContext';
import Result from "../../components/GMS/Result";

const GMS = () => {
    return (
        <GmsContextProvider>
            <Result/>
        </GmsContextProvider>
    );
};

export default GMS;
