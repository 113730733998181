import React from "react";
import "../../../styles/overView.css";
import Stats from "./Stats";
import userGroup from "../../../assets/images/icons/userGroup.png";
import RevenueImg from "../../../assets/images/icons/Vector (35).png";
import Currency from "../../../assets/images/icons/currency.png";
// import { BiCaretUp } from "react-icons/bi";
import BillingPieChart from "./BillingPieChart";
import TotalRevenue from "./TotalRevenue";
import TopPayingClientList from "./TopPayingClientList";
import LeastPayingClientList from "./LeastPayingClientList";

import { useQuery } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";

const BillingOverview = () => {
  const axiosInstance = useAxiosInstance();
  const fetchWidgets = async () => {
    const response = await axiosInstance.get(`api/widget/admin/billing`);
    return response;
  };

  const widget = useQuery(["widget-list"], () => fetchWidgets(), {
    refetchOnWindowFocus: false,
    retry: 1
  });

  const widgetData = widget?.data?.data?.data || [];

  return (
    <>
      <div className='overview-container billing-container'>
        <div className='w-full grid grid-cols-12 gap-16 grid-parent overview-top px-7 pt-5'>
          <div className='col-span-12 sm:col-span-4'>
            <Stats
              icon={Currency}
              stat={widgetData?.totalIncome ? +widgetData?.totalIncome : "--"}
              title='Total Income '
              // changeIcon={<BiCaretUp />}Avg. Revenue Generated per Client
              // percent='--'
            />
          </div>
          <div className='col-span-12 sm:col-span-4'>
            <Stats
              icon={userGroup}
              stat={widgetData?.totalClients || "--"}
              title='Total no. of clients'
            />
          </div>
          <div className='col-span-12 sm:col-span-4'>
            <Stats
              icon={RevenueImg}
              stat={widgetData?.averageRevenueByClient || "--"}
              title='Avg. Revenue Generated per Client'
            />
          </div>
        </div>

        <div className='user-income-graph grid grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-6 mx-7'>
          <div className='col-span-3'>
            {/* <UserIncomeGraph /> */}
            <TotalRevenue totalRevenue={widgetData?.incomeGeneratedPerMonth} />
          </div>
          <div className='overview-user-section  col-span-1'>
            <div className='user-graph-stats billing-graph-stats flex flex-col'>
              <h1>Invoice Status</h1>
              <BillingPieChart totalStats={widgetData?.invoiceStats} />
            </div>
          </div>
        </div>

        <div className='w-full grid grid-cols-12 gap-4 grid-parent overview-top px-7 pb-5'>
          <div className='col-span-12 sm:col-span-6'>
            <TopPayingClientList
              topPayingClients={widgetData?.topPayingClients}
            />
          </div>
          <div className='col-span-12 sm:col-span-6'>
            <LeastPayingClientList
              leastPayingClients={widgetData?.leastPayingClients}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingOverview;
