import React, { useState } from "react";
import { Select, Pagination } from "antd";
import { useQuery } from "react-query";
import SearchFilter from "../../components/SearchFilter";
import TmsDataTable from "./TmsDataTable";
import useAxiosInstance from "../../lib/useAxiosInstance";

const TmsList = () => {
  const axiosInstance = useAxiosInstance();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line
  const [sort, setSort] = useState("DES");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [clientDetails, setClientDetails] = useState("");

  // GET TMS CLIENTS DATA BY ID
  const getClientById = (clientId) => {
    axiosInstance
      .get(`api/clients/${clientId}`)
      .then((response) => {
        setClientDetails(response);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const fetchAllTms = async () => {
    let response;
    if (searchKeyword) {
      response = await axiosInstance.get(
        `api/clients/SearchClientAdminByKeyword?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}`
      );
      setTotal(response?.data?.totalpages);
      return response;
    } else {
      response = await axiosInstance.get(
        `api/clients/getAllClientAdminByType/TMS?page=${currentPage}&limit=${limit}&sort=${sort}`,
        {}
      );
      setTotal(response?.data?.totalpages);
      return response;
    }
  };

  const tmsSearchData = useQuery(
    ["allTmsClients", searchKeyword, currentPage, limit],
    () => fetchAllTms(),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );
  const allTmsData = useQuery(
    ["tmsData", currentPage, limit, sort],
    () => fetchAllTms(),
    {
      refetchOnWindowFocus: false,
      retry: 1
    }
  );

  // GET ALL SEARCH TMS CLIENTS DATA
  const SearchTmsClient = (value) => {
    setCurrentPage(1);
    setSearchKeyword(value);
  };

  // FOR PAGINATION
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const pagination = {
    total: total * limit,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange
  };
  const isLoading = allTmsData?.isLoading || tmsSearchData?.isLoading;
  return (
    <div className='table-list'>
      <SearchFilter handleSearch={SearchTmsClient} />

      <TmsDataTable
        allTmsData={allTmsData?.data?.data?.data}
        tmsSearchData={tmsSearchData?.data}
        getClientById={getClientById}
        clientDetails={clientDetails}
        pagination={pagination}
        isLoading={isLoading}
      />

      <div className='report-pagination '>
        <div>
          <Select
            value={limit.toString()}
            style={{ width: 56 }}
            onChange={(value) => {
              setLimit(parseInt(value));
              setCurrentPage(1);
            }}
            options={[
              { value: "10", label: "10" },
              { value: "20", label: "20" },
              { value: "50", label: "50" },
              { value: "100", label: "100" }
            ]}
          />
          <span className='pl-2'> Entries per pages</span>
        </div>

        <div className='report-selection'>
          <Pagination
            className='pagination'
            current={currentPage}
            pageSize={limit}
            total={total * limit}
            onChange={(page, pageSize) => {
              setCurrentPage(page);
              setLimit(pageSize);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TmsList;
