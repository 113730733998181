import React, { useState } from "react";
import { Modal, Input, Row, Col, Form, Upload } from "antd";

import { useQueryClient } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { FileFilled } from "@ant-design/icons";
import { AiOutlineClose } from "react-icons/ai";
import InvoiceConfirmationModal from "./InvoiceConfirmationModal";
import { toast } from "react-toastify";

const EditInvoiceForm = ({ editInvoice, setEditInvoice, selectedRecord }) => {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();

  const [fileList, setFileList] = useState([]);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const handleResetFile = () => {
    setFileList([]);
  };

  const handleEditSubmit = async (values) => {
    const formData = new FormData();
    formData.append("invoiceNumber", values?.invoice_number || "");
    formData.append("invoiceDate", values?.invoice_date || "");
    formData.append("invoiceAmount", values?.amt || "");
    formData.append("invoiceDocument", fileList[0]?.originFileObj || null); // Assuming only one file is allowed

    try {
      const response = await axiosInstance.put(
        `/api/invoice/${selectedRecord?._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );

      // Reset file after successful API call
      handleResetFile();

      // setIsConfirmModal(true);
      setEditInvoice(false);

      toast.success("Invoice Updated Successfully");
      queryClient.invalidateQueries("invoice-list");
    } catch (error) {
      toast.error("Failed to update invoice. Please try again.");
    }
  };

  const closeModal = () => {
    handleResetFile();
    setEditInvoice(false);
  };
  const handleConfirmation = () => {
    setIsConfirmModal();
  };
  const handleCancel = () => {
    setIsConfirmModal();
    setEditInvoice(true);
  };

  function convertDateFormat(dateString) {
    // Split the date string into day, month, and year
    const parts = dateString.split("/");
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    // Create a new Date object with the provided date components
    const dateObject = new Date(`${year}-${month}-${day}`);

    // Get the ISO date string in the format YYYY-MM-DD
    const isoDateString = dateObject.toISOString().split("T")[0];

    return isoDateString;
  }

  return (
    <>
      {editInvoice && (
        <Modal
          className='add-user-modal'
          open={editInvoice}
          onCancel={closeModal}
          width={1040}
          maskClosable={false}
          footer={[
            <button
              key='add-user'
              form='edit-invoice-form'
              type='submit'
              className='gms-modal-button'
            >
              Save Changes
            </button>
          ]}
        >
          <Form
            id='edit-invoice-form'
            onFinish={handleEditSubmit}
            initialValues={{
              invoice_number: selectedRecord?.invoiceNumber || "",
              invoice_date:
                convertDateFormat(selectedRecord?.invoice_date) || "",
              amt: selectedRecord?.invoiceAmount || ""
            }}
          >
            <div className='user-info-modal'>
              <div className='modal-title'>
                <h3>Edit Invoice</h3>
              </div>
              <div className='modal-wrapper-body'>
                <div className='modal-wrapper-content'>
                  <h4>Invoice Details</h4>

                  <Row className='add-modal-row'>
                    <Col span={8} className='mr-6'>
                      <label htmlFor=''>Invoice Number</label>
                      <Form.Item
                        name='invoice_number'
                        rules={[
                          {
                            required: true,
                            message: "Invoice number is required."
                          }
                        ]}
                      >
                        <Input
                          type='text'
                          name='invoice_number'
                          // onChange={(value) => handleChange(value, "invoice_number")}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <label htmlFor=''>Invoice Date</label>
                      <Form.Item
                        name='invoice_date'
                        rules={[
                          {
                            required: true,
                            message: "Invoice date is required."
                          }
                        ]}
                      >
                        <Input type='date' name='invoice_date' />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className='add-modal-row'>
                    <Col span={8} className='mr-6'>
                      <label htmlFor=''>Amount</label>
                      <Form.Item
                        name='amt'
                        rules={[
                          {
                            required: true,
                            message: "Amount is required."
                          }
                        ]}
                      >
                        <Input
                          type='number'
                          placeholder='₹'
                          name='amt'
                          // onChange={(value) => handleChange(value, "iamt")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <h4>Upload Invoice</h4>
                  <Row className='add-modal-row mb-2'>
                    <Col span={22}>
                      <Form.Item
                        name='fileLocation'
                        rules={[
                          {
                            required: true,
                            message: "Please upload the invoice file"
                          }
                        ]}
                      >
                        <div className='report-upload-file-info'>
                          <div className='mt-3'>
                            <Upload.Dragger
                              key='select-file'
                              name='fileLocation'
                              beforeUpload={() => false}
                              fileList={fileList}
                              onChange={(info) => {
                                const { file, fileList: newFileList } = info;
                                // Update fileList state
                                setFileList(newFileList);
                              }}
                              className='custom-file-upload'
                              accept=''
                            >
                              {/* <div className='fileUpload'> */}
                              {fileList.length > 0 ? (
                                <>
                                  <FileFilled />
                                  {fileList[0].name}
                                  <div className='text-right'>
                                    <button
                                      onClick={() => setFileList([])}
                                      className='reset-button font-bold'
                                    >
                                      <AiOutlineClose />
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <span className='flex justify-center file-icon'>
                                    <svg
                                      width='22'
                                      height='16'
                                      viewBox='0 0 22 16'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        d='M10 16H5.5C3.98 16 2.68333 15.4767 1.61 14.43C0.536667 13.3767 0 12.0933 0 10.58C0 9.28 0.39 8.12 1.17 7.1C1.95667 6.08 2.98333 5.43 4.25 5.15C4.67 3.61667 5.50333 2.37667 6.75 1.43C8.00333 0.476667 9.42 0 11 0C12.9533 0 14.6067 0.68 15.96 2.04C17.32 3.39333 18 5.04667 18 7C19.1533 7.13333 20.1067 7.63333 20.86 8.5C21.62 9.35333 22 10.3533 22 11.5C22 12.7533 21.5633 13.8167 20.69 14.69C19.8167 15.5633 18.7533 16 17.5 16H12V8.85L13.6 10.4L15 9L11 5L7 9L8.4 10.4L10 8.85V16Z'
                                        fill='#336D92'
                                      />
                                    </svg>
                                  </span>
                                  <p className='ant-upload-text'>
                                    Click to upload or Drag and drop your files.
                                  </p>
                                  <p className='ant-upload-text'>
                                    (Maximum file size is 50MB)
                                  </p>
                                  <div className='fileUpload'></div>
                                </div>
                              )}
                              {/* </div> */}
                            </Upload.Dragger>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  <p>Uploaded Invoice</p>
                  <div className='flex mt-4 gap-2'>
                    <FileFilled className='mt-1' />
                    <div>
                      <a
                        href={selectedRecord?.invoiceFile?.publicUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='download-link'
                      >
                        {selectedRecord?.invoiceFile?.name}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      )}
      <InvoiceConfirmationModal
        modalHeading='Upload Invoice'
        modalText='Are you sure you want to upload this invoice?'
        isConfirmModal={isConfirmModal}
        setIsConfirmModal={setIsConfirmModal}
        handleCancel={handleCancel}
        handleConfirmation={handleConfirmation}
      />
    </>
  );
};

export default EditInvoiceForm;
