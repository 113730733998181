import React from "react";
import { Table } from "antd";

const LeastPayingClientList = ({ leastPayingClients = [] }) => {
  const data = leastPayingClients.map((client) => {
    return {
      key: client?._id || "",
      name:
        (client?.clientDetail[0]?.adminFirstName || "") +
        " " +
        (client?.clientDetail[0]?.adminLastName || ""),
      subscription: (client?.clientDetail[0]?.planType || [])
        .map((sub) => sub.name)
        .join(", "),
      date:
        new Date(client?.clientDetail[0]?.createdAt).toLocaleDateString() || "",
      total_sales: "₹" + client?.totalAmountPaid || "-"
    };
  });
  const columns = [
    {
      title: "Client Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <h2>{text}</h2>
    },

    {
      title: "Plans",
      dataIndex: "subscription",
      key: "subscription"
    },

    {
      title: "Client Since",
      dataIndex: "date",
      key: "date"
    },

    {
      title: "Total REVENUE",
      dataIndex: "total_sales",
      key: "total_sales"
    }
  ];

  return (
    <div className='paying-table-wrapper'>
      <h1>Least Paying Clients</h1>
      <div>
        <Table columns={columns} dataSource={data} />
      </div>
    </div>
  );
};

export default LeastPayingClientList;
