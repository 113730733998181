import React, { useContext, useState } from "react";
import {Modal,Input,Space,Row,Col,Select,Upload,Spin,Tag,Progress,Button,Radio} from "antd";
import { FileFilled, DownloadOutlined } from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { toast } from "react-toastify";
import { AiOutlineClose} from "react-icons/ai";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../../../components/Forms/ErrorMessage";
import ReportContext from "../../../context/reportContext";

const { TextArea } = Input;

const ReportUploadForm = ({ isOpen, isAddMode }) => {
  const reportContext = useContext(ReportContext);

  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();

  const [tag, setTag] = useState("");
  const [fileError, setFileError] = useState({ isError: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showCompletedProgress, setShowCompletedProgress] = useState(false);

  // GET SUBSCRIBER LIST DATA
  const fetchSubscribers = async () => {
    try {
      let allSubscribers = [];
      let currentPage = 1;
      let sort = "DES";

      while (true) {
        const response = await axiosInstance.get(
          `api/clients/filter?page=${currentPage}&sort=${sort}`
        );
        const subscriberData = response.data.data;
        const activeClients = subscriberData.filter(
          (client) => client.status === "active"
        );
        allSubscribers = [...allSubscribers, ...activeClients];

        if (currentPage >= response.data.totalpages) {
          break;
        }

        currentPage++;
      }
      return allSubscribers;
    } catch (error) {
      console.error("An error occurred while fetching subscriber data.", error);
      throw error;
    }
  };

  const reportSubscribersList = useQuery("Subscribers-List", fetchSubscribers, {
    refetchOnWindowFocus: false,
    retry: 1
  });

  const FormSchema = Yup.object().shape({
    reportName: Yup.string()
      .trim()
      .required("Report Name is required")
      .min(3, "Report Name is minimum 3 character")
      .max(200, "report Name is maximum 200 character"),
    reportDescription: Yup.string()
      .trim()
      .required("Report Description is required")
      .min(3, "Report Description is minimum 3 character")
      .max(200, "report Description is maximum 200 character"),
    // subscribers: Yup.array().min(1, 'Subscriber is required'),
    resourceLocation: Yup.array().min(1, "File is required"),
    type: Yup.string().required("Type is required")
  });

  const formik = useFormik({
    initialValues: {
      reportName: isAddMode ? "" : reportContext.editData.reportName,
      reportDescription: isAddMode
        ? ""
        : reportContext.editData.reportDescription,
      subscribers: isAddMode ? [] : reportContext.editData.subscribers,
      resourceLocation: isAddMode
        ? []
        : reportContext.editData.resourceLocation,
      tags: isAddMode
        ? []
        : reportContext.editData.tags.length > 0
          ? reportContext.editData.tags[0].split(",")
          : [],
      type: isAddMode ? "" : reportContext.editData.type
    },
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    }
  });

  const handleSubmit = async (value) => {
    const data = {
      ...value,
      tags: value.tags.join(",") ? value.tags.join(",") : []
    };

    if (isAddMode) {
      handleCreateReport.mutate(data);
    } else {
      handleUpdateReport.mutate(data);
    }
  };

  const handleCreateReport = useMutation(
    (formData) =>
      axiosInstance
        .post(`api/reports`, formData)
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess(data);
      },
      onError: (error) => {
        handleError(error);
      }
    }
  );

  const handleUpdateReport = useMutation(
    (formData) =>
      axiosInstance
        .put(`api/reports/${reportContext.editData._id}`, formData)
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess(data);
      },
      onError: (error) => {
        handleError(error);
      }
    }
  );

  const handleSuccess = (data) => {
    queryClient.invalidateQueries("report-list");
    queryClient.invalidateQueries("allSearchReports");
    closeModal();
    toast.success(`${data?.message}`);
    setShowCompletedProgress(false);
  };

  const handleError = (error) => {
    toast.error("Something went wrong.");
    closeModal();
  };

  // FILE UPLOAD
  const handleFileChange = async (info) => {
    const file = info.file;
    const supportedFormats = [".jpg", ".jpeg", ".png", ".pdf", ".doc", ".xls"];

    const isSupportedFormat = supportedFormats.some((format) =>
      file.name.toLowerCase().endsWith(format)
    );

    if (!isSupportedFormat) {
      setFileError({
        isError: true,
        message:
          "Unsupported file format. Please upload a file with a valid format"
      });
    } else {
      const onProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentCompleted);
      };

      try {
        const fileURL = await handleFileSelection(file, onProgress);
        if (fileURL) {
          formik.setFieldValue("resourceLocation", [fileURL]);
          setFileError({ isError: false, message: "" });
          setShowCompletedProgress(true);
        } else {
          setFileError({ isError: true, message: "Failed to upload the file" });
        }
      } catch (error) {
        setFileError({ isError: true, message: "Failed to upload the file" });
      } finally {
        setLoading(false);
        setUploadProgress(0);
      }
    }
  };

  const handleFileSelection = async (resourceLocation, onProgress) => {
    const formData = new FormData();
    formData.append("reportLink", resourceLocation);
    try {
      const response = await axiosInstance.post(
        "api/reports/uploadReportFile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: onProgress
        }
      );

      return response.data.data;
    } catch (error) {
      return null;
    }
  };

  const handleResetFile = () => {
    formik.setFieldValue("resourceLocation", []);
    setUploadProgress(0);
  };

  // CHANGE SUBSCRIBER
  const handleSubscriberChange = (selectedId, formik) => {
    const result = reportSubscribersList?.data?.filter((row) =>
      selectedId.includes(row._id)
    );

    let list = result.map((row) => ({
      _id: row._id,
      firstName: row.adminFirstName,
      lastName: row.adminLastName
    }));

    formik.setFieldValue("subscribers", list);
  };

  // CHANGE TAG

  const handleAddTag = (formik, e) => {
    e.preventDefault();
    if (tag.trim() !== "") {
      let oldList = [...formik.values.tags, tag.trim()];
      formik.setFieldValue("tags", oldList);
      setTag("");
    }
  };

  const handleRemoveTag = (tag, list, formik) => {
    let newList = list.filter((item) => item !== tag);
    formik.setFieldValue("tags", newList);
  };

  const closeModal = () => {
    reportContext.updateOpenModal(false);
    reportContext.updateAddMode(true);
    formik.resetForm();
    setTag("");
    setShowCompletedProgress(false);
  };

  const handleStatusModal = () => {
    reportContext.updateOpenModal(false);
    reportContext.updateStatusModal(true);
  };
  const handleDownload = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl[0]?.publicUrl;
    link.download = fileUrl[0]?.name || "Uploaded Report";
    document.body.appendChild(link);
    link.click();


    document.body.removeChild(link);
  };

  return (
    <>
      {isOpen && (
        <Modal
          className='share-report-modal edit-reports'
          open={isOpen}
          onCancel={closeModal}
          width={1040}
          maskClosable={false}
          footer={
            isAddMode
              ? [
                <button
                  key='upload-report'
                  form='report-form'
                  type='submit'
                  className='report-upload-modal-button'
                >
                  Upload Report
                </button>
              ]
              : null
          }
        >
          <form id='report-form' onSubmit={formik.handleSubmit} className="modal-body-container">
            <div className='modal-title'>
              <h3>{isAddMode ? "Upload Report" : "Edit Report"}</h3>
            </div>

            <div className='modal-wrapper-body'>
              <div className='modal-wrapper-content'>
                {isAddMode ? (
                  <>
                    <h4>Upload File</h4>
                    <p className='pb-4'>
                      Add your relevant report documents here. (Supported
                      formats are JPEG, PNG, PDF, DOC, XLS)
                    </p>
                  </>
                ) : (
                  <h4>View Documents</h4>
                )}
                <div className='file-upload'>
                  <div className='mt-3 report-upload-file-info'>
                    {formik.values.resourceLocation.length === 0 ? (
                      <Upload.Dragger
                        key='select-file'
                        className='custom-file-upload'
                        name='resourceLocation'
                        accept='.jpg,.jpeg,.png,.pdf,.doc,.xls'
                        beforeUpload={() => false}
                        onChange={handleFileChange}
                      >
                        {loading ? (
                          <Spin />
                        ) : (
                          <>
                            {uploadProgress > 0 && (
                              <div className='p-4'>
                                <Progress
                                  percent={uploadProgress}
                                  status='active'
                                />
                                <span className='text-center font-bold'>
                                  Uploading File
                                </span>
                                {formik.values.resourceLocation.length > 0 && (
                                  <div className='fileUpload'>
                                    <FileFilled />
                                    <p>
                                      {formik.values.resourceLocation[0].name}
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}

                            {uploadProgress === 0 && (
                              <>
                                <span className='flex justify-center file-icon'>
                                  <svg width='22' height='16' viewBox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path d='M10 16H5.5C3.98 16 2.68333 15.4767 1.61 14.43C0.536667 13.3767 0 12.0933 0 10.58C0 9.28 0.39 8.12 1.17 7.1C1.95667 6.08 2.98333 5.43 4.25 5.15C4.67 3.61667 5.50333 2.37667 6.75 1.43C8.00333 0.476667 9.42 0 11 0C12.9533 0 14.6067 0.68 15.96 2.04C17.32 3.39333 18 5.04667 18 7C19.1533 7.13333 20.1067 7.63333 20.86 8.5C21.62 9.35333 22 10.3533 22 11.5C22 12.7533 21.5633 13.8167 20.69 14.69C19.8167 15.5633 18.7533 16 17.5 16H12V8.85L13.6 10.4L15 9L11 5L7 9L8.4 10.4L10 8.85V16Z' fill='#336D92' />
                                  </svg>
                                </span>
                                <p className='ant-upload-text'>
                                  Click to upload or drag and drop your files.
                                  <br /> (Maximum file size is 50MB)
                                </p>
                              </>
                            )}
                          </>
                        )}
                      </Upload.Dragger>
                    ) : (
                      <div className='fileUpload'>
                        <div className='text-right'>
                          <button
                            onClick={handleResetFile}
                            className='reset-button font-bold'
                          >
                            <AiOutlineClose />
                          </button>
                        </div>
                        <div className='flex file-icon justify-between'>
                          <div className='flex items-center'>
                            <FileFilled />
                            <p className="pl-1">{formik.values.resourceLocation[0].name}</p>
                          </div>
                          <Button
                            type='link'
                            icon={<DownloadOutlined />}
                            onClick={() =>
                              handleDownload(formik.values.resourceLocation)
                            }
                          // style={{ zIndex: 1 }}
                          >
                            Download
                          </Button>
                        </div>

                        {/* <div className='flex file-icon'>
                                  <FileFilled />
                                  <p>
                                    {formik.values.resourceLocation[0].name}
                                  </p>
                                </div> */}

                        {showCompletedProgress ? (
                          <div className=''>
                            <span className='text-right mr-8 font-bold'>
                              100%
                            </span>
                            <Progress percent={100} status='completed' />
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>

                  {fileError.isError ? (
                    <ErrorMessage
                      hasError={Boolean(fileError.isError)}
                      message={fileError.message}
                    />
                  ) : (
                    <ErrorMessage
                      hasError={Boolean(
                        formik.errors.resourceLocation &&
                        formik.touched.resourceLocation
                      )}
                      message={formik.errors.resourceLocation}
                    />
                  )}
                </div>

                <h4 className='py-4'>Report Details</h4>

                <Row className='add-modal-row'>
                  <Col span={20}>
                    <label>Report Name</label>
                    <Input
                      type='text'
                      placeholder='Type Report Name'
                      name='reportName'
                      onChange={formik.handleChange}
                      value={formik.values.reportName}
                      showCount
                      maxLength={200}
                    />
                    <ErrorMessage
                      hasError={Boolean(
                        formik.errors.reportName && formik.touched.reportName
                      )}
                      message={formik.errors.reportName}
                    />
                  </Col>
                </Row>

                <Row className='add-modal-row my-7'>
                  <Col span={20}>
                    <label>Report Description</label>
                    <TextArea
                      className='pt-3'
                      rows={2}
                      placeholder='Write Report Description'
                      name='reportDescription'
                      onChange={formik.handleChange}
                      value={formik.values.reportDescription}
                      showCount
                      maxLength={200}
                    />
                    <ErrorMessage
                      hasError={Boolean(
                        formik.errors.reportDescription &&
                        formik.touched.reportDescription
                      )}
                      message={formik.errors.reportDescription}
                    />
                  </Col>
                </Row>

                <Row className='add-modal-row my-7'>
                  <Col span={20}>
                    <label>Type</label>
                    <Radio.Group
                      name='type'
                      onChange={(event) =>
                        formik.setFieldValue("type", event.target.value)
                      }
                      value={formik.values.type}
                    >
                      <Radio.Button
                        value='free'
                        style={{
                          backgroundColor: "rgba(140, 209, 171, 0.50)",
                          color: "#27AE60"
                        }}
                      >
                        FREE
                      </Radio.Button>
                      <Radio.Button
                        value='premium'
                        style={{
                          backgroundColor: "#8ad4ff53",
                          color: "#0066ff",
                          marginLeft: "24px"
                        }}
                      >
                        PREMIUM
                      </Radio.Button>
                    </Radio.Group>
                    <ErrorMessage
                      hasError={Boolean(
                        formik.errors.type && formik.touched.type
                      )}
                      message={formik.errors.type}
                    />
                  </Col>
                </Row>
                <Row className='add-modal-row pb-8'>
                  <Col span={24} className='edit-subscribers'>
                    <label>Subscribers</label>
                    <Select
                      className='custom-dropdown'
                      mode='multiple'
                      style={{ width: "100%" }}
                      placeholder='Select subscribers'
                      name='subscribers'
                      showSearch
                      value={formik.values.subscribers?.map(
                        (subscriber) => subscriber._id
                      )}
                      onChange={(value) =>
                        handleSubscriberChange(value, formik)
                      }
                      filterOption={(input, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    >
                      {reportSubscribersList?.data?.map((subscriber) => (
                        <Select.Option
                          key={subscriber._id}
                          value={subscriber._id}
                          className='capitalize'
                        >
                          {`${subscriber.adminFirstName} ${subscriber.adminLastName}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                {isAddMode && (
                  <Row className='add-modal-row pb-8'>
                    <Col span={24} className='add-tags'>
                      <label>Tags</label>
                      <Space size={[0, 8]} wrap>
                        {formik.values.tags.map((tag, index) => (
                          <Tag
                            key={index}
                            closable
                            onClose={() =>
                              handleRemoveTag(tag, formik.values.tags, formik)
                            }
                            value={tag}
                          >
                            {tag}
                          </Tag>
                        ))}
                      </Space>
                      <Input
                        className='add-subscibers w-auto'
                        style={{ display: "inline" }}
                        name='tags'
                        value={tag}
                        onChange={(e) => setTag(e.target.value)}
                        onPressEnter={(e) => handleAddTag(formik, e)}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </div>

            {!isAddMode && (
              <div className='edit-footer'>
                <Button className='delete-btn' onClick={handleStatusModal}>
                  Deactivate Report
                </Button>
                <button type='submit' className='report-upload-modal-button'>
                  Save Changes
                </button>
              </div>
            )}
          </form>
        </Modal>
      )}
    </>
  );
};

export default ReportUploadForm;
