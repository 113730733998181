import React from "react";
import { ResponsiveBar } from "@nivo/bar";

import NoDataIcon from "../../../../assets/svg/no-data.svg";

// const customGenColors = {
//   Female: "#3BBEE8",
//   Male: "#004877",
//   Others: "#3BBEE8"
// };

const customGenColors = {
  FEMALE: "#3BBEE8",
  MALE: "#004877",
  OTHERS: "#3BBEE8"
};

const AgeVsGenderChart = ({ isDataAvailable, graphData }) => {
  const theme = {
    axis: {
      ticks: {
        text: {
          fontSize: 10,
          fill: `var(--stat-text)`,
          fontWeight: 400
        }
      },
      legend: {
        text: {
          fontSize: 12,
          fill: `var(--stat-text)`,
          fontWeight: 400
        }
      }
    }
  };
  return (
    <>
      {" "}
      <div className='revenue-map-chart' style={{ height: "300px" }}>
        {isDataAvailable ? (
          <>
            {" "}
            <div className='flex justify-between'>
              <div className='ml-4 mt-4 flex items-center legend'>
                {/* ["Female", "Male", "Others"] */}
                {["FEMALE", "MALE", "OTHERS"].map((legend) => (
                  <div
                    key={legend}
                    className='mr-2 flex items-center'
                    style={{ color: customGenColors[legend] }}
                  >
                    <div
                      className='w-3 h-3  mr-1'
                      style={{ backgroundColor: customGenColors[legend] }}
                    ></div>
                    <span>{legend}</span>
                  </div>
                ))}
              </div>
            </div>
            <ResponsiveBar
              data={graphData}
              // keys={["Female", "Male", "Others"]}
              keys={["FEMALE", "MALE", "OTHERS"]}
              indexBy='age'
              margin={{ top: 10, right: 5, bottom: 40, left: 80 }}
              padding={0.3}
              maxValue={100}
              layout='horizontal'
              valueScale={{ type: "linear" }}
              colors={(e) => customGenColors[e.id]}
              borderColor={{
                from: "color",
                modifiers: [["darker", "1.6"]]
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 0,
                tickPadding: 0,
                tickRotation: 0,
                legend: "Age Percentage",
                legendPosition: "middle",
                legendOffset: 30,
                format: (value) => `${value}%`
              }}
              axisLeft={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Age",
                legendPosition: "middle",
                legendOffset: -70
              }}
              theme={theme}
              enableGridY={false}
              enableLabel={false}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]]
              }}
              legends={[]}
              role='application'
              barAriaLabel={(e) =>
                e.id + ": " + e.formattedValue + " in age: " + e.indexValue
              }
              tooltip={({ id, value }) => <strong>{`${id}: ${value}`}</strong>}
            />
          </>
        ) : (
          <div className='no-data-icon'>
            <img src={NoDataIcon} alt=''></img>
            <span>No Data Available</span>
          </div>
        )}
      </div>
    </>
  );
};

export default AgeVsGenderChart;
