import React, {useContext} from "react";
import '../../styles/Report.css';
import {ToastContainer} from "react-toastify";
import {MdUpload} from 'react-icons/md';
import SurveyContext from '../../context/surveyContext';
import PageHeading from "../PageHeading";
import SurveyUploadForm from "./SurveyDatabase/SurveyUploadForm";
import SurveyTabs from "./SurveyTabs";

const Result = () => {

    const surveyContext = useContext(SurveyContext);

    const handleModal = () => {
        surveyContext.updateOpenModal(true);
    };

    return (
        <div className="gms-page-section report-tabs">
            <ToastContainer/>

            <div className="gms-wrapper">
                <PageHeading
                    pageTitle='Surveys'
                    icon={<MdUpload />}
                    pageModalTitle='Upload New Survey'
                    pageModalOpen={handleModal}
                />

                <SurveyUploadForm isOpen={surveyContext.isOpenModal} isAddMode={surveyContext.isAddMode}/>

                <SurveyTabs/>
            </div>
        </div>
    );
};

export default Result;
