import React from 'react'
import { ToastContainer } from 'react-toastify'
import CustSupportList from './CustSupportList'
import CustSupportForm from './CustSupportForm'
import CustSupportContext from '../../context/custSupportContext'
import { useContext } from 'react'

const Result = () => {
    const custSupportContext = useContext(CustSupportContext);
    return (
        <div className='cust-supprt-section'>
            <ToastContainer />
            <div className='heading-sec'>
                <h1 className='heading-text'>Customer Support</h1>
            </div>
            <CustSupportForm isOpen={custSupportContext.isOpenModal}/>
            <CustSupportList />
        </div>
    )
}

export default Result