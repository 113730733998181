import React, { useContext, useState, useRef, useEffect } from "react";
import Column from "antd/es/table/Column";
import { AppContext } from "../../context/AppContextProvider";
import SearchFilter from "../SearchFilter";
import Edit from "./Edit";
import IndividualUpload from "./IndividualUpload";
import DeleteModal from "../DeleteModal";
import { Pagination, Select, Space, Table } from "antd";
import BulKUpload from "./BulkUpload";

const data = [
  {
    key:"#1223",
    _id: "#1223",
    main_community: "Hindu",
    sub_community: "--",
    community_group: "SINGHAL",
  },
  {
    key:"#1223",
    _id: "#1224",
    main_community: "Muslim",
    sub_community: "--",
    community_group: "ANSARI",
  },
];

const CommunityDataTable = () => {
  const { handleClick, handleMouseEnter, handleMouseLeave, isDropdownOpen } =
    useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const [isModalUpload, setIsModalUpload] = useState(false);

  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);

  // For DELETE USER
  const [isDeleteModal, setDeleteModal] = useState(false);
  // eslint-disable-next-line
  const [selectedRecord, setSelectedRecord] = useState(null);
  const showModalDelete = (record) => {
    setSelectedRecord(record);
    setDeleteModal(!isDeleteModal);
  };

  // edit modal
  const showEditModal = () => {
    setIsModal(!isModal);
  };

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  // bulk upload

  function showBulkUpload() {
    setIsModalUpload(!isModalUpload);
  }

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModalUpload(false);
    setIsModal(false);
  };

  // dropdown1

  const dropdownRef = useRef(null);
  const toggleDropdown1 = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
  };

  const closeDropdown = () => {
    setIsDropdownOpen1(false);
  };
  useEffect(() => {
    const closeDropdownOnClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("click", closeDropdownOnClickOutside);
    return () => {
      document.removeEventListener("click", closeDropdownOnClickOutside);
    };
  }, []);

  
  return (
    <>
      <div className="community-page">
        <div className="community-header flex justify-between">
          <h1 class="heading-text">Community Dictionary</h1>
          <div>
            <button className="expt-btn">Export as CSV</button>
            <div className="dropdown1">
              <button
                className="add-dict-btn"
                onClick={toggleDropdown1}
                onBlur={closeDropdown}
                key="#123"
                ref={dropdownRef}
              >
                Add New Dictionary
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                >
                  <path
                    d="M1.7125 0.999531L5.5925 4.87953L9.4725 0.999531C9.8625 0.609531 10.4925 0.609531 10.8825 0.999531C11.2725 1.38953 11.2725 2.01953 10.8825 2.40953L6.2925 6.99953C5.9025 7.38953 5.2725 7.38953 4.8825 6.99953L0.2925 2.40953C-0.0975 2.01953 -0.0975 1.38953 0.2925 0.999531C0.6825 0.619531 1.3225 0.609531 1.7125 0.999531Z"
                    fill="white"
                  />
                </svg>
                {isDropdownOpen1 && (
                  <div className="dropdown-content1">
                    {/* Your dropdown items go here */}
                    <div
                      className="uplpad-dict"
                      onClick={() => {
                        showBulkUpload();
                      }}
                      key="#123"
                    >
                      {" "}
                      Bulk Upload{" "}
                    </div>

                    <div
                      className="uplpad-dict"
                      onClick={() => {
                        showModal();
                      }}
                      key="#124"
                    >
                      Add an Individual Dictionary{" "}
                    </div>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="table-list">
          <SearchFilter />
          <div className="reports-table">
            <Table
              rowKey={(record) => record._id}
              dataSource={data}
              className="report-tableRows"
              //   pagination={pagination}
            >
              <Column
                title="COM.ID"
                dataIndex="_id"
                // render={(text, record) => (
                //   <>

                //   </>
                // )}
              />
              <Column title="MAIN COMMUNITY" dataIndex="main_community" />
              <Column title="SUB COMMUNITY" dataIndex="sub_community" />
              <Column title="Group" dataIndex="community_group" />

              <Column
                title="ACTION"
                key="action"
                render={(text, record, index) => (
                  <Space className="select-option" size="small" style={{}}>
                    <div
                      className="dropdown"
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <span onClick={() => handleClick(index)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="34"
                          height="30"
                          viewBox="0 0 34 30"
                          fill="none"
                        >
                          <circle
                            cx="17.3889"
                            cy="9.38845"
                            r="1.38889"
                            transform="rotate(-90 17.3889 9.38845)"
                            fill="#004877"
                          />
                          <circle
                            cx="17.3889"
                            cy="15.4998"
                            r="1.38889"
                            transform="rotate(-90 17.3889 15.4998)"
                            fill="#004877"
                          />
                          <circle
                            cx="17.3889"
                            cy="21.6111"
                            r="1.38889"
                            transform="rotate(-90 17.3889 21.6111)"
                            fill="#004877"
                          />
                        </svg>
                      </span>
                      {isDropdownOpen(index) && (
                        <div className="dropdown-content">
                          <div
                            key={`edit-${index}`}
                            onClick={() => {
                              //   handleEditSubmit(record._id);
                              showEditModal(record);
                            }}
                          >
                            Edit
                          </div>
                          <div
                            key={`delete-${index}`}
                            onClick={() => showModalDelete(record)}
                          >
                            Delete
                          </div>
                        </div>
                      )}
                    </div>
                  </Space>
                )}
              />
            </Table>
            <div className="report-pagination">
              <div>
                {" "}
                <span className="mr-2">Showing</span>
                <Select
                  //   value={limit.toString()}
                  defaultValue={5}
                  style={{ width: 56 }}
                  //   onChange={(value) => setLimit(parseInt(value))}
                  options={[
                    // { value: "5", label: "5" },
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "50", label: "50" },
                    { value: "100", label: "100" },
                  ]}
                />{" "}
                <span className="ml-2">Of 50</span>
              </div>
              <div className="report-selection">
                <Pagination
                  //   current={currentPage}
                  //   pageSize={limit}
                  //   total={total * limit}
                  //   onChange={(page, pageSize) => {
                  //     setCurrentPage(page); setLimit(pageSize);
                  //   }}
                  className="pagination"
                />
              </div>
            </div>
          </div>
        </div>

        {isModal && <Edit isModal={isModal} handleCancel={handleCancel} />}
        {isModalOpen && (
          <IndividualUpload
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
          />
        )}
        <DeleteModal
          textheading="Delete Dictionary"
          deleteBtn="Delete Dictionary"
          // handleDelete={() => handleDelete(selectedRecord)}
          isDeleteModal={isDeleteModal}
          showModalDelete={() => setDeleteModal(false)}
          action='delete'
        />

        <BulKUpload isModalUpload={isModalUpload} handleCancel={handleCancel} />
      </div>
    </>
  );
};

export default CommunityDataTable;
