import { Button, Modal } from 'antd'
import React from 'react'
import useAxiosInstance from '../../../lib/useAxiosInstance';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import SurveyContext from "../../../context/surveyContext";

const DeactivateSurvey = ({ isStatusModal, editData }) => {

    const surveyContext = useContext(SurveyContext);

    const axiosInstance = useAxiosInstance();
    const queryClient = useQueryClient();

    const toggleReportStatusMutation = useMutation(async () => {
        const updatedSurvey = { ...editData };
        updatedSurvey.surveyStatus = updatedSurvey.surveyStatus === 'deactivated' ? 'activated' : 'deactivated';

        const response = await axiosInstance.put(`api/survey/${updatedSurvey._id}`, updatedSurvey);

        return response.data;
    }, {
        onSuccess: (data) => {
            handleCancel();
            queryClient.invalidateQueries('survey-list');
            queryClient.invalidateQueries('allSearchSurvey');
            toast.success(`Report ${data.data.surveyStatus} successfully`);
        },
        onError: (error) => {
            toast.error('An error occurred while updating report status');
        },
    }
    );

    const handleToggleStatus = () => {
        toggleReportStatusMutation.mutate();
    };

    const handleCancel = () => {
        surveyContext.updateStatusModal(false);
    };

    return (
        <Modal open={isStatusModal}
            onSumbit={handleToggleStatus}
            onCancel={handleCancel}
            footer={null}
        >
            <div>
                <div className='modal-title'>
                    <h3>{editData.surveyStatus === 'deactivated' ? 'Activate' : 'Deactivate'} Survey</h3>
                </div>
                <div className='delete-modal-body'>
                    <h6>Are you sure you want to {editData.surveyStatus === 'deactivated' ? 'activate' : 'deactivate'} this survey?</h6>
                </div>

                <div className='modal-footer p-4'>
                    <div className='flex justify-end'>
                        <Button className='delete-btn' onClick={handleToggleStatus}>
                            {editData.surveyStatus === 'deactivated' ? 'Activate' : 'Deactivate'} Survey
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

export default DeactivateSurvey;