import React, { useState } from "react";
import { Col, Row, Spin, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../../../Forms/ErrorMessage";
import ExcelFileImg from "../../../../assets/images/ExcelFileImg.png";
import { HiOutlineDownload } from "react-icons/hi";
import DeleteIcon from "../../../../assets/svg/deleteIcon.svg";

import { AiOutlineClose } from "react-icons/ai";
import useAxiosInstance from "../../../../lib/useAxiosInstance";
import { ToastContainer, toast } from "react-toastify";

const FileUpload = ({
  polygonData,
  loading,
  title,
  uploadApiEndpoint,
  deleteModalHeading,
  deleteModalText,
  localStorageKey,
  setLoading,
  setLocalData,
  uploadedData,
  year,
  setIsDeleteModalVisible,
  handleRefreshClick,
  activeTab,
  setRefresh,
  model
}) => {
  const [fileList, setFileList] = useState([]);
  const axiosInstance = useAxiosInstance();

  const FormSchema = Yup.object().shape({
    resourceLocation: Yup.array().min(1, "File is required")
  });

  // const selectedData = JSON.parse(localStorage.getItem(localStorageKey));
  const formik = useFormik({
    initialValues: {
      resourceLocation: [],
      comment: ""
    },
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    }
  });

  const handleCsvFileUpload = async (values) => {
    if (!year) {
      Promise.reject(toast.error("Please select the election year"));
    } else {
      const { resourceLocation, comment } = values;
      const formData = new FormData();
      formData.append("csvFile", resourceLocation[0]?.originFileObj);
      formData.append("constituencyId", polygonData?.constituencyId);
      formData.append("comment", comment);
      formData.append("year", year);

      try {
        const response = await axiosInstance.post(uploadApiEndpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        toast.success(`${title} Data Uploaded Successfully`);
        // localStorage.setItem(localStorageKey, JSON.stringify(values));
        return response?.data?.data;
      } catch (error) {
        toast.error(error?.response?.data?.message);
        return null;
      }
    }
  };

  const handleSubmit = (values, onSubmitProps) => {
    setLoading(true);
    handleCsvFileUpload(values).then(() => {
      handleRefreshClick().then(() => {
        setRefresh((prev) => {
          return !prev;
        });
        setLoading(false);
        // setTimeout(() => {
        //   setLoading(false);
        // });
      });
    });
  };

  const handleDownload = async () => {
    try {
      const response = await axiosInstance.get(
        `api/constituency/downloadUploadedFileByConstituencyId/${polygonData?.constituencyId}?model=${model}&year=${year}`
      );

      const filePath = response?.data?.data?.filePath;

      if (filePath) {
        const downloadLink = document.createElement("a");
        downloadLink.href = filePath?.publicUrl;
        downloadLink.setAttribute("download", filePath?.name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        toast.error(`${title} file not available`);
      }
    } catch (error) {
      // Handle any errors that occurred during the fetch or download process
      console.error("Error:", error.message);
    }
  };

  // const handleDeleteModal = () => {
  //   // localStorage.removeItem(localStorageKey);
  //   setIsDeleteModalVisible(false);
  // };

  const handleShowModalDelete = () => {
    setIsDeleteModalVisible(true);
    handleReset();
  };

  // const handleCancel = () => {
  //   setIsDeleteModalVisible(false);
  // };

  const handleResetFile = () => {
    formik.setFieldValue("resourceLocation", []);
  };

  const handleReset = () => {
    formik.resetForm();
  };
  // useEffect(() => {
  //   formik.setFieldValue("resourceLocation", []);
  // }, [year, activeTab]);

  return (
    <>
      <div className='formData'>
        <ToastContainer></ToastContainer>
        <Spin spinning={loading}>
          {uploadedData ? (
            <div className='constituency-form constituency-info p-9'>
              <h3>Uploaded File</h3>
              <div className='flex justify-between uploaded-file my-9'>
                <div className='flex'>
                  <img src={ExcelFileImg} alt='' className='mr-3 h-9' />
                  <p className='file-name-tooltip'>
                    {uploadedData}
                    {/* {uploadedData} */}
                  </p>
                </div>
                <div className='flex'>
                  <HiOutlineDownload
                    className='w-9 h-7 mr-4 cursor-pointer'
                    style={{ color: "#2B9FFF" }}
                    onClick={handleDownload}
                  />
                  <img
                    src={DeleteIcon}
                    alt=''
                    className='w-9 h-7 mr-2 cursor-pointer'
                    onClick={handleShowModalDelete}
                  />
                </div>
              </div>
              {/* <div className='basicInfoDiv'>
                <div className='firstname-field'>
                  <p>
                    <span>Uploaded on </span>
                    <span>
                      {new Date().toLocaleDateString("en-US") || 9 / 9 / 2023}
                    </span>
                  </p>
                  <p>
                    <span>Uploaded by</span>
                    <span style={{ textTransform: "capitalize" }}>
                      {user?.firstName} {user?.lastName}
                    </span>
                  </p>
                  <p className='flex'>
                    <span>Comment</span>{" "}
                    <span className='inline-block w-3/5'>
                      {formik?.values?.comment || ""}
                    </span>
                  </p>
                </div>
              </div> */}
            </div>
          ) : (
            <form
              id='form-20'
              className='constituency-form constituency-info rounded-b-lg'
              onSubmit={formik.handleSubmit}
            >
              <div className='model-wrapper'>
                <Row className='pt-5' justify='center'>
                  <Col span={16}>
                    <h3>Upload File</h3>
                    <div className='constituency-upload-file'>
                      <div className='mt-3 report-upload-file-info'>
                        <Upload.Dragger
                          key='select-file'
                          name='resourceLocation'
                          beforeUpload={() => false}
                          fileList={fileList}
                          onChange={(info) => {
                            const { file, fileList } = info;
                            if (file.status === "done") {
                              setFileList([file]);
                            } else {
                              setFileList([]);
                            }
                            formik.setFieldValue("resourceLocation", fileList);
                          }}
                          className='custom-file-upload'
                          accept='.xlsx'
                        >
                          {formik.values.resourceLocation.length > 0 ? (
                            <div className='fileUpload'>
                              <div className='text-right'>
                                <button
                                  onClick={handleResetFile}
                                  className='reset-button font-bold'
                                >
                                  <AiOutlineClose />
                                </button>
                              </div>
                              <div className='flex file-icon'>
                                {/* <FileFilled /> */}

                                <div className='flex'>
                                  <img
                                    src={ExcelFileImg}
                                    alt=''
                                    className='mr-3 h-9'
                                  />
                                  <p className='file-name-tooltip'>
                                    {formik.values.resourceLocation[0].name}
                                    {/* {uploadedData} */}
                                  </p>
                                </div>
                                {/* <p>{formik.values.resourceLocation[0].name}</p> */}
                              </div>
                            </div>
                          ) : (
                            <div>
                              <FaCloudUploadAlt
                                style={{ color: "#3BBEE8" }}
                                className='w-9 h-9 m-auto'
                              />
                              <p className='pt-2'>
                                Select a file or drag and drop
                              </p>
                              <p className='pt-2'>
                                Please upload excel file size no more than 10MB
                              </p>
                              <span className='select-file'>Select File</span>
                            </div>
                          )}
                        </Upload.Dragger>
                      </div>
                    </div>
                    <ErrorMessage
                      hasError={Boolean(
                        formik.errors.resourceLocation &&
                          formik.touched.resourceLocation
                      )}
                      message={formik.errors.resourceLocation}
                    />
                  </Col>
                </Row>
                <Row className='add-modal-row' justify='center'>
                  <Col span={16} className='my-7'>
                    <label>Comment (if any)</label>
                    <TextArea
                      rows={2}
                      id='comment'
                      name='comment'
                      onChange={formik.handleChange}
                      value={formik.values.comment}
                    />
                  </Col>
                </Row>
              </div>
              <div className='constituency-form-footer edit-footer flex justify-end'>
                <button
                  type='button'
                  className='report-upload-modal-button mr-5'
                  onClick={handleReset}
                >
                  Cancel
                </button>
                <button type='submit' className='report-upload-modal-button'>
                  Save
                </button>
              </div>
            </form>
          )}
        </Spin>
        {/* <ConfirmationModal
          modalHeading='Delete'
          modalText={`Are you sure you want to Delete ${title}?`}
          isConfirmModal={isDeleteModalVisible}
          handleCancel={handleCancel}
          handleConfirmation={handleDeleteModal}
        /> */}
      </div>
    </>
  );
};

export default FileUpload;
