import React, { createContext, useState } from 'react'

export const reportContext = createContext()

const ReportContextProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setLimit(pageSize)
    };

    const pagination = {
        total: (total * limit),
        pageSize: limit,
        current: currentPage,
        onChange: handlePageChange,
    };

    const [inputReportValue, setInputReportValue] = useState({
        reportName: "",
        authorName: "",
        createdAt: "",
        resourceLocation: null,
        subscribers: [],
        reportDescription: "",
        tags: []
    })

    const [reportDataValues, setReportDataValues] = useState([]);

    const [loading, setLoading] = useState(false);

    // for fetch subscribers list in dropdown
    const [selectedSubscribers, setSelectedSubscribers] = useState([]);

    // FOR EDIT MODAL
    const [editModal, setEditModal] = useState(false)
    const [reportsEdit, setReportsEdit] = useState({
        reportName: "",
        resourceLocation: [
            {
                name: "",
                key: "",
                publicUrl: ""
            }
        ],
        subscribers: [],
        reportDescription: "",
        reportStatus:""
    })
    const showEditModal = (record) => {
        setEditModal(!editModal);
        setReportsEdit({ ...record })
        setSelectedReportToDeactivate({ ...record });
    }
    // FOR MANAGE SUBSCRIBERS MODAL
    const [manageSubscribers, setManageSubscribers] = useState(false);
    const [selectedClient, setSelectedClient] = useState({
        reportName: "",
        resourceLocation: [
            {
                name: "",
                key: "",
                publicUrl: ""
            }
        ],
        subscribers: []
    });
    const showSubscribersModal = (record) => {
        setManageSubscribers(!manageSubscribers);
        setSelectedClient({ ...record })
    };

    // FOR DEACTIVATE REPORT MODAL
    const [deactivateReport, setDeactivateReport] = useState(false);
    const [selectedReportToDeactivate, setSelectedReportToDeactivate] = useState(null);
    const handleModalDeactivate = (record) => {
        setSelectedReportToDeactivate(record);
        setDeactivateReport(true);
    };

    const reportValues = {
        currentPage, setCurrentPage, limit, setLimit,
        total, setTotal, pagination, handlePageChange, inputReportValue, setInputReportValue,
        reportDataValues, setReportDataValues, loading, setLoading,
        editModal, setEditModal, reportsEdit, setReportsEdit, showEditModal,
        setSelectedSubscribers, manageSubscribers, selectedSubscribers, showSubscribersModal,
        selectedClient, setSelectedClient, deactivateReport, setDeactivateReport,
        selectedReportToDeactivate, setSelectedReportToDeactivate, handleModalDeactivate
    }
    return (
        <reportContext.Provider value={reportValues}>{children}</reportContext.Provider>
    )
}

export default ReportContextProvider