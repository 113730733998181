import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AppContext } from "../../context/AppContextProvider"
import useAxiosInstance from "../../lib/useAxiosInstance";
import AuthLayout from "../../components/Layouts/AuthLayout";
import ErrorMessage from '../../components/Forms/ErrorMessage';
import ButtonLoader from "../../components/Loader/ButtonLoader";
import { Input } from "antd";
import Logo from "../../assets/images/logo.png";


const SignIn = () => {

    const navigate = useNavigate();
    const axiosInstance = useAxiosInstance();

    const { setIsLoggedIn, setTheme } = useContext(AppContext);

    const [isLoading, setIsLoading] = useState(false);


    const FormSchema = Yup.object().shape({
        email: Yup.string().trim().required('Email is required').email('Valid email is required')
            .matches('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$', 'Valid email is required')
            .min(10, 'Email is minimum 10 character').max(100, 'Email is maximum 100 character'),
        password: Yup.string().trim().required('Password is required').min(6, 'Password is minimum 6 character').max(16, 'Password is maximum 16 character')
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        enableReinitialize: true,
        validationSchema: FormSchema,
        onSubmit: (values, onSubmitProps) => {
            handleSubmit(values, onSubmitProps);
        }
    });

    const handleSubmit = async (value, onSubmitProps) => {
        setIsLoading(true);
        axiosInstance.post("api/users/login", value).then((response) => {
            setIsLoggedIn(true);

            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("admin-user", JSON.stringify(response.data.data.user));

            // Set theme when navigating to the dashboard
            setTheme((storedTheme) => storedTheme || "light");

            navigate("/dashboard");
        })
            .catch((error) => {
                if (error.response) {
                    const errorMessage = error.response.data.message;
                    const emailErrorMsg = error.response.data.error;

                    if (errorMessage === "This account is suspended.") {
                        onSubmitProps.setFieldError('password', 'This account is suspended.');
                    }
                    else if (emailErrorMsg === "Can't find User! please enter correct email") {
                        onSubmitProps.setFieldError('password', "User does not exist.");
                    }
                    else {
                        onSubmitProps.setFieldError('password', 'Invalid email or password. Please try again.');
                    }
                } else {
                    // Handle other types of errors (e.g., network errors)
                    onSubmitProps.setFieldError('password', 'Invalid email or password. Please try again.');
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <AuthLayout>
            <>
                <div className="get-in">
                    {/* <div className="login-content"> */}
                    <div className='logo'>
                        <img className="block mx-auto" src={Logo} alt="Logo" />
                    </div>
                    <div className="text-content">
                        <h2>Welcome</h2>
                    </div>

                    <div className="sign-in-para">
                        <p>Login to your account details below</p>
                    </div>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="flex flex-wrap">
                            <div className="py-2 w-full">
                                <div className="add-modal-row mt-7 mb-0">
                                    <label className="leading-7 text-lg text-gray-600">
                                        Email
                                    </label>
                                    <Input type='text' placeholder='E-mail' name='email'
                                        className={`w-full rounded-lg text-base py-2 px-3 mt-2`}
                                        onChange={formik.handleChange} value={formik.values.email}
                                    />
                                    <ErrorMessage hasError={Boolean(formik.errors.email && formik.touched.email)} message={formik.errors.email} />
                                </div>
                            </div>

                            <div className="py-2 w-full">
                                <div className="add-modal-row mt-3 mb-0">
                                    <label className="leading-7 text-lg text-gray-600">
                                        Password
                                    </label>
                                    <Input.Password type='password' placeholder='Password' name='password'
                                        className={`w-full rounded-lg text-base py-2 px-3 mt-2 signin-password`}
                                        onChange={formik.handleChange} value={formik.values.password}
                                    />
                                    <ErrorMessage hasError={Boolean(formik.errors.password && formik.touched.password)} message={formik.errors.password} />
                                </div>
                            </div>

                            <div className="my-2 ml-auto">
                                <Link to="/login/forgot-password" className="forgot-pass-text">
                                    Forgot Password?
                                </Link>
                            </div>

                            <div className="py-2 w-full">
                                <div className="my-4">
                                    <button className="flex mx-auto w-full text-white bg-sky-900 border-0 py-3 justify-center focus:outline-none  rounded-lg text-lg"
                                        type="submit" disabled={isLoading}
                                    >
                                        {isLoading ? <ButtonLoader /> : 'Login'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                    {/* </div> */}
                </div>
            </>
        </AuthLayout>
    );
};

export default SignIn;