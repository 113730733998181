import React, { useState } from "react";
import { Button, Modal, Form, Upload, Spin } from "antd";
import { FileFilled } from "@ant-design/icons";

const BulKUpload = ({ isModalUpload, handleCancel, handleOk }) => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState();
  // eslint-disable-next-line
  const [inputReportValue, setInputReportValue] = useState({
    resourceLocation: null,
  });

  const handleFileChange = async (info) => { };

  return (
    <>
      <Modal
        className="custom-support-modal"
        open={isModalUpload}
        width={1040}
        maskClosable={false}
        height={824}
        onCancel={handleCancel}
        footer={[
          <Button
            type="button"
            className="gms-modal-button bg-sky-900 mr-10"
            onClick={handleOk}
          >
            Add New Dictionary
          </Button>,
        ]}
      >
        <Form className="cust-form">
          <div className="modal-title">
            <h3>Bulk Upload Dictionary</h3>
          </div>
          <div className="modal-wrapper-body">
            <div className="modal-wrapper-content">
              <div className="grid grid-cols-1 pb-4">
                <h4 className="text-heading">Add Community Data</h4>
              </div>
              <div>
                <h6>Step 1: Download the sample file as a guideline. </h6>
                <p className="csv-file">CSV templete</p>
              </div>
              <div>
                <h6>Step 2: After entering data, upload the CSV file.</h6>
                <div className="file-upload mt-8 mb-12">
                  <div className="mt-3 report-upload-file-info">
                    <Upload.Dragger
                      key="select-file"
                      name="resourceLocation"
                      beforeUpload={() => false}
                      onChange={handleFileChange}
                      className="custom-file-upload"
                      accept=".jpg,.jpeg,.png,.pdf,.doc,.xls"
                    >
                      {loading ? (
                        <Spin />
                      ) : (
                        <>
                          {!inputReportValue.resourceLocation ? (
                            <>
                              <span className="flex justify-center file-icon">
                                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M10 16H5.5C3.98 16 2.68333 15.4767 1.61 14.43C0.536667 13.3767 0 12.0933 0 10.58C0 9.28 0.39 8.12 1.17 7.1C1.95667 6.08 2.98333 5.43 4.25 5.15C4.67 3.61667 5.50333 2.37667 6.75 1.43C8.00333 0.476667 9.42 0 11 0C12.9533 0 14.6067 0.68 15.96 2.04C17.32 3.39333 18 5.04667 18 7C19.1533 7.13333 20.1067 7.63333 20.86 8.5C21.62 9.35333 22 10.3533 22 11.5C22 12.7533 21.5633 13.8167 20.69 14.69C19.8167 15.5633 18.7533 16 17.5 16H12V8.85L13.6 10.4L15 9L11 5L7 9L8.4 10.4L10 8.85V16Z"
                                    fill="#336D92" />
                                </svg>
                              </span>
                              <p className="ant-upload-text">
                                Click to upload or drag and drop your files.
                                <br />
                                (Maximum file size is 50MB)
                              </p>
                            </>
                          ) : (
                            <div className="fileUpload">
                              <FileFilled />
                              <p>{inputReportValue.resourceLocation[0].name}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Upload.Dragger>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </Form>
      </Modal>
    </>
  );
};

export default BulKUpload;
