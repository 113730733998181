import React from 'react'
import '../../styles/Gms.css';
import {ToastContainer} from 'react-toastify'
import TmsList from './TmsList';

const Result = () => {
    return (
        <div className="tms-page-wrapper">
            <ToastContainer/>

            <div className='heading-sec'>
                <h1 className='heading-text'>Task Management System(TMS)</h1>
            </div>

            <TmsList/>
        </div>
    )
};

export default Result;