import React, { useState } from "react";
import { Select, Spin, Table } from "antd";
// import NoDataIcon from "../../../../assets/svg/no-data.svg";
import GraphModal from "../../GraphModal";
import ExpandIcon from "../../../../assets/svg/expandIcon.svg";
import ConstituencyDataTable from "./constituencyDataTable";
import sampleData from "./sampleData.json";
import { getGraphData } from "../../utils/getFromattedValue";
import { renderCharts } from "../../utils/renderCharts";
import { demographyOptions } from "../../utils/demographyConfig";
// import { getIsDataAvailable } from "../../utils/getIsDataAvailable";
const ConstituencyData = ({
  constituencyDataList,
  isDataAvailable,
  isLoading
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState("age");

  // const isDataAvailable = getIsDataAvailable();

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleSelectChange = (value) => {
    setSelectedOption(value);
  };

  const graphData = getGraphData(
    selectedOption,
    sampleData,
    constituencyDataList
  );

  return (
    <>
      <div className='constituency-data mt-5'>
        <h4 style={{ textAlign: "center", marginBottom: "10px" }}>
          Constituency Data
        </h4>
        <div className='w-full grid grid-cols-12 gap-4'>
          <div className='col-span-12 sm:col-span-7  constituenct-data-table'>
            <h1>Constituency Form 20 Data</h1>

            <ConstituencyDataTable
              isDataAvailable={isDataAvailable}
              // data={sampleData?.electoralData}
              data={constituencyDataList?.electoralData}
              isLoading={isLoading}
            />
          </div>
          <div className='col-span-12 sm:col-span-5 constituenct-data-table'>
            <div className='flex justify-end'>
              <h1 className='mt-2 ml-6'>Constituency Electoral Data</h1>
              <div className='flex-grow' />
              <Select
                className='mr-3'
                placeholder='Select'
                value={selectedOption}
                onChange={handleSelectChange}
                disabled={!isDataAvailable}
              >
                {demographyOptions.map(({ value, label }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
              <img
                className='cursor-pointer'
                src={ExpandIcon}
                alt=''
                onClick={openModal}
              />
            </div>{" "}
            {renderCharts(
              selectedOption,
              isDataAvailable,
              graphData,
              isLoading
            )}
          </div>
        </div>
      </div>
      <GraphModal
        isDataAvailable={isDataAvailable}
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        graphData={graphData}
        selectedOption={selectedOption}
      />
    </>
  );
};

export default ConstituencyData;
