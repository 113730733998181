import React, { useState } from "react";
import { Modal, Input, Row, Col, Form, Upload } from "antd";
import { useQueryClient } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { FileFilled } from "@ant-design/icons";
import { AiOutlineClose } from "react-icons/ai";
// import InvoiceConfirmationModal from "./InvoiceConfirmationModal";
import { toast } from "react-toastify";

const InvoiceForm = ({ uploadInvoice, setUploadInvoice, clientId }) => {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const [fileList, setFileList] = useState([]);
  // const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [inputValues, setInputValues] = useState({
    invoiceNumber: null,
    invoiceDate: "",
    invoiceAmount: null,
    invoiceDocument: null
  });

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("invoiceNumber", values?.invoiceNumber || "");
    formData.append("invoiceDate", values?.invoiceDate || "");
    formData.append("invoiceAmount", values?.invoiceAmount || "");
    formData.append(
      "invoiceDocument",
      inputValues?.invoiceDocument[0]?.originFileObj || ""
    );
    formData.append("clientId", clientId || "");

    try {
      const response = await axiosInstance.post(`/api/invoice`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      // Reset file after successful API call
      handleResetFile();

      // setIsConfirmModal(true);
      setUploadInvoice(false);

      toast.success("Payment Recorded Successfully");
      queryClient.invalidateQueries("invoice-list");
    } catch (error) {
      toast.error("Failed to record payment. Please try again.");
    }
  };

  const handleChange = (value, name) => {
    setInputValues({ ...inputValues, [name]: value });
  };
  const closeModal = () => {
    setUploadInvoice(false);
  };
  const handleResetFile = () => {
    setInputValues("");
  };

  return (
    <>
      {uploadInvoice && (
        <Modal
          className='add-user-modal invoice-form'
          open={uploadInvoice}
          onCancel={closeModal}
          width={1040}
          maskClosable={false}
          footer={[
            <button
              key='invoice-form'
              form='invoice'
              type='submit'
              className='gms-modal-button'
            >
              Upload Invoice
            </button>
          ]}
        >
          <Form id='invoice' onFinish={handleSubmit}>
            <div className='user-info-modal'>
              <div className='modal-title'>
                <h3>Upload Invoice</h3>
              </div>
              <div className='modal-wrapper-body'>
                <div className='modal-wrapper-content'>
                  <h4>Invoice Details</h4>

                  <Row className='add-modal-row'>
                    <Col span={8} className='mr-6'>
                      <label htmlFor=''>Invoice Number</label>
                      <Form.Item
                        name='invoiceNumber'
                        rules={[
                          {
                            required: true,
                            message: "Invoice number is required."
                          }
                        ]}
                      >
                        <Input type='text' name='invoiceNumber' />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <label htmlFor=''>Invoice Date</label>
                      <Form.Item
                        name='invoiceDate'
                        rules={[
                          {
                            required: true,
                            message: "Invoice date is required."
                          }
                        ]}
                      >
                        <Input type='date' name='invoiceDate' />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className='add-modal-row'>
                    <Col span={8} className='mr-6'>
                      <label htmlFor=''>Amount</label>
                      <Form.Item
                        name='invoiceAmount'
                        rules={[
                          {
                            required: true,
                            message: "Amount is required."
                          }
                        ]}
                      >
                        <Input
                          type='number'
                          placeholder='₹'
                          name='invoiceAmount'
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <h4>Upload Invoice</h4>
                  <Row className='add-modal-row mb-2'>
                    <Col span={22}>
                      <Form.Item
                        name='invoiceDocument'
                        rules={[
                          {
                            required: true,
                            message: "Please upload the invoice file"
                          }
                        ]}
                      >
                        <div className='report-upload-file-info'>
                          <div className='mt-3'>
                            <Upload.Dragger
                              key='select-file'
                              name='invoiceDocument'
                              beforeUpload={() => false}
                              fileList={fileList}
                              onChange={(info) => {
                                const { file, fileList } = info;
                                if (file.status === "done") {
                                  setFileList([file]);
                                } else {
                                  setFileList([]);
                                }
                                handleChange(fileList, "invoiceDocument");
                              }}
                              className='custom-file-upload'
                              accept=''
                            >
                              {inputValues?.invoiceDocument?.length > 0 ? (
                                <div className='fileUpload'>
                                  <div className='text-right'>
                                    <button
                                      onClick={handleResetFile}
                                      className='reset-button font-bold'
                                    >
                                      <AiOutlineClose />
                                    </button>
                                  </div>
                                  <div className='flex file-icon'>
                                    <FileFilled />
                                    <p>
                                      {inputValues?.invoiceDocument[0]?.name}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <span className='flex justify-center file-icon'>
                                    <svg
                                      width='22'
                                      height='16'
                                      viewBox='0 0 22 16'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        d='M10 16H5.5C3.98 16 2.68333 15.4767 1.61 14.43C0.536667 13.3767 0 12.0933 0 10.58C0 9.28 0.39 8.12 1.17 7.1C1.95667 6.08 2.98333 5.43 4.25 5.15C4.67 3.61667 5.50333 2.37667 6.75 1.43C8.00333 0.476667 9.42 0 11 0C12.9533 0 14.6067 0.68 15.96 2.04C17.32 3.39333 18 5.04667 18 7C19.1533 7.13333 20.1067 7.63333 20.86 8.5C21.62 9.35333 22 10.3533 22 11.5C22 12.7533 21.5633 13.8167 20.69 14.69C19.8167 15.5633 18.7533 16 17.5 16H12V8.85L13.6 10.4L15 9L11 5L7 9L8.4 10.4L10 8.85V16Z'
                                        fill='#336D92'
                                      />
                                    </svg>
                                  </span>
                                  <p className='ant-upload-text'>
                                    Click to upload or Drag and drop your files.
                                  </p>
                                  <p className='ant-upload-text'>
                                    (Maximum file size is 50MB)
                                  </p>
                                  <div className='fileUpload'></div>
                                </div>
                              )}
                            </Upload.Dragger>
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default InvoiceForm;
