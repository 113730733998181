import React, { useState } from 'react'
import { Col, Modal, Row, Select } from "antd";
import shareFile from '../../../assets/svg/shareFile.svg'
import { useMutation, useQueryClient } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ErrorMessage from '../../../components/Forms/ErrorMessage';
import { toast } from "react-toastify";

const ShareSurvey = ({ shareSurvey, openShareModal, selectedSurveyData, setSelectedSurveyData }) => {
    const queryClient = useQueryClient()
    const axiosInstance = useAxiosInstance();
    const shareReportQuery = queryClient.getQueryData("subs-client-list");
    // eslint-disable-next-line
    const [selectedSubscribers, setSelectedSubscribers] = useState([]);


    const FormSchema = Yup.object().shape({
        subscribers: Yup.array().required('Subscribers is required'),
    });

    const formik = useFormik({
        initialValues: {
            subscribers: Array.from(new Set(selectedSurveyData.flatMap(survey => survey.subscribers.map(subscriber => subscriber._id)))),
        },
        enableReinitialize: true,
        validationSchema: FormSchema,
        onSubmit: (values, onSubmitProps) => {
            handleSubmit(values, onSubmitProps);
        }
    });

    const handleSubmit = async (values) => {
        try {
            if (values.subscribers.length === 0) {
                toast.error('Please select at least one subscriber');
                return;
            }

            const sharedSurveys = [];

            for (const survey of selectedSurveyData) {
                const existingSubscriberIds = new Set(survey.subscribers.map(existingSubscriber => existingSubscriber._id));

                if (!values.subscribers.every(subscriberId => existingSubscriberIds.has(subscriberId))) {
                    // The survey is not associated with all existing subscribers
                    const newSubscribers = values.subscribers.filter(subscriberId => !existingSubscriberIds.has(subscriberId));

                    const selectedSubscriberDetails = newSubscribers.map(subscriberId => {
                        const subscriber = shareReportQuery?.find(subscriber => subscriber._id === subscriberId);
                        return {
                            _id: subscriber._id,
                            firstName: subscriber.adminFirstName,
                            lastName: subscriber.adminLastName,
                        };
                    });

                    const updatedSubscribers = [...survey.subscribers, ...selectedSubscriberDetails];

                    await shareSurveyMutation.mutateAsync({
                        surveyIds: [survey._id],
                        subscribers: updatedSubscribers,
                    });
                }

                sharedSurveys.push(survey);
            }

            // Check if all selected surveys are already associated with all existing subscribers and no new subscribers are selected
            const allSurveysShared = selectedSurveyData.every(survey => {
                const existingSubscriberIds = new Set(survey.subscribers.map(existingSubscriber => existingSubscriber._id));
                return values.subscribers.every(subscriberId => existingSubscriberIds.has(subscriberId));
            });

            if (allSurveysShared && values.subscribers.length === 1) {
                toast.error('Surveys already shared with this subscriber');
                return;
            } else if (allSurveysShared && values.subscribers.length > 1) {
                toast.error('Surveys already shared with these subscribers');
                return;
            }

            if (sharedSurveys.length > 0) {
                toast.success('Surveys shared successfully.');
            }

        } catch (error) {
            console.log(error);
        }
    };

    const shareSurveyMutation = useMutation(
        async (surveyData) => {
            const response = await axiosInstance.put(`api/survey/client/shareSurveyWithClient`, surveyData);
            return response.data;
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries('survey-list');
                queryClient.invalidateQueries('allSearchSurvey');
                queryClient.invalidateQueries("subs-client-list");
                closeModal();
            },
        }
    );

    const closeModal = () => {
        setSelectedSurveyData([]);
        setSelectedSubscribers([]);
        openShareModal();
    };

    // const handleCancelFile = (surveyId) => {
    //     setSelectedSurveyData((prevState) => {
    //         const updatedReports = prevState.map((survey) => {
    //             if (survey._id === surveyId) {
    //                 const { resourceLocation, ...updatedReport } = survey;
    //                 return updatedReport;
    //             }
    //             return survey;
    //         });
    //         return updatedReports;
    //     });
    // };


    return (
        <div>
            <div className="share-report">
                <Modal
                    className="share-report-modal  form-container"
                    open={shareSurvey}
                    width={1040}
                    onCancel={closeModal}
                    maskClosable={false}
                    footer={[
                        <button key="upload-report" form="share-survey" type='submit' className='report-upload-modal-button'>
                            Share
                        </button>
                    ]}
                >
                    <form id="share-survey" onSubmit={formik.handleSubmit}>
                        <div className="modal-title">
                            <h3>Share Survey</h3>
                        </div>
                        <div className="modal-wrapper-body">
                            <div className="modal-wrapper-content">
                                <div>
                                    <Row className="add-modal-row pb-8">
                                        <Col className="edit-subscribers">
                                            <h4>Share with clients</h4>
                                            <Select
                                                mode="multiple"
                                                style={{ width: "100%" }}
                                                name="subscribers"
                                                showSearch
                                                filterOption={(input, option) => {
                                                    return (
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    );
                                                }}
                                                // onChange={(values) => {
                                                //     formik.setFieldValue("subscribers", values); // Update the formik state
                                                //     setSelectedSubscribers(values); // Update the selectedSubscribers state
                                                // }}
                                                // value={formik.values.subscribers}
                                                onChange={(values, option) => {
                                                    const updatedValues = option.map(opt => opt.value);
                                                    formik.setFieldValue("subscribers", updatedValues);
                                                    setSelectedSubscribers(updatedValues);
                                                }}
                                                value={formik.values.subscribers}
                                                getPopupContainer={(triggerNode) => triggerNode.parentNode} 
                                            >
                                                {shareReportQuery?.map((shareSubscriber) => (
                                                    <Select.Option key={shareSubscriber._id} value={shareSubscriber._id}
                                                        disabled={selectedSurveyData.some(survey => survey.subscribers.some(subscriber => subscriber._id === shareSubscriber._id))}
                                                    >
                                                        {`${shareSubscriber.adminFirstName} ${shareSubscriber.adminLastName}`}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <ErrorMessage
                                            hasError={Boolean(formik.errors.subscribers && formik.touched.subscribers)}
                                            message={formik.errors.subscribers}
                                        />
                                    </Row>
                                    {selectedSurveyData.length > 0 && (
                                        <Row className="add-modal-row pb-8">
                                            <Col span={24} className="">
                                                <h4>Sharing reports</h4>
                                                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                                                    {selectedSurveyData?.map((file) => (
                                                        <div key={file._id} className={file.resourceLocation ? "share-desc" : ""} >
                                                            {file.resourceLocation && (
                                                                <div className="flex">
                                                                    <img src={shareFile} alt="" />
                                                                    <a href={file.resourceLocation[0].publicUrl} target="_blank" rel="noopener noreferrer">
                                                                        {file.resourceLocation[0].name}
                                                                    </a>
                                                                    {/* <CloseOutlined onClick={() => handleCancelFile(file._id)} className="cancel-icon" /> */}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal>
            </div>
        </div>
    );
}

export default ShareSurvey