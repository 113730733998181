import React, { useState} from "react";
import {useNavigate} from "react-router-dom";
// import DeletedClientsList from "./DeletedClients/DeletedClientsList";
import ClientList from "./ClientsList/ClientList";


const ClientTabs = () => {

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("client-list");

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        navigate(`../${tab}`);
    };



    return (
        <>
        
            <div className="tabs1">
                <div className="gms-table-tabs">
                    <button className={`tab-button tab-btn1 ${activeTab === "client-list" ? "active" : ""}`}
                            onClick={() => handleTabChange("client-list")}
                    >
                        Client List
                    </button>

                    {/* <button className={`tab-button tab-btn1 ${activeTab === "deleted-clients" ? "active" : ""}`}
                            onClick={() => handleTabChange("deleted-clients")}
                    >
                        Deleted Clients
                    </button> */}

                   
                </div>
            </div>

            <div className="tab-content">
                {activeTab === "client-list" && <ClientList/>}
                {/* {activeTab === "deleted-clients" && <DeletedClientsList/>} */}
            </div>

        </>
    );
};

export default ClientTabs;
