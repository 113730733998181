const formatAgeNoOfPeopleValues = (ageData) => {
  const totalPeople = ageData.reduce(
    (sum, { noOfPeople }) => sum + noOfPeople,
    0
  );

  return ageData.map(({ name, noOfPeople }) => {
    let ageLabel;
    if (name === "Unknown") {
      ageLabel = "Missing";
    } else {
      ageLabel = name;
    }

    return {
      age: ageLabel,
      "No of People": parseFloat(((noOfPeople / totalPeople) * 100).toFixed(2))
    };
  });
};

export default formatAgeNoOfPeopleValues;
