import React from 'react'
import { Button, Modal } from "antd";

const InvoiceConfirmationModal = (props) => {
    return (
        <>
            {props.isConfirmModal && (
                <Modal
                    open={props.isConfirmModal}
                    onOk={props.handleConfirmation}
                    onCancel={props.handleCancel}
                    footer={null}
                >
                    <div>
                        <div className='modal-title'>
                            <h3>{props.modalHeading}</h3>
                        </div>
                        <div className='delete-modal-body'>
                            <h6>{props.modalText}</h6>
                        </div>

                        <div className='modal-footer p-4'>
                            <div className='flex justify-end'>
                                {/* <Checkbox className='pt2'><p className='checkbox-text'>Don’t show this again.</p></Checkbox> */}
                                <Button
                                    className='delete-btn'
                                    onClick={props.handleConfirmation}
                                >
                                    Upload Invoice
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default InvoiceConfirmationModal