import React from "react";
import { Spin, Table, Skeleton } from "antd";
import Column from "antd/es/table/Column";
import NoDataIcon from "../../../../assets/svg/no-data.svg";

const ConstituencyDataTable = ({ isDataAvailable, data, isLoading }) => {
  return (
    <>
      {/* <div
        className='flex items-center justify-center'
        style={{ height: "300px" }}
      > */}
      {isDataAvailable ? (
        <Table
          rowKey={(record) => record.type}
          dataSource={data}
          locale={{
            emptyText:
              isLoading || !data ? (
                <div
                  style={{
                    width: "95%"
                  }}
                >
                  {" "}
                  {/* Container with 90% width */}
                  <Skeleton
                    title={false}
                    active
                    paragraph={{
                      rows: 5,
                      width: ["100%", "100%", "100%", "100%", "100%"]
                    }}
                  />
                </div>
              ) : (
                <div className='no-data-icon mt-20'>
                  <img src={NoDataIcon} alt='' />
                  <h1>No Data Available</h1>
                </div>
              )
          }}
        >
          <Column
            title='Party'
            dataIndex='partyName'
            render={(text, record) => (
              <div className='text-center'>
                <span
                  style={{
                    fontWeight: "bold",
                    color: record.partyColorCode,
                    textTransform: "capitalize"
                  }}
                >
                  {text}
                </span>
              </div>
            )}
          />
          <Column
            title='Vote Share'
            dataIndex='voteShare'
            render={(text) => (
              <div className='text-center'>
                {text !== null && text !== undefined ? text : "-"}
              </div>
            )}
          />
          <Column
            title='Vote Share%'
            dataIndex='voteSharePercentage'
            render={(text) => (
              <div style={{ textAlign: "center" }}>
                {text !== null && text !== undefined
                  ? `${parseFloat(text).toFixed(2)}%`
                  : "-"}
              </div>
            )}
          />

          <Column
            title='Villages'
            dataIndex='noOfVillageWon'
            render={(text) => (
              <div style={{ textAlign: "center" }}>
                {text !== null && text !== undefined ? text : "-"}
              </div>
            )}
          />

          <Column
            title='Polling Station'
            dataIndex='noOfPSWon'
            render={(text) => (
              <div style={{ textAlign: "center" }}>
                {text !== null && text !== undefined ? text : "-"}
              </div>
            )}
          />
        </Table>
      ) : (
        <div className='no-data-icon mt-3'>
          <img src={NoDataIcon} alt=''></img>
          <span>No Data Available</span>
        </div>
      )}
      {/* </div> */}
    </>
  );
};

export default ConstituencyDataTable;
