import React from "react";
import AgeChart from "./Charts/ageChart";
import GenderChart from "./Charts/genderChart";
import CommunityChart from "./Charts/communityChart";
import AgeVsGenderChart from "./Charts/ageVsGenderChart";
import AgeVsCommunityChart from "./Charts/ageVsCommunityChart";
import GenderVsCommunityChart from "./Charts/genderVsCommunityChart";
import { Skeleton } from "antd";
import { DotChartOutlined } from "@ant-design/icons";

export const renderCharts = (
  selectedOption,
  isDataAvailable,
  graphData,
  isLoading
) => {
  if (isLoading && !graphData.length > 0 && isDataAvailable) {
    return (
      <div
        className=''
        style={{
          height: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Skeleton.Node active={true}>
          <DotChartOutlined
            style={{
              fontSize: 40,
              color: "#bfbfbf"
            }}
          />
        </Skeleton.Node>
      </div>
    );
  }
  switch (selectedOption) {
    case "age":
      return (
        <AgeChart isDataAvailable={isDataAvailable} graphData={graphData} />
      );
    case "gender":
      return (
        <GenderChart isDataAvailable={isDataAvailable} graphData={graphData} />
      );
    case "community":
      return (
        <CommunityChart
          isDataAvailable={isDataAvailable}
          graphData={graphData}
        />
      );
    case "ageGender":
      return (
        <AgeVsGenderChart
          isDataAvailable={isDataAvailable}
          graphData={graphData}
        />
      );
    case "ageCommunity":
      return (
        <AgeVsCommunityChart
          isDataAvailable={isDataAvailable}
          graphData={graphData}
        />
      );
    case "ganderCommunity":
      return (
        <GenderVsCommunityChart
          isDataAvailable={isDataAvailable}
          graphData={graphData}
        />
      );
    default:
      return null;
  }
};
