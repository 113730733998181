import React from "react";
import '../../styles/Report.css';
import {ToastContainer} from "react-toastify";
import BillingTabs from "./BillingTabs";


const Result = () => {



    return (
        <div className="gms-page-section report-tabs">
            <ToastContainer/>

            <div className="gms-wrapper">
            <div className='heading-sec'>
                <h1 className='heading-text'>Billing</h1>
            </div>


                <BillingTabs/>
            </div>
        </div>
    );
};

export default Result;
