import React from "react";
import { Button, Modal } from "antd";

const ConfirmationModal = (props) => {
  function transformString(inputString) {
    return inputString
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <>
      {props.isConfirmModal && (
        <Modal
          open={props.isConfirmModal}
          onOk={props.handleConfirmation}
          onCancel={props.handleCancel}
          footer={null}
        >
          <div>
            <div className='modal-header'>
              <p>{props.modalHeading}</p>
            </div>
            <div className='delete-modal-body'>
              <h6>
                Are you sure that you want to delete{" "}
                {transformString(props.activeTab)} Data
              </h6>
            </div>

            <div className='modal-footer p-4'>
              <div className='flex justify-end'>
                {/* <Checkbox className='pt2'><p className='checkbox-text'>Don’t show this again.</p></Checkbox> */}
                <Button
                  className='delete-btn'
                  onClick={props.handleConfirmation}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ConfirmationModal;
