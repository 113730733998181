import React from "react";
import { Modal } from "antd";
import { renderCharts } from "./utils/renderCharts";

const GraphModal = ({
  isModalVisible,
  isDataAvailable,
  closeModal,
  selectedOption,
  graphData
}) => {
  return (
    <>
      <Modal
        open={isModalVisible}
        onCancel={closeModal}
        footer={null}
        width={1040}
      >
        <div className='constituency-graph-modal'>
          <div className='constituenct-data-table mt-6 w-75'>
            <h1 className='mt-2 ml-6'>Electoral Data</h1>
            {renderCharts(selectedOption, isDataAvailable, graphData)}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GraphModal;
