import React from 'react'
import CommunityDataTable from '../../components/CommunityDictionary/CommunityDataTable'
import "../../styles/community.css"

const CommunityDictionary = () => {
  return (
    <div>
    <CommunityDataTable />
    </div>
  )
}

export default CommunityDictionary