import React from 'react'
import {Table, Tooltip, Skeleton, Empty} from "antd";

const { Column } = Table;

const GmsClientDataTable = ({pagination, SearchGmsClientData, allGmsData, getClientById, ShowGmsEditModal}) => {
  const isLoading = allGmsData.isLoading || SearchGmsClientData?.isLoading;


    return (
        <div className="gms-client-table">
            <Table rowKey={(record) => record._id} className="gms-table-rows" pagination={pagination}
                   dataSource={SearchGmsClientData || allGmsData?.data?.data?.data}
                    locale={{
          emptyText: isLoading ? (
            <div
              style={{
                marginLeft: "20px",
                width: "95%"
              }}
            >
              {" "}
              {/* Container with 90% width */}
              <Skeleton
                title={false}
                active
                paragraph={{
                  rows: 6,
                  width: ["100%", "100%", "100%", "100%", "100%", "100%"]
                }}
              />
            </div>
          ) : (
            <Empty />
          )
        }}
            >
                <Column title="CLIENT ID" dataIndex="_id"
                    render={(_id) => (
                        <Tooltip title={_id}>
                            {`${_id.slice(0, 10)}${_id.length > 10 ? "..." : ""}`}
                        </Tooltip>
                    )}
                />
                <Column title="CLIENT NAME" dataIndex="name"
                    render={(text, record) => (
                        <span onClick={() => {
                            ShowGmsEditModal(record);
                            getClientById(record._id);
                        }}>
                            {`${record?.adminFirstName} ${record?.adminLastName}`}
                        </span>
                    )}
                />
                <Column key="state" title="State" dataIndex="constituencyName"
                    render={(text, record) =>
                        record.constituency.map((c) => c.constituencyState).join(", ")
                    }
                />
                {/* <Column title="SUBSCRIPTION" dataIndex="planDetails"
                        render={(planDetails) => {
                            return planDetails?.name;
                        }}
                /> */}

                <Column
                    key='subscription'
                    title='SUBSCRIPTION'
                    dataIndex='planType'
                    render={(text, record) =>
                        record.planType.map((plan) => plan.name).join(", ")
                    }
                />

                <Column title="NO. OF OFFICES" dataIndex="office" className="custom-sorter w-48"
                    sorter={(a, b) => a.office.length - b.office.length} sortDirections={["ascend", "descend"]}
                    render={(office) => {
                        return office.length;
                    }}
                />
                <Column title="CONSTITUENCY" dataIndex="constituencyName"
                    render={(constituency, record, index) => (
                        <span>
                            {`${record?.constituency[0]?.constituencyName}
                            ${record.constituency?.length > 1 ? ` +${record.constituency.length - 1}` : ""}`}
                        </span>
                    )}
                />
                <Column title="NO. OF GRIEVANCE" dataIndex="grievances_count" className="custom-sorter w-52"
                    sorter={(c, d) => c.grievances_count - d.grievances_count} sortDirections={["ascend", "descend"]}
                    render={(grievances_count) => {
                        return grievances_count;
                    }}
                />
            </Table>
        </div>
    )
};

export default GmsClientDataTable;