import React from 'react';
import {SurveyContextProvider} from '../../context/surveyContext';
import Result from '../../components/Surveys/Result';

const Survey = () => {
    return (
        <SurveyContextProvider>
            <Result/>
        </SurveyContextProvider>
    );
};

export default Survey;