import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Form } from "antd";
import useAxiosInstance from "../../lib/useAxiosInstance";
import AssemblySearch from "./utils/AssemblySearch";
import ParliamentarySearch from "./utils/ParliamentarySearch";
import stateDistrictMapping from "../../assets/data/Indian_district_State.json";
import MapBox from "./MapBox";

const ConstituencyTabs = () => {
  const [form] = Form.useForm();
  const axiosInstance = useAxiosInstance();
  const [year, setYear] = useState();
  const [data, setData] = useState({});
  const [activeTab, setActiveTab] = useState("assembly");
  const [electionYears, setElectionYears] = useState([]);
  const [yearSelected, setYearSelected] = useState("");
  const [formValue, setFormValue] = useState({
    state: "Andhra Pradesh",
    district: "Kurnool",
    pcName: "NIZAMABAD",
    constituency: "",
    year: year
  });
  const navigate = useNavigate();
  useEffect(() => {
    setElectionYears();
    const fetchElectionYears = async () => {
      try {
        const response = await axiosInstance.get(
          `api/constituency/getElectionYears/${formValue?.constituency}`
        );
        setElectionYears(response.data);
      } catch (error) {
        console.error(error);
        // Handle error
      }
    };

    fetchElectionYears();
  }, [formValue?.constituency]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`../${tab}`);
  };
  // useEffect(() => {
  //   handleReset();
  // }, [activeTab]);

  const handleChange = (value, name) => {
    if (name === "state") {
      setFormValue((prev) => ({
        ...prev,
        district: "",
        constituency: "",
        pcName: ""
      }));
    }

    // Reset constituency when district changes
    else if (name === "district") {
      setFormValue((prev) => ({
        ...prev,
        constituency: ""
      }));
    }

    setFormValue((prev) => ({ ...prev, [name]: value }));
  };

  const handleReset = () => {
    form.resetFields();
    setFormValue({
      state: "Andhra Pradesh",
      district: "Kurnool",
      constituency: "",
      pcName: "",
      year: ""
    });
    setData(constituencyByState?.data?.data?.data);
  };

  const fetchConstituencyById = async (id) => {
    if (id) {
      return axiosInstance.get(`api/constituency/getConstituencyById/${id}`);
    } else {
      return Promise.resolve({ data: null });
    }
  };

  const onFinish = async () => {
    let response;

    if (formValue?.constituency) {
      response =
        activeTab === "assembly"
          ? await fetchConstituencyById(formValue?.constituency)
          : await fetchConstituencyById(formValue?.constituency);
    }

    setData(
      response?.data?.data
        ? [response?.data?.data]
        : constituencyByState?.data?.data?.data
    );
  };

  const fetchConstituencyByState = async (
    state,
    district,
    pcName,
    activeTab
  ) => {
    if (
      state === undefined ||
      district === undefined ||
      activeTab === undefined
    ) {
      return Promise.resolve({ data: [] });
    }

    const response =
      activeTab === "parliamentary"
        ? axiosInstance.get(
            `api/parliment/getAllParliments?state=${state}&pc_name=${pcName}`
          )
        : axiosInstance.get(
            `api/constituency/getAllConstituency?state=${state}&district=${district}`
          );

    return response;
  };

  const constituencyByState = useQuery(
    [
      "client-constituency-ByState",
      formValue?.state,
      formValue?.district,
      formValue?.pcName,
      activeTab
    ],
    () =>
      fetchConstituencyByState(
        formValue?.state?.toUpperCase(),
        formValue?.district?.toUpperCase(),
        formValue?.pcName,
        activeTab
      ),
    {
      refetchOnWindowFocus: false
    },
    { retry: 1 }
  );

  useEffect(() => {
    setYear(2023);
    const fetchData = async () => {
      try {
        const initialData = await fetchConstituencyByState(
          "ANDHRA PRADESH",
          "KURNOOL",
          "NIZAMABAD",
          activeTab
        );

        setData(initialData?.data?.data);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchData();
  }, [activeTab]);

  const stateConstituencyOptions = Object.keys(stateDistrictMapping).map(
    (state) => ({
      value: state,
      label: state
    })
  );

  const districtOptions = useMemo(
    () =>
      formValue?.state
        ? stateDistrictMapping[formValue?.state].map((district) => ({
            value: district,
            label: district
          }))
        : [],
    [formValue?.state]
  );

  const FilterContainer =
    activeTab === "assembly" ? AssemblySearch : ParliamentarySearch;

  return (
    <>
      <div className='constituency-tabs'>
        <h1>Constituency Map</h1>
        <div className='flex justify-between'>
          <div className='gms-table-tabs btn-group'>
            <button
              className={`tab-button ${
                activeTab === "assembly" ? "active" : ""
              }`}
              style={{ width: "144.47px" }}
              onClick={() => handleTabChange("assembly")}
            >
              Assembly
            </button>
            <button
              className={`tab-button tab-btn1 ${
                activeTab === "parliamentary" ? "active" : ""
              }`}
              onClick={() => handleTabChange("parliamentary")}
              // disabled
            >
              Parliamentary
            </button>
          </div>
          <FilterContainer
            form={form}
            formValue={formValue}
            stateConstituencyOptions={stateConstituencyOptions}
            districtOptions={districtOptions}
            handleChange={handleChange}
            constituencyByState={constituencyByState}
            electionYears={electionYears}
            activeTab={activeTab}
            setYear={setYear}
            handleReset={handleReset}
            onFinish={onFinish}
          />
        </div>
      </div>

      <div className='tab-content'>
        <MapBox
          state={formValue?.state?.toUpperCase()}
          district={formValue?.district?.toUpperCase()}
          fetchData={data}
          year={formValue?.year}
          tab={activeTab}
        ></MapBox>
      </div>
    </>
  );
};

export default ConstituencyTabs;
