import React, {useState} from "react";
import {useNavigate} from 'react-router-dom';
import SurveyDatabaseList from "./SurveyDatabase/SurveyDatabaseList";
import SurveyRequestedList from "./SurveyRequested/SurveyRequestedList";
import CompletedRequestList from "./SurveyCompleted/CompletedRequestList";
import DeclinedRequestList from "./SurveyDeclined/DeclinedSurveyList";

const SurveyTabs = () => {

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('survey-database');

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        navigate(`../${tab}`);
    };

    return (
        <>
            <div className="tabs1">
                <div className='gms-table-tabs'>
                    <button
                        className={`tab-button tab-btn1 ${activeTab === 'survey-database' ? 'active' : ''}`}
                        onClick={() => handleTabChange('survey-database')}
                    >
                        Survey Database
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'survey-requested' ? 'active' : ''}`}
                        onClick={() => handleTabChange('survey-requested')}
                    >
                        Survey Requested
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'declined-survey-requests' ? 'active' : ''}`}
                        onClick={() => handleTabChange('declined-survey-requests')}
                    >
                        Declined Requests
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'completed-survey-requests' ? 'active' : ''}`}
                        onClick={() => handleTabChange('completed-survey-requests')}
                    >
                        Completed Requests
                    </button>
                </div>
            </div>

            <div className="tab-content">
                <div className='report-page-section'>
                    <div className='report-table-wrapper'>
                        {activeTab === 'survey-database' && <SurveyDatabaseList/>}
                        {activeTab === 'survey-requested' && <SurveyRequestedList/>}
                        {activeTab === 'declined-survey-requests' && <DeclinedRequestList />}

                        {activeTab === 'completed-survey-requests' && <CompletedRequestList />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SurveyTabs;