import React from 'react'
import { ConstituencyContextProvider } from '../../context/constituencyContext'
import Result from '../../components/ConstituencyManagement/Result'

const ConstituencyManagement = () => {
  return (
    // <div>ConstituencyManagement</div>
    <ConstituencyContextProvider>
      <Result />
    </ConstituencyContextProvider>
  )
}

export default ConstituencyManagement