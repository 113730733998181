import React, { useState } from "react";
import { Select, Pagination } from "antd";
import { useQuery, useQueryClient } from "react-query";
import SearchFilter from "../../SearchFilter"
import useAxiosInstance from "../../../lib/useAxiosInstance";
import ClientDataTable from "./ClientDataTable";
import { toast } from "react-toastify";

const ClientList = () => {

    const axiosInstance = useAxiosInstance();
    const queryClient = useQueryClient();

    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    // eslint-disable-next-line
    const [sort, setSort] = useState("DES");
    const [searchKeyword, setSearchKeyword] = useState("");

    // GET ALL CLIENT DATA
    const fetchClients = async () => {
        const response = await axiosInstance.get(`api/clients/getAllClientAdmin?page=${currentPage}&limit=${limit}&sort=${sort}`);
        const clients = response?.data?.data;
        const totalClients = response?.data?.totalclients;
        setTotal(totalClients);
        return clients;
    };

    const clientsData = useQuery(
      ["clients", currentPage, limit],
      () => fetchClients(),
      {
        refetchOnWindowFocus: false,
        retry: 1
      }
    );

    // GET ALL SEARCH CLIENT DATA
    const SearchClient = (value) => {
            setCurrentPage(1);
        setSearchKeyword(value);
    };

    const fetchSearchClients = async () => {
        let response;
        if (searchKeyword) {
            response = await axiosInstance.get(`api/clients/SearchClientAdminByKeyword?keyword=${searchKeyword}&page=${currentPage}&limit=${limit}&sort=${sort}`);
        }
        else{
            response = await axiosInstance.get(
              `api/clients/getAllClientAdmin?page=${currentPage}&limit=${limit}&sort=${sort}`
            );
        }
        const totalClients = response?.data?.totalclients;
         setTotal(totalClients);
        return response;
    };

    const clientSearchData = useQuery(
      ["allSearchClients", searchKeyword, currentPage, limit],
      () => fetchSearchClients(),
      {
        refetchOnWindowFocus: false,
        retry: 1
      }
    );

    const searchClientData = clientSearchData?.data?.data?.data;

    // FOR PAGINATION
    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setLimit(pageSize);
    };

    const pagination = {
         total: searchKeyword ? clientSearchData?.data?.data?.totalclients : total,
        pageSize: limit,
        current: currentPage,
        onChange: handlePageChange,
    };

const isLoading= clientsData?.isLoading || clientSearchData?.isLoading

    
    // DELETE USER
    const handleDelete = async (record) => {
        try {
            const response = await axiosInstance.delete(`api/clients/${record._id}`);
            const message = response?.data?.message;
            toast.success(`${message}`);
            queryClient.invalidateQueries("clients");
            queryClient.invalidateQueries("allSearchClients");
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Something went wrong.");
            }
        }
    };

    // GET PLAN LIST
    const fetchPlan = async () => {
        return axiosInstance.get("api/plan/");
    };

    const planList = useQuery("plan-list", fetchPlan);

    return (
        <div className="">
            <SearchFilter handleSearch={SearchClient} />

            <ClientDataTable
                usersData={clientsData}
                userSearchData={searchClientData}
                handleDelete={handleDelete}
                pagination={pagination}
                planList={planList}
                isLoading={isLoading}
            />

            <div className="report-pagination ">
                <div>
                    <Select value={limit.toString()} style={{ width: 56 }}
                        onChange={(value) => {
                            setLimit(parseInt(value));
                            setCurrentPage(1);
                        }}

                        options={[
                            { value: "10", label: "10" },
                            { value: "20", label: "20" },
                            { value: "50", label: "50" },
                            { value: "100", label: "100" },
                        ]}
                    />
                    <span className="pl-2"> Entries per pages</span>
                </div>

                <div className="report-selection">
                    <Pagination className="pagination" current={currentPage} pageSize={limit} total={pagination.total}
                        onChange={(page, pageSize) => {
                            setCurrentPage(page);
                            setLimit(pageSize);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ClientList;
