import React, {useContext} from "react";
import "../../styles/users.css";
import {ToastContainer} from "react-toastify";
import {MdAdd} from 'react-icons/md';
import ClientContext from '../../context/clientContext';
import PageHeading from "../PageHeading";
import ClientForm from "./ClientsList/ClientForm";
import ClientTabs from "./ClientTabs";

const Result = () => {
  const user = JSON.parse(localStorage.getItem("admin-user"));
    const clientContext = useContext(ClientContext);

    const handleModal = () => {
        clientContext.updateOpenModal(true);
    };

    return (
        <div className="users-section">
            <ToastContainer/>

            <PageHeading
                pageTitle='Clients'
                icon={<MdAdd/>}
                pageModalTitle='Add New Client'
                pageModalOpen={handleModal}
                role={user?.role}
            />

            <ClientForm isOpen={clientContext.isOpenModal} isAddMode={clientContext.isAddMode}/>

            <ClientTabs/>
        </div>
    );
};

export default Result;