import React, { useState } from "react";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { useQuery, useQueryClient } from "react-query";
import SearchFilter from "../SearchFilter";
import CustSupportDataTable from "../../components/CustomerSupport/CustSupportDataTable";
import { Pagination, Select } from "antd";
import TicketSummary from "./TicketSummary";

const CustSupportList = () => {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  // eslint-disable-next-line
  const [sort, setSort] = useState("DES");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedTicket, setSelectedTicket] = useState("all");

  // TICKET SUMMARY
  const fetchTicketSummary = async (keyword) => {
    const response = await axiosInstance.get(
      `api/tickets/filter?field=ticketStatus&keyword=${keyword}&size=${limit}&page=${currentPage}&sort=${sort}`
    );
    setTotal(response.data.totalpages * limit);
    queryClient.invalidateQueries("Subscribers-List");

    return response.data;
  };

  const ticketSummaryData = useQuery(
    ["ticketSummary", selectedTicket, currentPage, limit, sort],
    () => fetchTicketSummary(selectedTicket, currentPage, limit, sort)
  );

  // SEARCH Ticket
  // const fetchSearchTickets = async () => {
  //   let response;
  //   if (searchKeyword) {
  //     response = await axiosInstance.get(
  //       `api/tickets/search?keyword=${searchKeyword}&limit=${limit}&page=${currentPage}`
  //     );
  //     setTotal(response?.data?.totalPages * limit);
  //   } else {
  //     const response = await axiosInstance.get(`api/tickets/filter?field=ticketStatus&keyword=${selectedTicket}&size=${limit}&page=${currentPage}&sort=${sort}`);
  //     setTotal(response.data.totalpages * limit)
  //   }
  //   return response;
  // };

  const fetchSearchTickets = async () => {
    let response;
    if (searchKeyword) {
      response = await axiosInstance.get(
        `api/tickets/search?keyword=${searchKeyword}&limit=${limit}&page=${currentPage}`
      );
    } else {
      const response = await axiosInstance.get(
        `api/tickets/filter?field=ticketStatus&keyword=${selectedTicket}&size=${limit}&page=${currentPage}&sort=${sort}`
      );
      setTotal(response.data.totalpages * limit);
    }
    // if (response.data) {
    //   const totalPages = response.data.totalpages || 0; // Ensure a default value if totalPages is not present
    //   setTotal(totalPages * limit);
    // }
    return response;
  };

  const ticketSearchData = useQuery(
    ["allSearchTickets", searchKeyword, currentPage, limit],
    () => fetchSearchTickets()
  );

  const SearchUser = (value) => {
    setCurrentPage(1);
    setSearchKeyword(value);
  };

  // FOR PAGINATION
  const handlePageChange = (page, pageSize) => {
    setCurrentPage(1);
    setLimit(pageSize);
  };

  const pagination = {
    total: total,
    pageSize: limit,
    current: currentPage,
    onChange: handlePageChange
  };
  const isLoading = ticketSummaryData?.isLoading || ticketSearchData?.isLoading;
  return (
    <div>
      <TicketSummary
        fetchTicketSummary={fetchTicketSummary}
        setSelectedTicket={setSelectedTicket}
        setCurrentPage={setCurrentPage}
        selectedTicket={selectedTicket}
      />

      <div className='table-list'>
        <SearchFilter handleSearch={SearchUser} />

        <CustSupportDataTable
          ticketSearchData={ticketSearchData?.data}
          pagination={pagination}
          selectedTicket={selectedTicket}
          ticketSummaryData={ticketSummaryData?.data?.data}
          isLoading={isLoading}
        />

        <div className='report-pagination '>
          <div>
            <Select
              value={limit.toString()}
              style={{ width: 56 }}
              onChange={(value) => {
                setLimit(parseInt(value));
                setCurrentPage(1);
              }}
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "50", label: "50" },
                { value: "100", label: "100" }
              ]}
            />
            <span className='pl-2'> Entries per pages</span>
          </div>

          <div className='report-selection'>
            <Pagination
              className='pagination'
              current={currentPage}
              pageSize={limit}
              total={total}
              onChange={(page, pageSize) => {
                setCurrentPage(page);
                setLimit(pageSize);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustSupportList;
