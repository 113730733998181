import React, {useState} from "react";

const BillingContext = React.createContext({
    isOpenModal: false,
    updateOpenModal: () => {
    },
    
    isAddMode: true,
    updateAddMode: () => {
    },

    editData: {},
    updateEditData: () => {
    },
});

export const BillingContextProvider = ({children}) => {

    const [isOpenModalState, setIsOpenModalState] = useState(false);
    const [isAddModeState, setIsAddModeState] = useState(true);
    const [editDataState, setEditDataState] = useState({});

    const contextValue = {
        isOpenModal: isOpenModalState,
        updateOpenModal: setIsOpenModalState,

        isAddMode: isAddModeState,
        updateAddMode: setIsAddModeState,

        editData: editDataState,
        updateEditData: setEditDataState,
    };

    return <BillingContext.Provider value={contextValue}>{children}</BillingContext.Provider>;
};

export default BillingContext;