import React from "react";
import { Modal } from "antd";
import "../../styles/BasicInformation.css";

const GmsClientDetails = ({ isModal, handleCancel, clientDetails }) => {
  return (
    <>
      <Modal
        className='gmsClient-modal'
        open={isModal}
        width={996}
        height={824}
        onCancel={handleCancel}
        footer={false}
      >
        <div className='modal-title'>
          <h3>Client Details</h3>
        </div>
        <div className='ticket-modal-wrapper'>
          <div className='pb-16 modal-wrapper-content'>
            <div className='client-details'>
              <div>
                <div className='basicInfoDiv'>
                  <div className='firstname-field'>
                    <p>
                      <span>First Name</span>
                      <span>{clientDetails?.data?.data?.adminFirstName}</span>
                    </p>
                    <p>
                      <span>Last Name</span>
                      <span>{clientDetails?.data?.data?.adminLastName}</span>
                    </p>
                  </div>
                </div>
                <div className='basicInfoDiv'>
                  <div className='firstname-field'>
                    <p>
                      <span>Email</span>
                      <span>{clientDetails?.data?.data?.adminEmail}</span>
                    </p>
                    <p>
                      <span>Date</span>{" "}
                      <span>{clientDetails?.data?.data?.createdAt}</span>
                    </p>
                  </div>
                </div>
                <div className='basicInfoDiv'>
                  <div className='firstname-field'>
                    <p>
                      <span>Phone</span>
                      <span>{clientDetails?.data?.data?.adminContact}</span>
                    </p>
                    <p>
                      <span>Primary Contact</span>
                      <span>-</span>
                    </p>

                    <p>
                      <span>Main Office Address</span>
                      <span>
                        {clientDetails?.data?.data?.headOffice.location}
                      </span>
                    </p>
                    <p>
                      <span> Count of Staff</span>
                      <span>-</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default GmsClientDetails;
