import React from 'react';
import { Button, Modal } from 'antd';

const DeleteModal = (props) => {

  const handleOk = () => {
    props.handleDelete()
    props.showModalDelete();
  };

  const handleCancel = () => {
    props.showModalDelete();
  };

     const handleDelete = () => {
      props.handleDelete()
      props.showModalDelete();
  }


  return (
    <>
      {/* {message && (
        <div className="text-white text-sm bg-black">{message}</div>
      )} */}
      <Modal open={props.isDeleteModal} onOk={handleOk} onCancel={handleCancel}
        footer={null}
      >
        <div>
          <div className='modal-title'>
            <h3>{props.textheading}</h3>
          </div>
          <div className='delete-modal-body'>
            <h6>Are you sure you want to {props.action} this <span>{props.deleteTitle}</span> permanently?</h6>
          </div>

          <div className='modal-footer p-4'>
            <div className='flex justify-end'>
              <Button className='delete-btn' onClick={handleDelete}>{props.deleteBtn}</Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DeleteModal