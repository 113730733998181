import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ReportDatabaseList from './ReportDatabase/ReportDatabaseList';
import ReportRequestedList from './ReportRequested/ReportRequestedList';
import CompletedRequestList from './ReportCompleted/CompletedRequestList';
import DeclinedRequestList from './ReportDatabase/DecliendReports';

const ReportTabs = () => {
   
    const [activeTab, setActiveTab] = useState('report-database');
    const navigate = useNavigate();

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        navigate(`../${tab}`); 
    };

    return (
        <>
            <div className="tabs1">
                <div className='gms-table-tabs'>
                    <button
                        className={`tab-button tab-btn1 ${activeTab === 'report-database' ? 'active' : ''}`}
                        onClick={() => handleTabChange('report-database')}
                    >
                        Report Database
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'report-requested' ? 'active' : ''}`}
                        onClick={() => handleTabChange('report-requested')}
                    >
                        Report Requested
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'declined-report-requests' ? 'active' : ''}`}
                        onClick={() => handleTabChange('declined-report-requests')}
                    >
                        Declined Requests
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'completed-report-requests' ? 'active' : ''}`}
                        onClick={() => handleTabChange('completed-report-requests')}
                    >
                        Completed Requests
                    </button>
                </div>

            </div>
            <div className="tab-content">
                <div className='report-page-section'>
                    <div className='report-table-wrapper'>
                        {activeTab === 'report-database' && <ReportDatabaseList />}
                        {activeTab === 'report-requested' && <ReportRequestedList />}
                        {activeTab === 'declined-report-requests' && <DeclinedRequestList />}

                        {activeTab === 'completed-report-requests' && <CompletedRequestList />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportTabs