import React from "react";
import { Modal, Input, Row, Col, Radio, Form } from "antd";
import { useQueryClient } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { toast } from "react-toastify";

const EditRecordPayment = ({
  editRecordPayment,
  setEditRecordPayment,
  selectedRecord
}) => {
  const axiosInstance = useAxiosInstance();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  // const [isConfirmModal, setIsConfirmModal] = useState(false);

  const closeModal = () => {
    setEditRecordPayment(false);
  };

  const handleSubmit = (values) => {
    const recordId = selectedRecord?._id;

    axiosInstance
      .post(`/api/invoice/recordPayment/${recordId}`, values)
      .then((response) => {
        form.resetFields();
        // setIsConfirmModal(true);
        setEditRecordPayment(false);

        toast.success("Payment Recorded Successfully");

        // Invalidate the query
        queryClient.invalidateQueries("invoice-list");
      })
      .catch((error) => {
        console.error("Error recording payment:", error);
        toast.error("Failed to record payment. Please try again.");
      });
  };
  return (
    <>
      {editRecordPayment && (
        <Modal
          className='add-user-modal'
          open={editRecordPayment}
          onCancel={closeModal}
          width={1040}
          maskClosable={false}
          footer={[
            <button
              key='add-user'
              form='edit-record'
              type='submit'
              className='gms-modal-button'
            >
              Save Changes
            </button>
          ]}
        >
          <Form id='edit-record' onFinish={handleSubmit}>
            <div className='user-info-modal'>
              <div className='modal-title'>
                <h3>Edit Record Payment</h3>
              </div>
              <div className='modal-wrapper-body'>
                <div className='modal-wrapper-content'>
                  <h4>Invoice Details</h4>
                  <Row className='add-user-modal-radio mb-5'>
                    <Col>
                      <label className='opacity-60'>Status</label>
                      <Form.Item
                        name='invoiceStatus'
                        rules={[
                          {
                            required: true,
                            message: "Please select the paid type"
                          }
                        ]}
                      >
                        <Radio.Group
                          className='mt-1 opacity-100'
                          name='invoiceStatus'
                        >
                          <Radio value='unpaid'>Unpaid</Radio>
                          <Radio value='partiallyPaid'>Partially Paid</Radio>
                          <Radio value='fullyPaid'>Fully Paid</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className='add-modal-row'>
                    <Col span={8} className='mr-6'>
                      <label htmlFor=''>Amount Paid</label>
                      <Form.Item
                        name='transactionAmount'
                        rules={[
                          {
                            required: true,
                            message: "Please enter the paid amount"
                          }
                        ]}
                      >
                        <Input
                          type='number'
                          name='transactionAmount'
                          // onChange={(value) => handleChange(value, "amt_paid")}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <label htmlFor=''>Date of Payment</label>
                      <Form.Item
                        name='transactionDate'
                        rules={[
                          {
                            required: true,
                            message: "Payment Date is required"
                          }
                        ]}
                      >
                        <Input
                          type='date'
                          name='transactionDate'
                          // onChange={(value) => handleChange(value, "payment_date")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className='add-modal-row'>
                    <Col span={8} className='mr-6'>
                      <label htmlFor=''>Transaction ID</label>
                      <Form.Item
                        name='transactionId'
                        rules={[
                          {
                            required: true,
                            message: "Transaction Id is required"
                          }
                        ]}
                      >
                        <Input
                          type='text'
                          placeholder='₹'
                          name='transactionId'
                          // onChange={(value) => handleChange(value, "transaction_id")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default EditRecordPayment;
