import React, { useContext, useState } from 'react';
import '../../../styles/ShareReports.css';
import { Button, Modal, Row, Col, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import useAxiosInstance from '../../../lib/useAxiosInstance';
import { surveyContext } from '../../../context/SurveyContextProvider';

const ManageSurveySubscribers = () => {
    const { surveySubscribers, showSurveySubscribers, selectedClients, setSelectedClients } = useContext(surveyContext)
    const axiosInstance = useAxiosInstance()
    const queryClient = useQueryClient()

    const handleCancel = () => {
        showSurveySubscribers();
        setSelectedClientsList([])
        setSelectedSubcribers(null)
    };
    const subscribersQuery = queryClient.getQueryData("subs-client-list");

    const [selectedSubcribers, setSelectedSubcribers] = useState(null);
    const [selectedClientsList, setSelectedClientsList] = useState([]);

    const handleClientChange = (value) => {
        setSelectedSubcribers(value);
    };

    // SELECT SUBSCRIBERS
    const handleAddClient = () => {
        if (selectedClients && selectedSubcribers) {
            // Check if the subscriber is already in either of the lists
            const isSubscriberAlreadyAdded =
                selectedClients.subscribers.some(subscriber => subscriber._id === selectedSubcribers) ||
                selectedClientsList.includes(selectedSubcribers);

            if (isSubscriberAlreadyAdded) {
                toast.error('Subscriber already added to the list.');
            } else {
                setSelectedClientsList([...selectedClientsList, selectedSubcribers]);
                setSelectedSubcribers(null);
            }
        }
    };
    const handleRemoveClient = (clientToRemove) => {
        setSelectedClientsList(selectedClientsList.filter((client) => client !== clientToRemove));

        // Remove the subscriber from selectedClient.subscribers if it exists
        setSelectedClients(prevSelectedClient => ({
            ...prevSelectedClient,
            subscribers: prevSelectedClient.subscribers.filter(subscriber => subscriber !== clientToRemove)
        }));
    };
    
    // UPDATE SUBSCRIBERS
    const updateSubscribersMutation = useMutation(
        (updatedSubscribers) => {
            return axiosInstance.put(`api/survey/${updatedSubscribers._id}`, {
                subscribers: updatedSubscribers.subscribers,
                surveyName: updatedSubscribers.surveyName,
                resourceLocation: [
                    {
                        name: updatedSubscribers.resourceLocation[0].name,
                        key: updatedSubscribers.resourceLocation[0].key,
                        publicUrl: updatedSubscribers.resourceLocation[0].publicUrl
                    }
                ],
            });
        },
        {
            onSuccess: () => {
                showSurveySubscribers();
                setSelectedClientsList([])
                queryClient.invalidateQueries('survey-list');
                queryClient.invalidateQueries('allSearchSurvey')
                queryClient.invalidateQueries('subs-client-list');
                // setSelectedClient(null);
                toast.success('Subscribers updated successfully.');
            },
            onError: (error) => {
                if (error?.response?.data?.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error('Failed to update subscribers.');
                }
            },
        }
    );
    // const handleSubmit = () => {
    //     const selectedSubscribers = [...selectedClients.subscribers];
    //     const addedSubscribers = [];

    //     // Map selected subscriber IDs to actual subscriber objects
    //     selectedClientsList.forEach(subscriberId => {
    //         const subscriber = subscribersQuery?.find(sub => sub._id === subscriberId);
    //         if (subscriber) {
    //             addedSubscribers.push(subscriber);
    //         }
    //     });

    //     selectedSubscribers.push(...addedSubscribers);

    //     const updatedSubscribers = {
    //         ...selectedClients,
    //         subscribers: selectedSubscribers.map(subscriber => ({
    //             _id: subscriber._id,
    //             firstName: subscriber.adminFirstName,
    //             lastName: subscriber.adminLastName,
    //         })),
    //     };

    //     updateSubscribersMutation.mutate(updatedSubscribers);
    // };
    const handleSubmit = () => {
        const selectedSubscribers = selectedClients?.subscribers || [];

        const addedSubscribers = selectedClientsList.map(subscriberId => {
            const subscriber = subscribersQuery?.find(sub => sub._id === subscriberId);
            return subscriber ? {
                _id: subscriber._id,
                firstName: subscriber.adminFirstName,
                lastName: subscriber.adminLastName,
            } : null;
        });

        const updatedSubscribers = {
            ...selectedClients,
            subscribers: [...selectedSubscribers, ...addedSubscribers],
        };

        updateSubscribersMutation.mutate(updatedSubscribers);
    };

    return (
        <>
            <div className='report-page-title'>
                {surveySubscribers && (
                    <Modal
                        className='share-report-modal'
                        open={surveySubscribers}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        width={1040}
                        maskClosable={false}
                        footer={[
                            <Button
                                type='button'
                                key='manage_subscribers'
                                className='report-upload-modal-button'
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>,
                        ]}
                    >
                        <div className='modal-title'>
                            <h3>Manage Subscribers</h3>
                        </div>
                        <div className='modal-wrapper-body'>
                            <div className='modal-wrapper-content manage-subscriber-modal'>
                                <h4>Client Name</h4>
                                <Row className='add-modal-row my-2'>
                                    <Col span={10} className='add-clients'>
                                        {selectedClients?.subscribers?.map((manageclients) => (
                                            <div key={manageclients._id} className='flex justify-between'>
                                                <div className='client-name'>{`${manageclients.firstName} ${manageclients.lastName}`}</div>
                                                <CloseOutlined onClick={() => handleRemoveClient(manageclients)} className='manage-subscribers' />
                                            </div>
                                        ))}
                                         

                                        {selectedClients?.subscribers && selectedClientsList.map((subscriberId) => {
                                            const subscriber = subscribersQuery?.find(sub => sub._id === subscriberId);
                                            if (subscriber) {
                                                return (
                                                    <div key={subscriber._id} className='flex justify-between'>
                                                        <div className='client-name'>
                                                            {`${subscriber.adminFirstName} ${subscriber.adminLastName}`}
                                                        </div>
                                                        <CloseOutlined onClick={() => handleRemoveClient(subscriber._id)} className='manage-subscribers' />
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </Col>

                                </Row>
                                <h4 className='pt-6'>Add Subscribers</h4>
                                <Row className='add-modal-row manage-subscriber-row my-5'>
                                    <Col span={20}>
                                        <label className='d-block'>Client Name</label>
                                        <Select
                                            onChange={handleClientChange}
                                            value={selectedSubcribers}
                                            size='large'
                                            id='state'
                                            placeholder='Search client'
                                            style={{ width: 365, height: '60px' }}
                                            showSearch
                                            filterOption={(input, option) => {
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                            }
                                            }
                                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                        >
                                            {subscribersQuery?.map((managesubscriber) => (
                                                <Select.Option key={managesubscriber._id} value={managesubscriber._id}>
                                                    {`${managesubscriber.adminFirstName} ${managesubscriber.adminLastName}`}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                        <Button onClick={handleAddClient} className='manage-subscribers'>Add</Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        </>
    );
};


export default ManageSurveySubscribers