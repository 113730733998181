import React from "react";
import { Form, Row, Col, Select, Button, Popover } from "antd";

const AssemblySearch = ({
  form,
  formValue,
  stateConstituencyOptions,
  districtOptions,
  handleChange,
  constituencyByState,
  electionYears,
  activeTab,
  setYear,
  handleReset,
  onFinish
}) => {
  const { OptGroup, Option } = Select;
  const renderContent = () => (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={formValue}
      id='state-district-form'
      className='constituency-search'
    >
      <Row className='flex'>
        <Col span={10} offset={1}>
          <label>Select State</label>
          <Form.Item name='state'>
            <Select
              name='state'
              id='state'
              showSearch
              placeholder={"Andra Pradesh"}
              onChange={(value) => handleChange(value, "state")}
              options={stateConstituencyOptions}
              style={{ width: "150px" }}
            />
          </Form.Item>
        </Col>

        <Col span={10} offset={1} className='ml-5'>
          <label>Select District</label>
          <Form.Item>
            <Select
              showSearch
              name='district'
              id='district'
              placeholder='Select District'
              optionFilterProp='value'
              value={formValue?.district}
              onChange={(value) => handleChange(value, "district")}
              options={districtOptions}
              disabled={!formValue?.state || !districtOptions.length}
              style={{ width: "150px" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className='flex'>
        <Col span={10} offset={1} className=''>
          <label>Constituency</label>
          <Form.Item>
            <Select
              name='constituency'
              id='constituency'
              value={formValue?.constituency}
              showSearch
              placeholder='Select Constituency'
              optionFilterProp='value'
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              onChange={(value) => handleChange(value, "constituency")}
              disabled={
                !formValue?.state ||
                !formValue?.district ||
                !constituencyByState?.data?.data?.data.length
              }
              style={{ width: "150px" }}
            >
              <OptGroup>
                {constituencyByState?.data?.data?.data
                  ?.filter((constituency) => constituency.name !== null) // Filter out null values
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort the data alphabetically
                  .map((constituency) => (
                    <Option key={constituency._id} value={constituency?.id}>
                      {constituency.name}
                    </Option>
                  ))}
              </OptGroup>
            </Select>
          </Form.Item>
        </Col>

        <Col span={10} offset={1} className='ml-5'>
          <label>Select Year</label>
          <Form.Item>
            <Select
              placeholder={"Year"}
              onChange={(value) => {
                setYear(value);
                handleChange(value, "year");
              }}
              disabled={!formValue?.state}
              style={{ width: "150px" }}
              value={formValue?.year}
            >
              {electionYears?.data?.electionYears.map((year) => (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row className='flex justify-end items-center'>
        <Button
          key='reset-form'
          onClick={handleReset}
          className='reset-button mb-3 mr-4'
          size='small'
          style={{ width: "auto",color:"var(--dark-color)",background: "var(--input-bg)" }}
        >
          Reset
        </Button>
        <Button
          key='add-constituency'
          form='state-district-form'
          type='submit'
          size='small'
          onClick={onFinish}
          className='search-by mb-3'
          style={{ width: "auto", background: "#004877", color: "white" }}
        >
          Search
        </Button>
      </Row>
    </Form>
  );

  return (
    <Popover content={renderContent()} trigger='click' placement='bottomLeft'>
      <Button
        key='add-constituency'
        form='constituency-form'
        type='submit'
        className='search-by mt-5 mr-8'
        style={{ width: "auto", background: "#004877", color: "white" }}
      >
        Search By
      </Button>
    </Popover>
  );
};

export default AssemblySearch;
