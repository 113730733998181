import React from 'react';
import {ReportContextProvider} from '../../context/reportContext';
import Result from '../../components/Reports/Result';

const Reports = () => {
    return (
        <ReportContextProvider>
            <Result/>
        </ReportContextProvider>
    )
};

export default Reports;


