import React, { useState, useEffect } from "react";
import useAxiosInstance from "../../lib/useAxiosInstance";
import { MdCheckCircleOutline, MdOutlineCancel } from "react-icons/md";

const ConstituencySummary = () => {
  const axiosInstance = useAxiosInstance();
  const [summaryData, setSummaryData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          "/api/constituency/getConstituencyFileUploadedSummary"
        );
        const data = response?.data?.data;
        setSummaryData(data);
      } catch (error) {
        console.error("Error fetching constituency summary:", error);
        // Handle the error as needed
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className='constituency-summary'>
        <div className='w-full grid grid-cols-12 gap-12 grid-parent overview-top'>
          <div className='col-span-12 sm:col-span-4'>
            <div className='p-2 constituency-summary-tabs flex flex-col'>
              <p className='text-center'>Total Constituency</p>
              <div className='flex justify-around py-3 constituency-divider'>
                <div className='flex flex-col items-center constituency-stats'>
                  <p className='mt-1'>
                    {summaryData?.total?.parlimentry || "-"}
                  </p>
                  <p className='mt-2'>Parliamentary</p>
                </div>
                <div className='flex flex-col items-center constituency-stats'>
                  <p className='mt-1'>{summaryData?.total?.assembley || "-"}</p>
                  <p className='mt-2'>Assembly</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-span-12 sm:col-span-4'>
            <div className='p-2 constituency-summary-tabs flex flex-col'>
              <div className='flex items-center justify-center'>
                <MdCheckCircleOutline className='text-green-600 mr-1' />
                <p style={{ color: "#27AE60" }}>Complete</p>
              </div>
              <div className='flex justify-around py-3 constituency-divider'>
                <div className='flex flex-col items-center constituency-stats'>
                  <p className='mt-1'>
                    {" "}
                    {summaryData?.complete?.parlimentry || "-"}
                  </p>
                  <p className='mt-2'>Parliamentary</p>
                </div>
                <div className='flex flex-col items-center constituency-stats'>
                  <p className='mt-1'>
                    {" "}
                    {summaryData?.complete?.assembley || "-"}
                  </p>
                  <p className='mt-2'>Assembly</p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-span-12 sm:col-span-4'>
            <div className='p-2 constituency-summary-tabs flex flex-col'>
              <div className='flex items-center justify-center'>
                <MdOutlineCancel className='text-red-500 mr-1' />
                <p style={{ color: "#E84C3B" }}> Incomplete</p>
              </div>
              <div className='flex justify-around py-3 constituency-divider'>
                <div className='flex flex-col items-center constituency-stats'>
                  <p className='mt-1 text-3xl'>
                    {" "}
                    {summaryData?.incomplete?.parlimentry || "-"}
                  </p>
                  <p className='mt-2 text-sky-400'>Parliamentary</p>
                </div>
                <div className='flex flex-col items-center constituency-stats'>
                  <p className='mt-1 text-3xl'>
                    {" "}
                    {summaryData?.incomplete?.assembley || "-"}
                  </p>
                  <p className='mt-2 text-sky-400'>Assembly</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConstituencySummary;
