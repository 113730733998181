import React, { useContext, useEffect, useState } from "react";
import { Modal, Input, Row, Col, Select } from "antd";
import { useMutation, useQueryClient } from "react-query";
import useAxiosInstance from "../../../lib/useAxiosInstance";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../../../components/Forms/ErrorMessage";
import GmsContext from "../../../context/gmsContext";

const SubCategoryForm = ({ isOpen, isAddMode }) => {
  const gmsContext = useContext(GmsContext);

  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();

  const [categoryList, setCategoryList] = useState([]);

  const FormSchema = Yup.object().shape({
    parentId: Yup.string().trim().required("Category is required"),
    subCategoryName: Yup.string()
      .trim()
      .required("Sub Category Name is required")
      .min(3, "Sub Category Name is minimum 3 character"),
    subCategoryDescription: Yup.string()
      .trim()
      .required("Sub Category Description is required")
      .min(10, "Sub Category Description is minimum 10 character")
  });

  const formik = useFormik({
    initialValues: {
      parentId: isAddMode ? "" : gmsContext.editData.parentId,
      subCategoryName: isAddMode ? "" : gmsContext.editData.name,
      subCategoryDescription: isAddMode ? "" : gmsContext.editData.description
    },
    enableReinitialize: true,
    validationSchema: FormSchema,
    onSubmit: (values, onSubmitProps) => {
      handleSubmit(values, onSubmitProps);
    }
  });

  const handleSubmit = async (value) => {
    if (isAddMode) {
      const obj = {
        parentId: value.parentId,
        subCategories: [
          {
            subCategoryName: value.subCategoryName,
            subCategoryDescription: value.subCategoryDescription
          }
        ]
      };
      handleCreate.mutate(obj);
    } else {
      const obj = {
        parentId: value.parentId,
        name: value.subCategoryName,
        description: value.subCategoryDescription
      };
      handleUpdate.mutate(obj);
    }
  };

  const handleCreate = useMutation(
    (formData) =>
      axiosInstance
        .post(`api/category`, formData)
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess("sub category created successfully");
      },
      onError: (error) => {
        handleError(error);
      }
    }
  );

  const handleUpdate = useMutation(
    (formData) =>
      axiosInstance
        .put(`api/category/${gmsContext.editData._id}`, formData)
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        handleSuccess("sub category updated successfully");
      },
      onError: (error) => {
        handleError(error);
      }
    }
  );

  const handleSuccess = (data) => {
    queryClient.invalidateQueries("sub-categories");
    queryClient.invalidateQueries("allSearchSubCategory");
    closeModal();
    toast.success(data);
  };

  const handleError = (error) => {
    toast.error("Something went wrong.");
    closeModal();
  };

  const closeModal = () => {
    gmsContext.updateOpenModal(false);
    gmsContext.updateAddMode(true);
    formik.resetForm();
  };

  const getAllCategoryList = async () => {
    try {
      let categoryList = [];
      let currentPage = 1;

      while (true) {
        const response = await axiosInstance.get(
          `api/category/main?page=${currentPage}`
        );
        const categoryData = response.data.data;
        categoryList = [...categoryList, ...categoryData];

        if (currentPage >= response.data.totalpages) {
          break;
        }

        currentPage++;
      }

      setCategoryList(categoryList);
    } catch (error) {
      toast.error("An error occurred while fetching category data.");
    }
  };

  useEffect(() => {
    getAllCategoryList();
  }, []);

  return (
    <>
      {isOpen && (
        <Modal
          className='add-user-modal'
          open={isOpen}
          onCancel={closeModal}
          width={1040}
          maskClosable={false}
          footer={[
            <button
              form='category-form'
              type='submit'
              className='gms-modal-button'
            >
              {isAddMode ? "Add New Sub Category" : "Save Changes"}
            </button>
          ]}
        >
          <form id='category-form' onSubmit={formik.handleSubmit}>
            <div className='user-info-modal'>
              <div className='modal-title'>
                <h3>
                  {isAddMode ? "Add New Sub Category" : "Edit Sub Category"}
                </h3>
              </div>
              <div className='modal-wrapper-body '>
                <div className='modal-wrapper-content pb-7'>
                  <h4>Sub Category Details</h4>

                  <Row className='add-modal-row mt-7'>
                    <Col span={8}>
                      <label htmlFor=''>Category</label>
                      <Select
                        showSearch
                        size='large'
                        style={{ width: "100%" }}
                        name='categoryName'
                        optionFilterProp='children'
                        value={formik.values.parentId}
                        onChange={(value) =>
                          formik.setFieldValue("parentId", value)
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                      >
                        <Select.Option key='default' value=''>
                          Select Category
                        </Select.Option>

                        {categoryList.length > 0 &&
                          categoryList.map((category) => (
                            <Select.Option
                              key={category._id}
                              value={category._id}
                            >
                              {category.name}
                            </Select.Option>
                          ))}
                      </Select>
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.parentId && formik.touched.parentId
                        )}
                        message={formik.errors.parentId}
                      />
                    </Col>
                  </Row>

                  <Row className='add-modal-row'>
                    <Col span={8} className='mr-6'>
                      <label htmlFor=''>Sub Category Name</label>
                      <Input
                        type='text'
                        placeholder='Sub Category Name'
                        name='subCategoryName'
                        onChange={formik.handleChange}
                        value={formik.values.subCategoryName}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.subCategoryName &&
                            formik.touched.subCategoryName
                        )}
                        message={formik.errors.subCategoryName}
                      />
                    </Col>

                    <Col span={8}>
                      <label htmlFor=''>Sub Category Description</label>
                      <Input
                        type='text'
                        placeholder='Sub Category Description'
                        name='subCategoryDescription'
                        onChange={formik.handleChange}
                        value={formik.values.subCategoryDescription}
                      />
                      <ErrorMessage
                        hasError={Boolean(
                          formik.errors.subCategoryDescription &&
                            formik.touched.subCategoryDescription
                        )}
                        message={formik.errors.subCategoryDescription}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};

export default SubCategoryForm;
